.links {
  display: none;
}

.links li {
  @if ($theme-name == "cgr" or $theme-name == "women") {
    color: $text-color;
  }

  display: block;
  float: left;
  line-height: 24px;
  padding: 0 0 0 20px;
  position: relative;
}

.links a {
  color: $logo-color;
  font-family: $fontfamily-regular;
  font-size: 14px;

  @if ($theme-name == "leukoplast") {
    color: $segment-color-alt;

    &:hover {
      color: $logo-color;
    }
  }

  @if ($theme-name == "cutimed") {
    color: $color-gray;
    font-size: 12px;
    line-height: 14px;

    &:hover {
      color: $color-gray;
    }
  }
}

.links li a {
  @if ($theme-name == "men") {
    color: $color-white;
  }
}

.country-selector,
.language-selector {
  @include mediaquery(md) {
    display: block;
  }

  float: left;
}

.language-selector .title {
  @include font-size($font-size-header-item-title);
  font-family: $fontfamily-regular;
  color: $logo-color;
  float: left;
  margin-right: 7px;

  @if ($theme-name == "leukoplast") {
    font-family: $fontfamily-regular-bold;
    color: $segment-color-alt;
  }

  @if ($theme-name == "men") {
    color: $color-white;
  }

  // TODO: Refactor with mobile header
  margin-top: 1px;

  @if ($theme-name == "cutimed") {
    color: $color-gray;
    font-size: 12px;
  }
}

.languages {
  @if ($theme-name == "leukoplast") {
    color: $segment-color-alt;
  } @else {
    color: $logo-color;
  }
  float: left;
}

.languages a.active {
  font-weight: bold;
}

.country-selector-columns-container {
  column-count: 3;
  column-rule: 1px solid $country-selector-border-color;

  @if ($theme-name == "cutimed") {
    column-gap: 45px;
  } @else {
    column-gap: 50px;
  }
}

@media (min-width: $grid-bp-md) {
  .links {
    display: block;
    float: right;
    margin: 0;
    min-height: 25px;
  }

  .links-container {
    left: 30px;
    position: absolute;
    right: 30px;
    z-index: 2;
    min-height: 25px;

    @if ($theme-name == "men") {
      top: 10px;
    }
  }
}

@if ($theme-name == "leukoplast") {
  .country-selector-columns-container .submenu-title {
    color: $segment-color-alt;
  }
}

.simple-link-list-image {
  margin-bottom: 20px;
}

.simple-link-list-narrow {
  margin: 0 auto;
  text-align: center;
}

.simple-link-list-narrow .reset-list {
  justify-content: space-between;
}

@media (min-width: $grid-bp-md) {
  .simple-link-list-narrow {
    margin: auto;
    text-align: left;
  }
}
