.site-search-box {
  position: relative;
  padding-top: 15px;
}

.overlay-search-bg {
  @include transition(opacity $mobile-header-animation-speed);
  @include backgroundBackdrop();

  z-index: 4;
  top: $header-height;
  opacity: 1;
}

.site-search-box--is-expanded {
  position: absolute;
  z-index: 10;
  top: 35px;
  left: 325px; // value position left from header-details-items-container(this is parent container) according to design
  width: 70%;
  height: 50px;
  background-color: transparent;
}

.input-site-search {
  @include font-size-and-line-height($font-size-input-search, $line-height-input-search);

  box-sizing: border-box;
  border: transparent;
  color: $home-color;
  background-color: transparent;
  height: 38px;
  padding-left: 40px;
}

.site-search-page {
  padding-bottom: 40px;
}

.site-search-page .input-site-search,
.site-search-box--is-expanded .input-site-search {
  position: relative;
  width: 570px;
  border: 1px solid $input-search-border-color;
  border-radius: $btn-border-radius;
  padding-right: 40px;
}

.site-search-box--is-expanded .input-site-search {
  border: 1px solid $input-search-border-color-active;
}

.header-details-items-container .site-search-page .input-site-search:focus,
.header-details-items-container .site-search-box--is-expanded .input-site-search:focus {
  outline: 1px solid $input-search-border-color-active;
}

.site-search-box--is-mobile .input-site-search {
  width: 95%;
  border-radius: $btn-border-radius;
  border: 1px solid $input-search-color;
  padding-right: 40px;
}

.btn.btn-search {
  width: 28px;
  height: 28px;
}

.site-search-box--is-desktop {
  display: none;

  @media (min-width: $grid-bp-md) {
    display: block;
  }
}

.site-search-box--is-desktop .input-site-search::placeholder {
  @include font-size($font-size-paragraph);

  font-family: $fontfamily-regular-bold;
  color: $input-search-placeholder-color;
  opacity: 1;
}

.site-search-page .input-site-search::placeholder,
.site-search-box--is-mobile .input-site-search::placeholder,
.site-search-box--is-expanded .input-site-search::placeholder {
  @include font-size($font-size-placeholder-site-search);

  border-radius: $btn-border-radius;
  font-family: $fontfamily-regular;
  color: $input-search-placeholder-color-active;
}

.input-site-search::-webkit-search-cancel-button {
  display: none;
}

.site-search-box--is-desktop .input-site-search {
  cursor: pointer;
}

.site-search-box--is-expanded .input-site-search {
  cursor: auto;
}

.site-search-box--is-desktop .btn-search--collapsed .icon {
  &:hover {
    color: $home-color;
  }
}

.btn.btn-search--reset {
  position: relative;
  right: 40px;
  top: -6px;
  width: 26px;
  height: 26px;
  padding: 0;
  border-radius: 26px;
  background-color: $input-search-color;
  border: 1px solid $color-white;

  &:hover {
    background-color: $input-search-color;
  }
}

.btn.btn-search--reset .icon {
  transform: translateY(0);
  left: 2px;
  top: 2px;
  color: $color-white;
}

.btn .icon-validation-cross {
  @include font-size($btn-search-page-close-icon);

  position: absolute;
  color: $home-color;
}

.site-search-box--is-mobile .btn-search--collapsed {
  display: none;
}

.site-search-box--is-mobile .btn-search--reset {
  position: absolute;
  top: 21px;
  right: 24px;
}

.search-icon-path {
  stroke: $logo-color;
}

.site-search-page .btn-search:disabled .search-icon-path,
.site-search-box--is-mobile .btn-search:disabled .search-icon-path,
.site-search-box--is-expanded .btn-search:disabled .search-icon-path {
  stroke: $input-search-color;
}

.site-search-box .btn-search {
  left: 6px;
  top: $btn-search-icons-position-top;
}

.site-search-box--is-expanded .btn-search {
  pointer-events: auto;
}

@if ($theme-name != "cutimed") {
  .search-result-filter-item {
    @include roundedButton();

    &--is-active {
      background-color: $segment-color;
      color: $color-white;
      pointer-events: none;
    }
  }
}

.search-page-content {
  padding-top: 10px;
}

.search-page-content .search-article-link {
  display: flex;
  width: 100%;
}

.search-article-list {
  display: flex;
  padding-bottom: 15px;
  margin-top: 15px;
  width: 100%;
  border-bottom: 1px solid $input-search-color;
}

.search-article-list .search-article-title {
  @include font-size-and-line-height($font-size-items-list, $font-size-accordion-line-height);

  padding-bottom: 10px;
  font-weight: normal;
  font-family: $fontfamily-regular;
}

.search-article-list-content {
  width: 90%;
}

.search-article-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 10%;
  font-size: 18px;
}

.search-pagination-container {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.search-result-filter--is-mobile {
  display: none;
}

.none-results-search-page .title.has-border {
  @include font-size($font-size-section-title);

  border-bottom: 1px solid $title-border-color;
  font-family: $fontfamily-regular;
  padding-bottom: 10px;
}

.search-pagination {
  margin-left: auto;
  margin-top: 15px;
  width: auto;
}

@if ($theme-name == "leukoplast") {
  .site-search-box--is-desktop .input-site-search::placeholder {
    color: $logo-color;
    font-size: 14px;
    line-height: 17px;
  }

  .site-search-box--is-mobile .input-site-search,
  .site-search-box--is-expanded .input-site-search,
  .site-search-page .input-site-search {
    border: 1px solid $segment-color-alt;
    color: $color-black;
    font-size: $font-size-placeholder-site-search;
    line-height: 16px;
  }

  .site-search-box--is-mobile .btn-search:disabled .search-icon-path,
  .site-search-box--is-expanded .btn-search:disabled .search-icon-path,
  .site-search-page .btn-search:disabled .search-icon-path {
    stroke: $segment-color;
  }

  .site-search-page .site-search-box .input-site-search::placeholder,
  .site-search-box--is-mobile .input-site-search::placeholder,
  .site-search-box--is-expanded .input-site-search::placeholder {
    color: $segment-color;
  }

  .btn.btn-search--reset {
    background-color: $segment-color;
  }

  .site-search-page .input-site-search:focus,
  .site-search-box--is-mobile .input-site-search:focus,
  .site-search-box--is-expanded .input-site-search:focus {
    outline: 1px solid $segment-color-alt;
  }

  .search-article-list .search-article-title,
  .search-article-icon,
  .btn.btn-search--collapsed .icon,
  .btn.btn-search--collapsed .icon:hover {
    color: $logo-color;
  }

  .search-result-filter-item {
    border: 1px solid $logo-color;
    color: $logo-color;

    &--is-active {
      background-color: $logo-color;
      border: 1px solid $logo-color;
      color: $color-white;
    }

    &:hover {
      color: $logo-color;
    }
  }

  .search-article-text {
    font-size: 16px;
    line-height: 22px;
  }

  .none-results-search-page .title.has-border {
    border: none;
    margin: 0;
    padding-bottom: 20px;
  }
}

@media (max-width: $grid-bp-sm-max) {
  .site-search-page .btn.btn-search--collapsed {
    right: calc(50% + 20px);
  }

  .site-search-page .input-site-search {
    width: calc(50% - 10px);
  }

  .overlay-search-bg,
  .search-result-filter--is-desktop {
    display: none;
  }
}

@if ($theme-name == "men") {
  .btn-search--collapsed,
  .btn-search--reset {
    clip-path: none;
  }

  .btn-search--collapsed::after {
    color: transparent;
  }

  .site-search-box .btn.btn-search--collapsed::before {
    background-color: transparent;
  }

  .btn-search--collapsed .icon.icon-validation-cross,
  .btn-search--collapsed .icon.icon-validation-cross:hover {
    color: $color-white;
  }

  .site-search-box--is-expanded .input-site-search {
    color: $color-white;
  }

  .site-search-box--is-desktop .input-site-search::placeholder {
    @include line-height($line-height-input-search);

    color: $input-search-placeholder-color;
    font-family: $fontfamily-regular;
    font-weight: normal;
  }

  .site-search-box--is-expanded .input-site-search::placeholder {
    color: $input-search-placeholder-color-active;
  }

  .site-search-box .btn.btn-search--reset::before,
  .site-search-box .btn.btn-search::before {
    background-color: transparent;
  }

  .site-search-box .btn.btn-search--reset::after,
  .site-search-box .btn.btn-search::after {
    color: transparent;
  }

  .site-search-box--is-expanded .btn-search:disabled .search-icon-path {
    stroke: $color-yellow;
  }

  .site-search-box--is-mobile .btn-search:disabled .search-icon-path {
    stroke: $input-search-color;
  }

  .header-details-items-container .search-icon-path {
    stroke: $color-white;

    @media (max-width: $grid-bp-sm-max) {
      stroke: $input-search-color;
    }
  }

  .site-search-box--is-expanded .search-icon-path {
    stroke: $input-search-border-color-active;
  }

  .btn.btn-search--reset,
  .btn.btn-search--reset:hover {
    background-color: $input-search-placeholder-color-active;
  }

  .btn.btn-search--reset {
    border: 1px solid $input-search-placeholder-color-active;
  }

  .header-details-items-container .btn.btn-search--reset .icon {
    color: unset;

    @media (max-width: $grid-bp-sm-max) {
      color: $color-white;
    }
  }
}

@if ($theme-name == "cutimed") {
  .site-search-page .site-search-box {
    padding-top: 0;
  }

  .site-search-page .input-site-search {
    border: 1px solid $search-border-color;
    line-height: 19px;
    height: 50px;
    padding-left: 65px;

    @media (min-width: $grid-bp-md) {
      width: 785px;
    }
  }

  .site-search-page .site-search-box .btn-search {
    left: 0;
    top: 0;
    background-color: $color-blue;
    width: 50px;
    height: 50px;
    font-size: 30px;
    padding: 6px;
  }

  .header-details-items-container .search-icon-path {
    stroke: $color-blue;
  }

  .site-search-box .btn .icon-validation-cross {
    font-size: 26px;
  }

  .site-search-page .btn.btn-search--reset,
  .site-search-box--is-desktop .btn.btn-search--reset,
  .site-search-box--is-mobile .btn.btn-search--reset {
    border-radius: 0;
  }

  .site-search-page .btn.btn-search--reset,
  .site-search-page .btn.btn-search--reset:hover {
    border: 1px solid $color-cuticell-classic;
    background-color: $color-cuticell-classic;
  }

  .header-details-items-container .site-search-box--is-expanded .btn.btn-search--reset .icon {
    left: -2px;
    top: -2px;
  }

  .site-search-box .btn.btn-search--reset .icon {
    color: $color-gray;
    left: 0;
    top: 0;
  }

  .site-search-box--is-desktop {
    padding-top: 0;
  }

  .site-search-box--is-desktop .input-site-search::placeholder {
    @include font-size-and-line-height(15px, 20px);

    color: $color-blue;
  }

  .site-search-box--is-desktop .btn-search {
    top: 6px;
  }

  .site-search-box--is-expanded {
    padding-top: 15px;
  }

  .site-search-box--is-expanded .input-site-search {
    border: 1px solid $border-color-dropwdown;
    color: $color-gray;
  }

  .site-search-box--is-expanded .input-site-search::placeholder {
    color: $input-search-placeholder-color-active;
  }

  .header-details-items-container .site-search-box--is-expanded .input-site-search:focus {
    outline: auto;
  }

  .site-search-box--is-expanded .btn .icon-validation-cross {
    font-size: 30px;
  }

  .site-search-box--is-expanded .input-site-search,
  .site-search-box--is-mobile .input-site-search {
    @include font-size-and-line-height(15px, 18px);

    padding-left: 55px;
  }

  .site-search-box.site-search-box--is-expanded .btn-search,
  .site-search-box--is-mobile .btn-search {
    top: 15px;
    left: 0;
    width: 38px;
    height: 38px;
    background-color: $color-blue;
    padding: 5px;
  }

  .site-search-box.site-search-box--is-mobile .btn-search {
    top: 15px;
  }

  .site-search-page .search-icon-path,
  .site-search-page .btn-search:disabled .search-icon-path,
  .site-search-box--is-expanded .btn-search:disabled .search-icon-path,
  .site-search-box--is-expanded .search-icon-path,
  .site-search-box--is-mobile .btn-search:disabled .search-icon-path,
  .site-search-box--is-mobile .btn-search .search-icon-path {
    stroke: $color-white;
  }

  .none-results-search-page .title.has-border {
    @include font-size-and-line-height(23px, 26px);

    font-family: $fontfamily-regular-bold-condensed;
    border: 0;
    margin: 0;
  }

  .none-results-search-page ul {
    padding-left: 10px;
  }

  .none-results-search-page ul li {
    line-height: 25px;
    list-style-type: '- ';
  }

  .search-result-filter-item {
    @include btn("tertiary-blue");

    &--is-active {
      @include btn("secondary");
    }
  }

  .search-article-list .search-article-title {
    @include font-size-and-line-height(28px, 32px);

    font-family: $fontfamily-regular-bold-condensed;
  }

  .search-article-list .search-article-text {
    line-height: 25px;
  }
}

@if ($theme-name == "actimove") {
  .site-search-box .input-site-search {
    padding-left: 34px;
  }

  .site-search-box--is-expanded .input-site-search {
    border-radius: 3px;
  }

  .site-search-page .input-site-search {
    border: 1px solid $color-aluminium;
    border-radius: 3px;

    @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
      width: 470px;
    }
  }

  .site-search-page .input-site-search:focus,
  .header-menu-container .site-search-box--is-mobile .input-site-search:focus {
    outline: 1px solid $color-primary;
  }

  .site-search-box--is-desktop .input-site-search::placeholder {
    @include font-size-and-line-height(16px, 16px);

    font-family: $fontfamily-regular;
  }

  .site-search-box .btn-search {
    top: 20px;
  }

  .site-search-box .btn-search .svg-icon {
    width: 20px;
    height: 20px;
    margin-top: 3px;
  }

  .header-details-items-container .search-icon-path {
    stroke: $color-light-black;
  }

  .site-search-box--is-expanded .search-icon-path {
    stroke: $color-primary;
  }

  .site-search-box .btn {
    background-image: none;

    &:after {
      display: none;
    }
  }

  .btn.btn-search--reset {
    width: 20px;
    height: 20px;
    right: 36px;

    &:hover {
      background-color: $color-light-black;
    }
  }

  .btn.btn-search--reset .icon {
    top: 1px;
    left: 0;
  }

  .btn .icon-validation-cross {
    color: $color-black;
  }

  .search-result-filter--is-desktop {
    margin-left: 5px;
    display: none;

    @media (min-width: $grid-bp-sm) {
      display: block;
    }
  }

  .search-result-filter-item {
    @include btn("secondary");

    border-radius: 0;
    transform: skewX(350deg);

    &:after {
      display: none;
    }
  }

  .search-result-filter-item--is-active {
    @include btn("primary");
  }

  .search-result-filter-item-text {
    display: inline-block;
    transform: skewX(10deg);
  }

  .search-page-content .product-list .component-product-box {
    width: calc(50% - 20px);

    @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
      width: calc(33.33% - 20px);
    }

    @media (min-width: $grid-bp-md) {
      width: calc(25% - 20px);
    }
  }

  .search-page-content .search-pagination .pagination {
    justify-content: flex-start;
  }

  .search-pagination {
    margin-top: 4px;

    @media (min-width: $grid-bp-md) {
      margin-top: 25px;
    }
  }

  .search-article-list ~ .search-pagination {
    margin-top: 24px;

    @media (min-width: $grid-bp-md) {
      margin-top: 45px;
    }
  }

  .search-article-icon {
    color: $color-primary;
  }

  .search-article-list .search-article-title {
    @include font-size-and-line-height(22px, 26px);

    font-family: $fontfamily-titles-normal;
    font-style: italic;
  }

  .search-article-text {
    color: $color-light-black;
    line-height: 22px;
  }

  .none-results-search-page {
    color: $color-light-black;
  }

  .none-results-search-page .title.has-border {
    @include font-size-and-line-height(28px, 32px);

    font-family: $fontfamily-titles-normal;
    font-style: italic;
    padding-bottom: 16px;
    margin-bottom: 24px;
    border-color: $color-aluminium;
    color: $home-color;

    @media (min-width: $grid-bp-sm) {
      width: 66.66%;
    }
  }

  .none-results-search-page ul {
    padding-top: 24px;
    color: $color-light-black;
    padding-left: 28px;

    li:not(:first-of-type) {
      padding-top: 16px;
    }
  }
}

@media (max-width: $grid-bp-sm) {
  .site-search-page {
    padding-bottom: 10px;
  }

  .site-search-page .btn.btn-search--reset {
    position: absolute;
    right: 10px;
    top: $btn-search-icons-position-top;
  }

  .site-search-page .input-site-search {
    width: 100%;
  }
}

@media (min-width: $grid-bp-md) and (max-width: $grid-bp-lg) {
  .site-search-box--is-expanded {
    @if ($theme-name != "men") {
      left: 230px;
      width: 75%;
    } @else {
      width: 66%;
    }
  }

  .input-site-search.expand-search {
    width: 470px;
  }
}

@media (min-width: $grid-bp-md) {
  .site-search-page .btn.btn-search--collapsed {
    left: 540px;
  }

  .site-search-box--is-expanded .btn-search--collapsed {
    right: 0;
  }
}
