.campaign {
  @include svg-play-icon();
  flex-wrap: wrap;

	@media (min-width: $grid-bp-sm) {
		margin: 0 auto;
		max-width: $container-max-width;
		min-height: 220px;
	}

	@media (min-width: $grid-bp-md) {
		min-height: 290px;
	}

	@media (min-width: $container-max-width) {
		min-height: 500px;
	}
}

.campaign .svg-icon.play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.campaign-column-text {
  word-break: break-word;
}

.campaign--center .campaign-column.campaign-column-text {
  text-align: center;
}

.campaign--center .campaign-ctas {
  display: flex;
  justify-content: center;
}

.campaign-ctas {
  margin-top: $campaign-button-margin;
}

.container .campaign {
  @media (min-width: $container-max-width) {
    min-height: 365px;
  }
}

.campaign-container {
	display: flex;
	flex-direction: column-reverse;

	@media (min-width: $grid-bp-sm) {
		display: block;
		margin: 0 auto;
		max-width: 1600px;
	}
}

.campaign-column {
	flex-basis: 50%;
	max-width: 50%;
	position: relative;

	@media (max-width: $grid-bp-md) {
		flex-basis: 100%;
		max-width: 100%;
	}

	&:last-of-type:not(:first-of-type) {
		margin-top: 20px;

    @if ($theme-name == "actimove") {
      margin-top: 0;
    }

		@media (min-width: $grid-bp-md) {
			margin-top: 0;
		}
	}

	&--with-padding {
		padding: 25px;
	}
}

.campaign-column,
.campaign-column p {
  @include font-size-and-line-height($font-size-paragraph, $line-height-boxes-text);
  color: $home-color;
}

.campaign-column.LightenText,
.campaign-column.LightenText p {
  @include font-size-and-line-height($font-size-paragraph, $line-height-boxes-text);
  color: $color-white;
}

@media (max-width: $grid-bp-xs-max) {
  .campaign-column.LightenText,
  .campaign-column.LightenText p {
    color: inherit;
  }
}

@media (min-width: $grid-bp-md) {
	.campaign-column-text {
    padding: 25px;

		@if ($theme-name == "leukoplast") {
			&:first-of-type:last-of-type {
				text-align: right;

				.horizontal-btns {
					float: right;
					margin-left: 10px;
					margin-right: 0;
				}
			}
		}
  }

  .campaign-column--with-padding {
		padding: 40px;
  }

  @if ($theme-name == "leukoplast") {
		.campaign-column-text.LightenText .horizontal-btns {
			border-color: $color-white;
			color: $color-white;
		}
	}
}

.campaign-column .campaign-title {
  &.campaign-title--large {
    @include font-size-and-line-height($font-size-campaign-image-title-large, 1em);
  }
}

.campaign-column .campaign-title {
  @include font-size-and-line-height($font-size-campaign-title, $line-height-campaign-image-title);
}

@if ($theme-name == "men") {
  .campaign-column .campaign-title {
    color: $color-purple;
    font-family: $fontfamily-titles-thin;
    text-transform: uppercase;
  }

  .campaign-column.LightenText .campaign-title {
    color: $color-white;
  }
}

@media (min-width: $grid-bp-md) {
  .campaign-column .campaign-title {
    padding-bottom: 30px;
  }
}

@if ($theme-name == "leukoplast") {
  .campaign-column .campaign-title {
    padding-bottom: 30px;
  }
}

@if ($theme-name == "cutimed") {
  .campaign-column .campaign-title.campaign-title--large {
    line-height: 42px;
  }

  .campaign--center .campaign-column-text {
    text-align: left;
  }

  .campaign-column .campaign-subtitle,
  .campaign-column p,
  .campaign-column.LightenText p {
    line-height: 25px;
  }

  .campaign-column .campaign-title {
    font-family: $fontfamily-regular-bold-condensed;
    padding-bottom: 15px;
  }

  .campaign-ctas .btn {
    @include btn("secondary");
  }

  .campaign-ctas .btn span {
    line-height: 20px;
  }

  .campaign-ctas .btn.btn-hollow.btn-dark {
    color: $color-white;

    &:hover {
      background-color: $color-blue;
      border-color: $color-blue;
    }
  }

  @media (max-width: $grid-bp-xs-max) {
    .campaign--center .campaign-ctas {
      flex-direction: column;
    }
  }

  @media (max-width: $grid-bp-sm-max) {
    .campaign-column-text {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  @media (min-width: $grid-bp-md) {
    .campaign--nonCentered .campaign-column.campaign-column-text {
      padding: 0 0 0 36px;
    }

    .campaign--nonCentered.Flex--desktop-reverse .campaign-column-text {
      padding: 0 36px 0 0;
    }

    .campaign--nonCentered .campaign-column--with-padding.campaign-column-text {
      padding: 40px;
    }
  }
}

.campaign-column .campaign-subtitle {
	padding-bottom: $campaing-image-h4-padding-bottom;
	font-family: $campaign-subtitle-font;

  @if ($theme-name == "men") {
    font-family: $fontfamily-titles-thin;
  }

	@if ($theme-name == "leukoplast") {
		font-size: 14px;
		line-height: 17px;
	} @else {
    font-weight: normal;
    text-transform: uppercase;
	}

  @if ($theme-name == "actimove") {
    @include font-size-and-line-height(14px, 18px);

    letter-spacing: 0.4px;
  }
}

@if ($theme-name == "leukoplast") {
  .campaign-text-content {
    padding-bottom: 30px;

    a {
      color: $logo-color;

      &:hover {
        color: $segment-color-alt;
        text-decoration: underline;
      }
    }
  }
}

@if ($theme-name != "leukoplast") {
  .campaign-icon {
    height: 80px;
    width: auto;

    @media (min-width: $grid-bp-sm) {
      height: 100px;
    }
  }
}

.campaign-column .btn {
  @media (max-width: $grid-bp-md) {
		margin-bottom: 10px;

		@if ($theme-name != "leukoplast" and $theme-name != "cutimed") {
			text-align: left;
		}
	}

	@if ($theme-name == "leukoplast") {
		text-align: center;
		@include btn("secondary");

		@media (min-width: $grid-bp-sm) {
			min-width: $btn-min-width;
		}
	}
}

.campaign-column img {
	@media (min-width: $grid-bp-md) {
		margin: 0 auto;
		max-height: 600px;
		max-width: 100%;
		object-fit: contain;
	}
}

.campaign-column .campaign-img {
  @if ($theme-name == "men") {
    @include imageClipPath();
  }
}

@media (max-width: $grid-bp-xs-max) {
	.campaign .campaign-column--with-padding {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.campaign-bg-image {
	width: 100%;

	@media (min-width: $grid-bp-sm) {
		bottom: 0;
		height: 100%;
		left: 0;
		object-fit: cover;
		position: absolute;
		right: 0;
		top: 0;
		z-index: -1;
	}
}

@media (max-width: $grid-bp-xs-max) {
	.campaign-column-text.LightenText {
		color: inherit;
	}
}

.campaign-column .jw-wrapper,
.carousel-alt .jw-wrapper {
	background-color: transparent;
}

@if ($theme-name == "actimove") {
  .campaign-column-text {
    padding: 24px;

    @media (min-width: $grid-bp-md) {
      padding: 40px;
    }
  }

  .campaign-column .campaign-title {
    padding-bottom: 24px;
    font-style: italic;

    @media (min-width: $grid-bp-md) {
      padding-bottom: 38px;
    }
  }

  .campaign-ctas {
    display: flex;
    flex-direction: column;

    @media (min-width: $grid-bp-md) {
      margin-top: 40px;
      flex-direction: row;
    }
  }

  .campaign-ctas .btn {
    text-align: center;
    min-height: 40px;
    min-width: 130px;

    @media (min-width: $grid-bp-md) {
      text-align: left;
    }
  }

  .campaign-ctas .btn.btn-hollow.btn-dark {
    @include btn("secondary");

    &:hover {
      background-color: none;
    }
  }

  @media (max-width: $grid-bp-sm-max) {
    .campaign-ctas .btn:after,
    .campaign-ctas .btn.btn-hollow.btn-dark:after {
      right: auto;
      margin-left: 15px;
    }
  }
}