.cta--has-background {
  background-color: $cta-background-color;
}

@if ($theme-name == "cutimed") {
  .cta--is-double.cta--has-background .cta-item {
    margin-bottom: 25px;
  }

  .cta--is-double .cta-content,
  .cta--is-double .btn.cta-btn {
    text-align: center;
  }

  .cta--is-double .cta-title {
    @include font-size-and-line-height(35px, 42px);
  }

  .cta--is-double .cta-text {
    margin-top: 10px;
  }
}

@media (min-width: $grid-bp-sm) {
  .cta--is-double {
    @include clearfix();
  }

  .cta--is-double .cta-item {
    float: left;
    width: 50%;
  }

  @if ($theme-name == "cutimed") {
    .cta--is-double {
      display: flex;
      justify-content: space-between;
      gap: 25px;

      &::before,
      &::after {
        display: none;
      }
    }

    .cta--is-double .cta-item {
      flex-basis: 50%;
    }

    .cta--is-double .cta-content {
      text-align: left;
    }

    .cta--is-double .cta-title {
      @include font-size-and-line-height(28px, 32px);
    }

    .cta--is-double .btn.cta-btn {
      margin-top: 30px;
      text-align: center;
      min-width: 280px;
    }
  }
}

.cta--is-double.cta--is-half-width .cta-item {
  float: none;
  width: 100%;
}

.cta-info {
  width: 100%;
}

.cta-item-link {
  display: flex;
  flex-direction: column;

  padding: $cta-spacing;
  position: relative;

  @media (min-width: $grid-bp-xs) {
    padding: 30px;
  }

  @media (min-width: $grid-bp-sm) {
    @if ($theme-name != "actimove") {
      flex-direction: row;
    }

    align-items: center;
    padding: 25px;
  }

  @if ($theme-name == "actimove") {
    @media (min-width: $grid-bp-md) {
      flex-direction: row;
    }
  }

  @media (min-width: $grid-bp-lg) {
    padding: 45px;
  }
}

.cta-btn {
  @if ($theme-name != "men") {
    background-color: $cta-button-background-color;
    border: 2px solid $cta-button-background-color;
    color: $cta-button-text-color;

    &:hover {
      background-color: $cta-button-hover-background-color;
      border: 2px solid $cta-button-hover-background-color;
      color: $cta-button-hover-text-color;
    }
  }

  display: inline-block;
  height: 100%;
  margin-top: 25px;
  max-width: 100%;
  min-width: 170px;
  text-align: center;
  width: 100%;
  word-break: break-word;

  @media (min-width: $grid-bp-sm) {
    margin-top: 15px;
  }

  @media (min-width: $grid-bp-md) {
    margin-top: 0;
    width: auto;

    @if ($theme-name != "men") {
      max-width: 300px;
    }
  }

  @if ($theme-name == "men") {
    @include btn("accent");
  }

  @if ($theme-name == "cutimed") {
    @include btn("primary");
  }

  @if ($theme-name == "actimove") {
    @include btn("primary-light");

    border: none;
    min-height: 40px;
    min-width: 130px;

    @media (max-width: $grid-bp-sm-max) {
      padding-right: 20px;
    }

    &:hover {
      border: none;
    }
  }

  .cta-btn-text {
    @include font-size($cta-spacing);

    @if ($theme-name == "men") {
      @include font-size-and-line-height($font-size-btn, $font-size-btn);
    }
  }
}

.cta--is-double .cta-btn {
  text-align: left;

  @media (min-width: $grid-bp-sm) {
    max-width: 200px;
  }

  @media (min-width: $grid-bp-md) {
    white-space: initial;
  }
}

.cta--is-double .cta-text,
.cta--is-half-width .cta-text,
.cta--is-full-width .cta-text {
  @include font-size-and-line-height($font-size-paragraph, $line-height-paragraph)
}

.cta--is-double .cta-btn .cta-btn-text,
.cta--is-half-width .cta-btn .cta-btn-text,
.cta--is-full-width .cta-btn .cta-btn-text {
  @if ($theme-name == "cutimed") {
    @include font-size-and-line-height(15px, 20px);
  } @else if ($theme-name == "actimove") {
    @include font-size-and-line-height(16px, 22px);
  } @else {
    @include font-size(19px);
  }
}

.cta--is-half-width .cta-btn {
  white-space: initial;
  @if ($theme-name == "cutimed") {
    text-align: center;
    margin-top: 30px;
  } @else {
    text-align: left;
  }
}

.cta--is-half-width .cta-inline-img-container {
  margin: 0 auto 25px;
}

@media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
  .cta-btn {
    width: auto;
  }

  .cta--is-half-width {
    .cta-btn {
      width: 100%;
    }

    .cta-item-link {
      flex-wrap: wrap;
    }
  }

  @if ($theme-name == "actimove") {
    .cta--is-full-width .cta-btn {
      width: 100%;
      margin-top: 25px;
    }
  }
}

@media (min-width: $grid-bp-md) {
	.cta-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
		margin-right: $cta-spacing;
	}

  .cta-btn {
    height: fit-content;

    @if ($theme-name == "cutimed") {
      align-self: baseline;
    } @else {
      align-self: center;
    }
  }

  .cta-info {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
  }

  .cta--is-full-width .cta-btn {
    @if ($theme-name != "actimove") {
      width: 100%;
    }

    @if ($theme-name == "men") {
      width: initial;
    }
  }

  .cta--is-full-width .cta-content {
    max-width: calc(100% - 250px);
  }
}

.cta-content,
.cta-text {
  @if ($theme-name != "cutimed") {
    color: $color-white;
  }
}

.cta-content {
  text-align: left;
}

.cta--is-half-width .cta-content {
  margin-right: 0;
}

.cta-content--is-dark,
.cta-content--is-dark .cta-title,
.cta-content--is-dark .cta-text {
  color: $home-color;
}

.cta-title {
  font-weight: normal;

  @if ($theme-name != "leukoplast" and $theme-name != "cutimed") {
    @include font-size-and-line-height(28px, 32px);
  }

  @if ($theme-name == "com") {
    font-family: $fontfamily-regular;
  } @else {
    font-family: $cta-title-font;
    text-transform: $cta-title-transform;
  }

  @if ($theme-name == "men") {
    @include font-size-and-line-height($font-size-cta-title, $line-height-cta-title);

    margin-bottom: 10px;
  }

  @if ($theme-name == "actimove") {
    font-style: italic;
  }
}

.cta-text {
	@include font-size($font-size-cta-text);

  @if ($theme-name == "men") {
    @include font-size-and-line-height($font-size-paragraph, $line-height-paragraph);
  }
}

.cta--is-double .cta-title,
.cta-text {
  margin-bottom: 0;
}

.cta-cover-img {
  bottom: 0;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

.cta-inline-img-container {
  margin-bottom: 25px;
}

.cta-inline-img {
  height: auto;
  width: 120px;
}

@media (min-width: $grid-bp-sm) {
  .cta-item-link--has-cover-img .cta-content {
    margin-right: $cta-spacing;
  }

  .cta-inline-img-container {
    padding-right: $cta-spacing;
    margin-bottom: 0;
  }
}

.cta-item-link--has-inline-img {
  .cta-inline-img-container {
    flex-basis: 20%;
    text-align: center;
  }

  .cta-content {
    flex-basis: 60%;
    margin-right: 0;
  }

  .cta-text {
    margin-top: 0;
  }

  .cta-btn {
    margin-left: initial;
  }
}

.equal-height-container {
  display: flex;
  flex-wrap: wrap;

  @if ($theme-name != "leukoplast") {
    .cta-container {
      display: flex;
      flex-basis: 100%;
    }

    &.contains-different-components .cta-container {
      padding: 10px;
      display: flex;
      flex-direction: column;
    }

    &.contains-different-components .cta--is-third-width {
      margin-bottom: 0;
      height: 100%
    }

    &.contains-different-components .cta-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.cta--is-third-width {
  width: 100%;
  margin-bottom: $cta-spacing;

  .cta-btn {
    width: 100%;
  }

  .cta-btn-text {
    @include font-size(19px);
  }

  .cta-title {
    @include font-size-and-line-height(16px, 18px);
  }

  .cta-text {
    @include font-size-and-line-height(22px, 28px);

    @if ($theme-name == "men") {
      @include font-size-and-line-height($font-size-paragraph, $line-height-paragraph);
    }
  }

  .cta-item-link--has-inline-img {
    .cta-inline-img-container {
      margin-bottom: 32px;
      margin-top: 65px;
      padding-right: 0;
      text-align: center;
    }

    .cta-btn {
      margin-top: 0;
    }
  }
}

@if ($theme-name != "leukoplast") {
  .cta-container .pushbox-shadow {
    position: absolute;
    bottom: -7px;
    left: 10px;
    right: 10px;
    background-image: url("../img/box-shadow.png");
    height: $pushbox-shadow-height;
    background-position: center;
    background-color: $color-white;
  }

  .cta--is-full-width .cta-btn {
    @if ($theme-name == "cutimed") {
      text-align: center;
      margin-top: 30px;

      @media (min-width: $grid-bp-md) {
        margin-top: auto;
        margin-bottom: auto;
      }
    } @else {
      text-align: left;
    }
  }

  @media (min-width: $grid-bp-md) {
    .cta--is-full-width .cta-title {
      @include font-size-and-line-height(40px, 44px);
    }
  }
}

@if ($theme-name == "men") {
  .cta-has-gradient {
    @include ctaBackgroundGradient();
  }

  .cta--is-third-width .cta-title,
  .cta--is-full-width .cta-title,
  .cta--is-half-width .cta-title {
    @include font-size-and-line-height($font-size-cta-title, $line-height-cta-title);
  }
}

@media (min-width: $grid-bp-sm) {
  .cta-item-link--has-cover-img {
    flex-direction: row;

    @if ($theme-name == "actimove") {
      min-height: 160px;
    } @else {
      min-height: 260px;
    }
  }

  .cta-item-link--has-inline-img .cta-inline-img-container {
    text-align: left;
  }

  .cta-inline-img {
    width: 140px;
  }

  .equal-height-container .cta-container {
    flex-basis: 50%;
  }

  .cta--is-third-width {
    .cta-item {
      height: 100%;
    }

    .cta-item-link {
      height: 100%;
    }

    .cta-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    .cta-btn {
      align-self: flex-start;
      width: fit-content;
      height: fit-content;
    }
  }
}

@if ($theme-name == "cutimed") {
  .cta--is-full-width .cta-title,
  .cta--is-half-width .cta-title {
    @include font-size-and-line-height($font-size-cta-title, $line-height-cta-title);

    margin-bottom: 38px;

    @media (min-width: $grid-bp-md) {
      margin-bottom: 22px;
    }
  }

  .cta--is-full-width a:hover,
  .cta--is-half-width a:hover {
    color: unset;
  }

  .cta-item-link {
    padding: 40px 18px 50px;

    @media (min-width: $grid-bp-md) {
      padding: 28px 100px;
    }
  }

  @media (min-width: $grid-bp-md) {
    .cta--is-full-width .cta-item-link--has-inline-img .cta-content {
      margin-right: 30px;
    }
  }
}

@if ($theme-name == "actimove") {
  .cta--has-background-light {
    background-color: $color-primary-light-blue;
  }

  .cta-has-gradient {
    @include ctaBackgroundGradient();
  }

  .cta--is-full-width .cta-item-link {
    padding: 40px 24px;

    @media (min-width: $grid-bp-sm) {
      padding-left: 40px;
      padding-right: 40px;
    }

    @media (min-width: $container-max-width) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  .cta--is-full-width .cta-item-link.cta-item-link--has-inline-img {
    @media (max-width: $grid-bp-xs-max) {
      padding-top: 164px;
      padding-bottom: 24px;
    }

    @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
      padding-top: 240px;
    }

    @media (min-width: $grid-bp-md) {
      padding-left: 300px;
      padding-top: 64px;
      padding-bottom: 40px;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border-top: 24px solid $color-white;
      }
    }
  }

  .cta--is-full-width .cta-content {
    @media (min-width: $container-max-width) {
      max-width: 770px;
    }
  }

  .cta--is-full-width .cta-title {
    @include font-size-and-line-height($cta-title-font-size, $cta-title-line-height);

    margin-bottom: 10px;
  }

  .cta--is-full-width .cta-item-link--has-inline-img .cta-title {
    @include font-size-and-line-height(28px, 32px);
  }

  .cta--has-background-light .cta-btn {
    @include btn("primary");
  }

  .cta--is-full-width .cta-btn {
    @media (max-width: $grid-bp-sm-max) {
      text-align: center;

      .cta-btn-text {
        margin-right: 10px;;
      }

      &:after {
        right: auto;
      }
    }

    @media (min-width: $grid-bp-md) {
      max-width: 225px;
    }
  }

  .cta--is-full-width .cta-item-link--has-inline-img .cta-inline-img-container {
    position: absolute;
    width: 100%;
    padding-right: 0;
    top: 0;
    left: 0;
    right: 0;
    height: 140px;

    @media (max-width: $grid-bp-xs-max) {
      margin-bottom: 24px;
    }

    @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
      height: 200px;
      margin-bottom: 40px;
    }

    @media (min-width: $grid-bp-md) {
      width: 275px;
      height: 100%;
      right: unset;
      // Calculates an angle of 10 degrees on the right side
      clip-path: polygon(0 0, 100% 0, calc(100% + -0.18 * 100vmax) 100vmax, 0 100vmax);
    }
  }

  .cta--is-full-width .cta-item-link--has-inline-img .cta-inline-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .cta--is-full-width .cta-item-link--has-inline-img .cta-content {
    @media (min-width: $grid-bp-md) {
      margin-right: 24px;
    }

    @media (min-width: $container-max-width) {
      margin-right: 40px;
    }
  }
}

@media (min-width: $grid-bp-md) {
  .equal-height-container .cta-container {
    flex-basis: 33%;
  }

  .cta--is-double .cta-info,
  .cta--is-half-width .cta-info {
    flex-direction: column;
    justify-content: flex-start;

    .cta-btn {
      align-self: start;

      @if ($theme-name != "cutimed") {
        margin-top: $vspace-sm;
      }
    }
  }
}