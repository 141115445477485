.component-image-list-header .text-content div,
.component-image-list-header .text-content p {
  @include font-size-and-line-height($font-size-paragraph, $line-height-paragraph);
}

.component-image-list-header .section-subtitle {
  @include font-size-and-line-height($font-size-items-list, $line-height-items-list);
}

.component-image-list-header {
  word-break: break-word;
}

.image-list-item {
  @include mediaquery(xs) {
    padding-top: 15px;
  }

  overflow: hidden;
}

.image-list-image {
  @include mediaquery(xxs) {
    margin-bottom: 15px;
    text-align: center;

    @if $theme-name == "leukoplast" {
      display: inline-block;
      margin-right: 25px;
    } @else {
      float: none;
      width: auto;
    }
  }

  float: left;
}

.image-list-image img {
  width: 100%;
  @if $theme-name == "leukoplast" {
    max-width: 100px;
  }

  @if $theme-name == "cutimed" {
    max-width: 93px;
  }

  @include mediaquery(xxs) {
    @if $theme-name == "leukoplast" {
      max-width: 80px;
    } @else if $theme-name == "cutimed" {
      max-width: 93px;
    } @else {
      max-width: 115px;
    }
  }
}

.image-list-content {
  @include font-size-and-line-height($font-size-paragraph, $line-height-paragraph);
  @include mediaquery(xxs) {
    margin-left: 0;
  }
  @if $theme-name == "leukoplast" {
    font-size: $font-size-paragraph;
    line-height: 25px;
    margin-left: 125px;
  } @else if $theme-name == "cutimed" {
    margin-left: 130px;
  } @else {
    margin-left: 150px;
  }
}

@if $theme-name == "leukoplast" {
  .image-list-content h2 {
    color: $logo-color;
    font-size: $font-size-paragraph;
    font-weight: bold;
  }
} @else {
  .image-list-content .image-list-title {
    @include font-size-and-line-height($font-size-items-list, $line-height-items-list);
  }

  @if $theme-name == "cutimed" {
    .component-image-list-header .section-title {
      @include font-size-and-line-height(28px, 32px);

      border-bottom: none;
    }

    .component-image-list-header .text-content {
      margin-bottom: 25px;
    }

    .component-image-list-header .item-list-footer {
      @include font-size-and-line-height($font-size-items-list, $line-height-items-list);

      color: $color-pink;
      font-family: $fontfamily-regular-bold;
    }

    .image-list-content .image-list-title {
      font-family: $fontfamily-regular-bold;
    }

    @media (max-width: $grid-bp-xxs-max) {
      .image-list-content .text-content {
        text-align: center;
      }
    }
  }

  .image-list-content .text-content div,
  .image-list-content .text-content p {
    @include font-size-and-line-height($font-size-paragraph, $line-height-paragraph);
    margin: 0 0 20px;
  }
}

@if ($theme-name == "actimove") {
  .component-image-list-header .section-title {
    color: $color-black;
    margin-bottom: 16px;
    padding-bottom: 16px;
  }

  .component-image-list-header .section-subtitle {
    font-family: $fontfamily-regular;
    color: $color-black;
    font-weight: 600;
  }

  .component-image-list-header .text-box-content,
  .component-image-list-header .item-list-footer {
    color: $color-light-black;
  }

  .image-list-content .image-list-title {
    @include font-size-and-line-height(22px, 26px);

    font-style: italic;
  }

  .image-list-content .text-content div,
  .image-list-content .text-content p {
    color: $color-light-black;
  }

  .image-list-image {
    width: 80px;
    height: 80px;

    @media (max-width: $grid-bp-xs-max) {
      margin-bottom: 16px;
    }
  }

  .image-list-content {
    margin-left: 0;
    text-align: center;

    @media (min-width: $grid-bp-sm) {
      margin-left: 105px;
      text-align: left;
    }
  }

  @media (max-width: $grid-bp-xs-max) {
    .image-list-item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}