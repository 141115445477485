/* Drops styling for product absorbency */

.DropStyle {
  display: inline-block;
  height: 11px;
  width: 11px;
}

.DropStyle.is-empty {
  color: $abosrbency-color-drop-style-empty;

  &:before {
    content: $icon-drop-empty;
  }
}

.DropStyle.is-halfFilled {
  color: $absorbency-color-drop-style-filled;

  &:before {
    content: $icon-drop-half-full;
  }
}

.DropStyle.is-filled {
  color: $absorbency-color-drop-style-filled;

  &:before {
    content: $icon-drop-full;
  }
}
