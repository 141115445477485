.component-intro {
  .intro-content {
    position: relative;

    .intro-text-content {
      @include mediaquery(xs) {
        &.has-top-bg {
          margin-top: 80px;
        }
      }

      @include mediaquery(xxs) {
        &.has-top-bg {
          margin-top: 40px;
        }
      }

      .intro-title {
        @include font-size-and-line-height($intro-title-font-size, $intro-title-line-height);

        font-family:  $intro-title-font-family;
        font-weight: normal;
        margin: 0;

        @if ($theme-name != "cutimed") {
          letter-spacing: -1px;
        }

        @if $theme-name == "men" {
          color: $color-heading;
        } @else if $theme-name == "actimove" {
          color: $section-title-color;
          font-style: italic;
        } @else {
          color: $home-color;
        }

        @include mediaquery(xxs) {
          word-break: break-word;
        }
      }

      .intro-title.product-intro-title {
        @if ($theme-name == "men") {
          @include font-size((def: 42px, sm: 40px, xs: 34px));
          font-family: $fontfamily-header;
          text-transform: uppercase;
        }	@else {
          @include font-size($intro-title-font-size);
        }
      }
    }

    &.has-image {
      @include mediaquery(xs) {
        min-height: 0;
      }
    }

    &.has-preamble {
      .intro-title {
        @if ($theme-name == "com") {
          margin-bottom: 20px;
        } @else if $theme-name == "cutimed" {
          margin-bottom: 20px;
          margin-top: 10px;
        } @else if $theme-name == "actimove" {
          margin-bottom: 10px;
        } @else {
          margin-bottom: 5px;
          margin-top: 10px;
        }
      }
    }
  }

  p {
    @include font-size($intro-text-font-size);
    @include line-height($intro-line-height);

    color: $home-color;
    font-family: $intro-text-font-family;

    @if ($theme-name == "actimove") {
      color: $intro-paragraph-color;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;

    &.intro-image-photo {
      max-height: 180px;
    }

    &.intro-image-top {
      position: relative;
    }

    &.intro-image-bg {
      @include mediaquery(xs) {
        max-height: 140px;
      }
      @include mediaquery(xxs) {
        max-height: 90px;
      }
    }

    &:not(.intro-image-bg) {
      @include mediaquery(md) {
        max-width: 50%;
      }

      @include mediaquery(xs) {
        display: block;
        float: right;
        margin-right: -20px;
        margin-top: -25px;
        max-width: 90%;
        position: relative;
        right: auto;
        top: auto;
      }
    }
  }

  h2 {
    @if ($theme-name == "com") {
      @include mediaquery(sm) {
          font-size: 24px;
      }

      font-family: $fontfamily-regular;
      font-size: 26px;
      margin-bottom: 13px;
    }
  }
}

.intro-top-padding {
  @include mediaquery(sm) {
    padding-top: 25px;
  }

  padding-top: 35px;
}

.component-intro.component-intro--inline .intro-content {
	padding-top: 0;
}

.component-intro.component-intro--inline .intro-content .intro-title {
	margin-bottom: 20px;
	margin-top: 0;
}

.intro-title-link {
  @include font-size-and-line-height(28px, 32px);

  font-weight: 400;
  color: $home-color;
  font-family: $fontfamily-regular;
  border-bottom: 1px solid $title-border-color;
  padding-bottom: 10px;
}

.intro-text-content--center {
  // offset the coulumn by two
  margin-left: 100% / 12 * 2;
  text-align: center;

  // offset the coulumn by one
  @include mediaquery(sm) {
    margin-left: 100% / 12 * 1;
  }

  @include mediaquery(xs) {
    margin-left: 0;
  }
}