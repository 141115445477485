@import "../component/header/logo";
@import "../component/header/menu";
@import "../component/header/submenu";
@import "../component/header/links";
@import "../component/header/header-items";
@import "../component/header/header-details";
@import "../component/header/header-sample";
@import "../component/header/mobile-header";

.header {
  background-color: $color-white;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;

  @if $theme-name == 'men' {
    background-color: transparent;
  }

  @if ($theme-name != "leukoplast" and $theme-name != "cutimed") {
    padding-bottom: 35px;
  }

  @if ($theme-name == "cutimed") {
    box-shadow: 0 8px 11px 0 rgba(0, 0, 0, 0.1);
  }
}

.header-top {
  display: flex;

  @if ($theme-name == "leukoplast") {
    border-bottom: 1px solid $segment-color;
    padding-top: 10px;
  } @else if ($theme-name == "men") {
    padding-bottom: 7px;
  } @else if ($theme-name == "cutimed") {
    padding-bottom: 0;
  } @else if ($theme-name == "actimove") {
    justify-content: center;
  } @else {
    border-bottom: 4px solid $segment-color;
    padding-bottom: 6px;
  }
}

.header-container {
  @include transition(left $mobile-header-animation-speed);
  @include mobileMenuWidth();
  @include mobileMenuLeft();

  background-color: $accent-color;
  height: 100vh;
  position: fixed;
  z-index: 10;
}

@if $theme-name == "men" {
  .header-menu-container .select2-results {
    width: auto;
  }

  .header-menu-container .select2-container--default .select2-selection--single .select2-selection__arrow b {
    padding: 6px;
  }
}

@media (min-width: $grid-bp-md) {
  .header {
    border-bottom: 0;
    padding-bottom: 0;
    position: relative;
  }

  .header-top {
    @include container-size();

    min-height: 100px;
    border-bottom: 0;
    margin: 0 auto;
    padding: 0 30px;
    position: relative;

    @if $theme-name == "leukoplast" {
      min-height: 105px;
    } @else {
      min-height: $header-height;
    }
  }

  .header-container {
    background: $page-background-color;
    height: initial;
    position: initial;
    width: 100%;
  }
}

@media (max-width: $grid-bp-sm-max) {
  @if ($theme-name == "men") {
    .header {
      padding-bottom: 40px;
    }
  }
}