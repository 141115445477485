$header-show-more-gutter: 45px;

.header-details-items-container {
  align-items: center;
  display: flex;
  width: 100%;

  @if ($theme-name == "leukoplast" or $theme-name == "cutimed" or $theme-name == "actimove") {
    display: none;

    @media (min-width: $grid-bp-md) {
      display: flex;
    }
  }
}

.header-details {
  color: $text-color;
  @media (min-width: $grid-bp-md) {
    min-height: 50px;
  }

  @if ($theme-name == "leukoplast") {
    margin-left: 0;
    margin-top: 20px;
  } @else {
    margin-left: -55px;
    margin-top: 5px;
  }
}

@media (max-width: $grid-bp-sm-max) {
  .header-details-items-container {
    width: calc(100% - 200px);
  }

  .header-details {
    max-width: 100%;
  }

  .header-details-items-container .submenu {
    padding-top: 0;
  }
}

.site-name {
  @include font-size($font-size-header-title);

  display: block;
  margin: 0;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  @if ($theme-name == "cutimed") {
    font-family: $fontfamily-regular-bold;
  } @else {
    font-family: $fontfamily-regular;
  }

  @if ($theme-name != "leukoplast") {
    text-transform: uppercase;
  }

  @media (max-width: $grid-bp-sm-max) {
    &.largestTitle {
      font-size: 11px;
    }
    &.largeTitle {
      font-size: 13px;
    }
    &.mediumTitle {
      font-size: 15px;
    }
    &.smallTitle {
      font-size: 17px;
    }
    &.defaultTitle {
      font-size: 19px;
    }
  }

  @media (min-width: $grid-bp-md) {
    min-height: 32px;
    text-transform: none;
    width: auto;
  }
}

.site-name,
.site-name:hover,
.site-name a,
.site-name a:hover {
  @if ($theme-name == "men") {
    color: $color-white;
    text-transform: uppercase;
  } @else if $theme-name == "cutimed" {
    color: $color-blue;
  } @else {
    color: $logo-color;
  }
}


.languages a.active {
  font-family: $fontfamily-regular-bold;
}

.ChangeSite-container {
  bottom: 0;
  border-bottom: 1px solid $footer-bg-color;
  position: absolute;
  left: 0;
  right: 0;
  padding-left: $header-show-more-gutter;
  padding-bottom: 10px;
}

.ChangeSite-container .DropDown-link {
  color: $text-color;
  font-family: $fontfamily-regular-bold;
  font-size: 14px;

  @if ($theme-name == 'men') {
    color: $color-white;
  }

  &:after {
    left: 5px;

    @if ($theme-name == 'men' ) {
      color: $color-yellow;
      font-size: 20px;
    }
  }
}

@media (max-width: $grid-bp-sm-max) {
  @if ($theme-name == 'men') {
    .site-name {
      display: none;
    }
  }
}

@media (min-width: $grid-bp-md) {
  .ChangeSite-container {
    border-bottom: none;
    height: 34px;
    left: $header-show-more-gutter;
    padding: 0;
    position: static;
    width: 34px;
  }

  .header-details-items-container {
    display: flex;
    margin-top: 20px;
    @if ($theme-name == "leukoplast") {
      padding-left: 210px;
    } @else if ($theme-name == "cutimed") {
      padding-left: 175px;
    } @else {
      min-height: 88px;
      padding-left: 195px;
    }
  }

  .header-details {
    display: flex;
    flex-grow: 1;
    margin-left: 0;
    margin-top: 0;
    position: relative;

    @if ($theme-name != "leukoplast") {
      margin-top: 30px;

      .submenu {
        padding-top: 0;
      }
    }

    @if ($theme-name == 'men') {
      margin-left: 45px;
    }
  }

  .ChangeSite-container .DropDown-link {
    font-family: inherit;
  }
}

@media (min-width: $grid-bp-md) and (max-width: $grid-bp-lg) {
  .header-details {
    @if ($theme-name == 'men') {
      margin-top: 30px;
      margin-left: 25px;
    }
  }
}

@media (max-width: $grid-bp-md) {
  .ChangeSite-container {
    @if ($theme-name == 'men') {
      bottom: -1px;
      border: transparent;
    }
  }

  .ChangeSite-container .ChangeSite-list {
    top: 30px !important;
    left: 40px !important;
  }

  .ChangeSite-container .arrow {
    left: 140px !important;
    top: 30px !important;
  }
}