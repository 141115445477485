.country-list-container {
  column-count: 3;
  column-width: 280px;
  padding: 0 0 0 10px;

  @if $theme-name == "leukoplast" {
    column-gap: 40px;
    column-rule: solid 1px $segment-color;
    column-width: 260px;
  }

  @if ($theme-name == "cutimed") {
    column-rule: 1px solid $border-color;
    column-gap: 45px;
  }
}

.country-list-wrapper {
  break-inside: avoid-column;
  margin-bottom: 30px;

  @if ($theme-name == "cutimed") {
    @media (max-width: $grid-bp-xs-max) {
      border-bottom: 1px solid $border-color;
      margin-bottom: 15px;

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}

.country-list {
  @media (max-width: $grid-bp-sm-max) {
    @if ($theme-name == "cutimed") {
      margin-bottom: 15px;
    } @else {
      margin-bottom: 20px;
    }
  }

  margin: 0 0 22px;
  padding: 0;
  zoom: 1;

  &:after,
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

.country-list-title,
.country-list-item {
  @if $theme-name == "leukoplast" {
    font-size: 17px;
  } @else {
    font-size: 16px;
  }

  @if ($theme-name == "cutimed") {
    line-height: 25px;
  } @else {
    line-height: 1.4em;
  }

  display: block;
  list-style-type: none;
}

.country-list-title {
  font-family: $fontfamily-regular-bold;
  margin-bottom: 0;
}
