@mixin container-size() {
    @if $fixed-grid {
        @for $i from 1 through length($screen-sizes) {
            $screen-size: nth($screen-sizes, $i);
            $width: nth($screen-size, 2);
            $media-width: get-prev-size($i);
            $max-width: $media-width - 1;

            @media (max-width: $max-width) {
                @if $i == length($screen-sizes) and $fixed-grid-fluid-xs {
                    max-width: $width;
                    width: auto;
                } @else {
                    width: $width;
                }
            }
        }
        width: $container-max-width; // Default value
    } @else {
        max-width: $container-max-width;
    }
}

@mixin create-grid() {
    // Default grid
    @for $i from 1 through $grid-columns {
        %#{$grid-class}-#{$i} {
            @include grid-base($i);
        }

        .#{$grid-class}-#{$i} {
            @include grid-base($i);
        }

        .#{$grid-class}-push-#{$i} {
            @include grid-push($i);
        }

        .#{$grid-class}-pull-#{$i} {
            @include grid-pull($i);
        }
    }

    // Breakpoints
    @for $i from 1 through length($screen-sizes) {
        $screen: nth($screen-sizes, $i);
        $type: nth($screen, 1);
        $media-width: nth($screen, 2);

        @if $fixed-grid {
            $media-width: get-prev-size($i);
        }

        $max-width: $media-width - 1;

        @media (max-width: $max-width) {
            @for $i from 1 through $grid-columns {
                .#{$grid-class}-#{$type}-#{$i} {
                    @include grid-base($i);
                }

                .#{$grid-class}-#{$type}-push-#{$i} {
                    @include grid-push($i);
                }

                .#{$grid-class}-#{$type}-pull-#{$i} {
                    @include grid-pull($i);
                }
            }

            .#{$grid-class}-#{$type}-push-0 {
                @include grid-push(0);
            }

            .#{$grid-class}-#{$type}-pull-0 {
                @include grid-pull(0);
            }

            .#{$grid-class}-#{$type}-full {
                padding: 0;
            }
        }
    }

    .#{$grid-class}-full {
        padding: 0;
    }
}

@mixin grid-base($i) {
    width: percentage($i / $grid-columns);
}

@mixin grid-push($i) {
    margin-left: percentage($i / $grid-columns);
}

@mixin grid-pull($i) {
    margin-left: - percentage($i / $grid-columns);
}

@function get-prev-size($i) {
    @if $i == 1 {
        @return $container-max-width;
    }

    $prev-screen-size: nth($screen-sizes, $i - 1);
    @return nth($prev-screen-size, 2);
}

@function smallest-bp() {
    $screen: nth($screen-sizes, length($screen-sizes));

    @return nth($screen, 1);
}

@mixin mediaquery($point) {
    $size: $point;

    @each $screen in $screen-sizes {
        @if nth($screen, 1) == $point {
            @if $fixed-grid {
                $size: get-prev-size(index($screen-sizes, $screen));
            } @else {
                $size: nth($screen, 2);
            }
        }
    }

    @if type-of($size) == "number" {
        $max-width: $size - 1;

        @media (max-width: $max-width) {
            @content;
        }
    }
}

@mixin clearfix() {
    zoom: 1; /* For IE 6/7 (trigger hasLayout) */
    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);
    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }
    @return $string;
}

@function url-encode($string) {
    $map: (
        "%":"%25",
        "<": "%3C",
        ">": "%3E",
        " ": "%20",
        "!": "%21",
        "*": "%2A",
        "'": "%27",
        '"': "%22",
        "(": "%28",
        ")": "%29",
        ";": "%3B",
        ":": "%3A",
        "@": "%40",
        "&": "%26",
        "=": "%3D",
        "+": "%2B",
        "$": "%24",
        ",": "%2C",
        "/": "%2F",
        "?": "%3F",
        "#": "%23",
        "[": "%5B",
        "]": "%5D"
    );
    $new: $string;
    @each $search, $replace in $map {
        $new: str-replace($new, $search, $replace);
    }
    @return $new;
}

@function svg-uri($string) {
    @return url("data:image/svg+xml;charset=utf8,#{url-encode($string)}");
}

@mixin line-height($line-heights) {
  @if type-of($line-heights) == "number" {
    line-height: $line-heights;
  } @else {
    @each $bp in map-keys($line-heights) {
      $size: map-get($line-heights, $bp);

      @if $bp == "def" {
        line-height: $size;
      } @else {
        @include mediaquery($bp) {
          line-height: $size;
        }
      }
    }
  }
}

@mixin font-size($font-sizes) {
    @if type-of($font-sizes) == "number" {
        font-size: $font-sizes;
    } @else {
        @each $bp in map-keys($font-sizes) {
            $size: map-get($font-sizes, $bp);

            @if $bp == "def" {
                font-size: $size;
            } @else {
                @include mediaquery($bp) {
                    font-size: $size;
                }
            }
        }
    }
}

@mixin font-size-and-line-height($font-sizes, $line-height) {
  @include font-size($font-sizes);
  @include line-height($line-height);
}

@mixin font-setup() {
  @if ($theme-name == "com" or $theme-name == "cutimed") {
    font-family: $fontfamily-regular-bold;
    font-weight: bold;
  } @else if ($theme-name == "leukoplast" or $theme-name == "men") {
    font-family: $fontfamily-titles-normal;
    font-weight: normal;
  } @else if ($theme-name == "actimove") {
    font-family: $fontfamily-regular;
    font-weight: normal;
  } @else {
    font-family: $fontfamily-titles-normal;
    font-weight: bold;
  }
}

@mixin header-gradient($color1, $color2) {
    background: $color1;
    background: linear-gradient(to right, $color1 25%, $color2 100%);
}

@mixin disableSelection() {
    -webkit-touch-callout: none;
    user-select: none;
}

@mixin transition($transition...) {
    transition: $transition;
}

@mixin overflow-ellipsis() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// Copied from Bootstrap 4
@mixin img-fluid {
    height: auto;
    max-width: 100%;
}

@mixin create-rtl() {
    /*!rtl:ignore */
    direction: rtl;
    unicode-bidi: embed;
}

@mixin flex() {
    display: flex;
}

@mixin flex-align-items($value) {
    display: flex;
    align-items: center;
}

@mixin mobileMenuWidth() {
  width: calc(100vw - 60px);

  @media (min-width: 380px) {
    width: $mobile-panel-width;
  }

  @media (min-width: $grid-bp-md) {
    width: 100%;
  }
}

@mixin mobileMenuLeft() {
  left: calc(-100vw - 60px);

  @media (min-width: 380px) {
    left: -#{$mobile-panel-width};
  }
}

@mixin ctaBackgroundGradient() {
  background: linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(0,44,111,0) 60%);

  @media (max-width: $grid-bp-sm) {
    background: linear-gradient(0deg, #000000 0%, rgba(0,44,111,0) 100%);
  }

  @if ($theme-name == "actimove") {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.54) 0%, transparent 100%);
  }
}

@mixin arrowIcon($arrowColor) {
  &::after {
    content: $icon-short-arrow-right;
    font-family: $fontfamily-icon;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $arrowColor;

    @if ($theme-name == "actimove") {
      right: 25px
    } @else {
      right: 15px;
    }
  }
}

@mixin btn($type: "primary") {
  @if ($theme-name == "cutimed") {
    text-align: center;
  }

  // ToDo: Add styles for legacy
  @if ($type == "secondary") {
    @if ($theme-name == "leukoplast") {
      background-color: transparent;
      border: 1px solid $cta-bgcolor-alt;
      color: $cta-bgcolor-alt;
      text-align: $btn-text-aligment;

      &:hover {
        border-color: $cta-bg-hover;
        background-color: $cta-bg-hover;
        color: #fff;
      }
    } @else if ($theme-name == "men") {
      @include font-size-and-line-height($font-size-btn, $line-height-btn);

      background-color: $color-white;
      border-color: $color-white;
      color: $color-white;

      &::before {
        background-color: $color-blue-dark;
      }

      &::after {
        color: $color-white;
      }
    } @else if ($theme-name == "cutimed") {
      @include font-size-and-line-height($btn-secondary-font-size, $btn-secondary-line-height);

      background-color: $btn-secondary-bg-color;
      border-color: $btn-secondary-bg-color;
      color: $btn-secondary-text-color;

      &:hover {
        filter: brightness(90%);
      }
    }
  }

  @if ($type == "accent") {
    @if ($theme-name == "men") {
      background-color: $color-white;
      color: $color-blue;
      min-width: initial;
      text-align: left;
      width: initial;

      &::before {
        background-color: $color-yellow;
        border: transparent;
      }

      &:hover {
        background-color: $color-white;
        filter: brightness(80%);
        transition: 0.4s ease-in-out;

        &::before {
          background-color: $color-yellow;
        }
      }
    }
  }

  @if ($type == "primary") {
    // Primary Button
    @if ($theme-name == "leukoplast") {
      background-color: transparent;
      border: 1px solid $logo-color;
      color: $logo-color;

      &:hover {
        border-color: $logo-color;
        background-color: $logo-color;
        color: #fff;
      }
    } @else if ($theme-name == "men") {
      @include font-size-and-line-height($font-size-btn, $font-size-btn);
      @include arrowIcon($color-purple);

      position: relative;
      border: transparent;
      background-color: $color-purple;
      color: $color-purple;
      padding: 13px 58px 13px 20px;
      clip-path: polygon(calc(100% - 15px) 0, 100% 15px, 100% 100%, 0 100%, 0 0);

      &::before {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        background-color: $color-white;
        border-radius: 2px;
        clip-path: polygon(calc(100% - 14px) 0, 100% 14px, 100% 100%, 0 100%, 0 0);
      }

      &:hover {
        color: $color-purple;
        background-color: $color-purple;

        &::before {
          background-color: $color-white;
        }
      }

      .icon-button-right {
        display: none;
      }

      span {
        font-family: $fontfamily-asap;
        font-weight: 400;
      }

      &.btn-dark {
        background-color: $color-purple;
      }

      &.btn-hollow {
        background-color: $color-purple;
      }

      &.btn-hollow::before {
        background-color: $color-white;
      }

      &.btn-hollow,
      &.btn-dark,
      &.btn-hollow.btn-dark {
        &:hover {
          color: $color-purple;
        }
      }
    } @else if ($theme-name == "cutimed") {
      background-color: $btn-primary-bg-color;
      border-color: $btn-primary-bg-color;
      color: $btn-primary-text-color;
    } @else if ($theme-name == "actimove") {
      @include font-size-and-line-height($font-size-btn, 22px);
      @include arrowIcon($color-white);

      text-align: center;
      color: $color-white;
      border: none;
      font-weight: 600;
      background-color: $color-primary;
      background-image: linear-gradient(110deg, $color-primary-light 50%, transparent 51%);
      background-size: 100px 100px;
      background-position: -100px -10px;
      background-repeat: no-repeat;
      transition: all linear .2s;

      &:hover {
        background-size: 200% 200%;
        background-position: 0 0;
      }
    }
  }

  @if ($type == "primary-light") {
    @if ($theme-name == "actimove") {
      @include arrowIcon($color-primary);

      color: $color-primary;
      background-color: $color-white;
      background-image: linear-gradient(110deg, $color-primary-lightest 50%, transparent 51%);

      &:hover {
        color: $color-primary;
      }
    }
  }

  // ToDo: Add styles for legacy
  @if ($type == "secondary") {
    @if ($theme-name == "leukoplast") {
      background-color: transparent;
      border: 1px solid $cta-bgcolor-alt;
      color: $cta-bgcolor-alt;
      text-align: $btn-text-aligment;

      &:hover {
        border-color: $cta-bg-hover;
        background-color: $cta-bg-hover;
        color: $color-white;
      }
    } @else if ($theme-name == "men") {
      background-color: $color-white;
      border-color: $color-white;
      color: $color-white;

      &::before {
        background-color: $color-blue;
      }

      &::after {
        color: $color-white;
      }
    } @else if ($theme-name == "cutimed") {
      @include font-size-and-line-height($btn-secondary-font-size, $btn-secondary-line-height);

      background-color: $btn-secondary-bg-color;
      border-color: $btn-secondary-bg-color;
      color: $btn-secondary-text-color;

      &:hover {
        filter: brightness(90%);
      }
    } @else if ($theme-name == "actimove") {
      @include arrowIcon($color-primary);

      border: 1px solid $color-primary;
      color: $color-primary;
      background-color: transparent;
      background-image: none;

      &:hover {
        @include arrowIcon($color-accent);

        transition: 0.1s;
        color: $color-accent;
        border: 1px solid $color-accent;
      }
    }
  }

  @if ($type == "secondary-light") {
    @if ($theme-name == "actimove") {
      @include arrowIcon($color-white);

      border: 1px solid $color-white;
      color: $color-white;
      background-color: transparent;
      background-image: none;

      &:hover {
        @include arrowIcon($color-accent);

        transition: 0.1s;
        color: $color-accent;
        border: 1px solid $color-accent;
      }
    }
  }

  @if ($type == "accent") {
    @if ($theme-name == "men") {
      background-color: $color-white;
      color: $color-blue;
      min-width: initial;
      text-align: left;
      width: initial;

      &::before {
        background-color: $color-yellow;
        border: transparent;
      }

      &:hover {
        background-color: $color-white;
        filter: brightness(80%);
        transition: 0.4s ease-in-out;

        &::before {
          background-color: $color-yellow;
        }
      }
    }
  }

  @if ($type == "tertiary") {
    @if ($theme-name == "men") {
      background-color: $color-yellow;
      color: $color-white;

      &::before {
        background-color: $color-purple;
      }

      &::after {
        color: $color-white;
      }

      &:hover{
        background-color: $color-yellow;
        color: $color-white;

        &::before {
          background-color: $color-purple;
        }
      }
    }
  }

  @if ($theme-name == "cutimed") {
    @if ($type == "tertiary-blue") {
      @include font-size-and-line-height(15px, 20px);

      font-family: $fontfamily-regular-bold;
      color: $color-blue;
      background-color: $color-white;
      border: 2px solid $color-blue;
      border-radius: 4px;
      padding: 0.5rem 1rem;
      margin-right: 0.5rem;
      margin-bottom: 10px;
      cursor: pointer;
      display: inline-block;

      &:hover {
        color: $color-white;
        background-color: $color-blue;
      }
    }
  }

  @if ($type == "textlink") {
    @if ($theme-name == "actimove") {
      @include arrowIcon($color-primary);

      color: $color-primary;
      background: transparent;

      &:hover {
        @include arrowIcon($color-accent);

        transition: 0.1s;
        color: $color-accent;
      }
    }
  }
}

@mixin imageClipPath() {
  clip-path: polygon(100% 0, 100% calc(100% - 45px), calc(100% - 45px) 100%, 0 100%, 0 0);
}

@mixin backgroundBackdrop() {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

@mixin roundedButton() {
  background-color: transparent;
  border: solid 2px $segment-color;
  border-radius: 50px;
  color: $segment-color;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  margin-bottom: 10px;
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;

  &:focus {
    outline: 1px solid $color-black;
  }
}

@mixin svg-play-icon() {
  .svg-icon.play-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    fill: $btn-play-background-transparent;
  }

  .play-icon-circle,
  .play-icon-arrow {
    stroke: $color-white;
    stroke-width: 2px;
  }

  @if ($theme-name == "leukoplast") {
    .svg-icon.play-icon {
      width: 100px;
      height: 100px;
    }

    .play-icon-circle {
      stroke-width: 0px;
    }

    .play-icon-arrow {
      fill: $color-white;
    }

    @include mediaquery(sm) {
      .svg-icon.play-icon {
        width: 70px;
        height: 70px;
      }
    }
  }

  @if ($theme-name == "men") {
    .svg-icon.play-icon {
      position: relative;
      left: 0%;
      top: 0%;
      transform: translate(0%, 0%);
      fill: transparent;
      width: 75px;
      height: 75px;
    }

    .play-icon-circle,
    .play-icon-arrow {
      stroke: $color-yellow;
    }
  }

  @if ($theme-name == "women" or $theme-name == "cgr") {
    .svg-icon.play-icon {
      border-radius: 50%;
      box-shadow: 0 0 10px 4px $btn-box-shadow;
    }

    .play-icon-circle {
      stroke-width: 0;
    }

    .play-icon-arrow {
      fill: transparent;
      stroke: $color-black;
      transform: scale(0.8) translate(12px, 12px);
    }
  }

  @if ($theme-name == "cutimed") {
    .svg-icon.play-icon {
      width: 56px;
      height: 56px;
    }

    .play-icon-circle {
      fill: $btn-play-background;
      filter: drop-shadow( 0px 0px 2px $btn-play-background);
      fill-opacity: 0.5;
      stroke-width: 0px;
    }

    .play-icon-arrow {
      transform: scale(0.75) translate(15px, 15px);
      fill: $color-white;
      stroke-width: 0px;
    }
  }

  @if ($theme-name == "actimove") {
    .play-icon-circle {
      fill: $btn-play-background;
      filter: drop-shadow( 0px 0px 2px $btn-play-background);
      fill-opacity: 0.5;
      stroke-width: 2px;
    }

    .play-icon-arrow {
      transform: scale(0.75) translate(15px, 15px);
      fill: $color-white;
      stroke-width: 0px;
    }
  }

  @include mediaquery(xs) {
    .svg-icon.play-icon {
      width: 50px;
      height: 50px;
    }
  }
}
