.pagination {
  display: flex;
  justify-content: center;
  i {
    padding: 7px 8px;
    color: $pagination-arrow-icon-color;

    &:hover {
      background-color: $pagination-hover-background-color;

      @if ($theme-name == "actimove") {
        color: $color-white;
      }
    }
  }

  .num {
    display: inline;

    a {
      font-family: $fontfamily-regular-bold;
      padding: 6px 10px;

      @if ($theme-name == "cutimed") {
        @include font-size-and-line-height(12px, 25px);

        font-family: $fontfamily-regular;
        color: $color-blue;
      }

      @if ($theme-name == "actimove") {
        font-family: $fontfamily-regular;
        color: $color-black;
        background-color: $color-primary-light-blue;
        margin-inline: 2px;
      }

      &:hover {
        background-color: $pagination-hover-background-color;

        @if ($theme-name == "actimove") {
          color: $color-white;
        }
      }

      &.selected {
        background-color: $pagination-selected-background-color;

        @if ($theme-name == "leukoplast") {
          background-color: transparent;
          color: $logo-color;
          text-decoration: 3px underline;
        }

        @if ($theme-name == "cutimed") {
          font-family: $fontfamily-regular-bold;
          color: $color-blue;
        }

        @if ($theme-name == "actimove") {
          color: $color-white;
        }

        &:hover {
          background-color: $pagination-selected-hover-background-color;
        }
      }
    }
  }

  @if ($theme-name == "cutimed") {
    .pagination-icon {
      margin-top: 4px;
    }
  }

  .dots {
    display: inline;
    padding: 7px 2px;
  }
}
