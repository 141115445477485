body,
textarea,
input[type=text],
input[type=search],
input[type=select],
button,
td {
    color: $home-color;
    font-family: $fontfamily-regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input[type="search"]::-webkit-search-decoration {
  display: none;
}

a {
    color: $home-color;
    text-decoration: none;

    &:hover {
        color: desaturate(lighten($home-color, 20), 60);
    }

    img {
        border: none;
    }

    .text-content &,
    &.link,
    p & {
      @include font-setup();

      @if $theme-name == "leukoplast"{
        color: $logo-color;
      } @else {
        color: $cta-bgcolor;
      }

        &:hover {
            color: $cta-bg-hover;
            text-decoration: underline;
        }
    }
    .TextContent--grey & {
        color: $home-color;

        &:hover {
            color: desaturate(lighten($home-color, 20), 60);
        }
    }
}

.underlined {
    text-decoration: underline;
}

.dashed {
    border-bottom: 1px dashed;
}

p {
    @include font-size($font-size-paragraph);

    @if ($theme-name == "cgr") {
        color: $text-color;
    }
    line-height: $paragraph-line-height;
    margin-top: 0;
}

strong,
b,
.strong {
  @if ($theme-name == "leukoplast") {
    font-weight: bold;
    font-family: $fontfamily-regular-bold;
  } @else if ($theme-name == "actimove") {
    font-weight: bold;
  } @else {
    @include font-setup();
  }
}

h2 {
    @if ($theme-name == "com") {
        margin: 0 0 13px;
    } @else {
        margin: 0 0 2px;
    }

    @if ($theme-name="com") {
        font-family: $fontfamily-regular;
    } @else {
        font-family: $fontfamily-titles-normal;
    }
    @include font-size($font-size-h2);
    font-weight: normal;
}

h3 {
    @if ($theme-name == "com") {
        margin: 0 0 13px;
    } @else {
        margin: 0 0 2px;
    }

    @include font-setup();
    @include font-size($font-size-h3);
}

h4 {
    @include font-setup();
    @include font-size($font-size-h4);
    font-family: $fontfamily-titles-normal;
    font-weight: normal;
    margin: 0;
}

@if ($theme-name == "actimove") {
  h1 {
    @include font-size($font-size-h1);
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: $fontfamily-titles-normal;
  }
}

.content-subtitle {
    font-family: $fontfamily-header-thin;
}

.listing-title {
    margin-bottom: 10px;
}

.thick {
    font-family: $fontfamily-titles-normal;
}

.preamble {
    @include font-size($font-size-preamble);
    font-family: $fontfamily-titles-thin;
    margin-bottom: 20px;
}

.success-message {
    color: $success-color;
}

.error-message {
    color: $error-color !important;
}

.article .text-content {
  @include font-size-and-line-height($font-size-paragraph, $line-height-boxes-text);
}

.text-content {
    @include mediaquery(md) {
        .col-md-12 {
            margin-bottom: 35px;
            padding: 0;
        }
    }

    @include mediaquery(sm) {
        .col-sm-12 {
            margin-bottom: 35px;
            padding: 0;
        }
    }

    @include mediaquery(xs) {
        .col-xs-12 {
            margin-bottom: 35px;
            padding: 0;
        }
    }
    overflow: hidden;
    word-break: break-word;

    &[class*="col-"] {
      padding: 0;
    }

    [class*="col-"] {
        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    h2 {
        margin: 0 0 5px;
    }

    p {
        @include mediaquery(md) {
            margin-bottom: 25px;
        }
        margin: 0 0 20px;

        &.preamble {
            @include font-size($font-size-paragraph-preamble);
        }

        &:last-child {
            margin: 0;
        }
    }

    ul {
        margin: 0 0 20px;
        padding: 0;

        li {
            @include font-size($font-size-paragraph);

            @include mediaquery(xxs) {
                margin-left: 30px;
            }
            line-height: 22px;
            margin: 0 0 5px 45px;
            padding: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
