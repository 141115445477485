.submenu {
  display: none;
  left: 0;
  padding-top: 11px;
  position: absolute;
  width: 100%;
  z-index: $z-index-top;

  a {
    color: $submenu-link-color;

    &:hover {
      color: $submenu-link-hover;
    }
  }

  &--is-active {
    display: block;
  }
}

.submenu-content {
  @include mediaquery(md) {
    padding-left: 5px;
    padding-right: 5px;
  }
  background: $menu-submenu-bg-color;
  border: 1px solid $menu-submenu-border-color;
  padding: 35px 15px;
}

.submenu-header {
  font-family: $fontfamily-regular-bold;
  margin-bottom: $vspace-sm;
  padding-left: 40px;

  @if ($theme-name == "leukoplast") {
    font-size: 20px;
  } @else if ($theme-name == "cutimed") {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 0;
  } @else {
    font-size: 26px;
  }

  @media (min-width: $grid-bp-md) and (max-width: $grid-bp-lg) {
    padding-left: 30px;
  }

  @media (min-width: $grid-bp-md) {
    margin-bottom: 6px;
  }
}

.submenu .column {
  @include mediaquery(md) {
    padding-left: 15px;
  }

  @if ($theme-name != "cutimed") {
    border-left: 1px solid $country-selector-border-color;
  }

  flex-basis: 100%;
  padding-left: 25px;

  &:first-child {
    border-width: 0;
  }

  &.col-full {
    border: 0;
  }

  &:first-of-type {
    border: none;

    .submenu-title,
    .submenu-title + ul {
      margin-left: 0;
    }
  }

  @if $theme-name == "leukoplast" {
    border-left-color: $home-color;
  }
}

.submenu .column-content {
  margin-left: 15px;
}

.submenu-title {
  clear: both;
  font-family: $fontfamily-regular-bold;
  font-weight: bold;

  @if ($theme-name == "leukoplast") {
    color: $logo-color;
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 20px;

    @media (min-width: $grid-bp-md) {
      margin-bottom: 0;

      &:not(:first-of-type) {
        margin-top: 1em;
      }
    }
  } @else if ($theme-name == "cutimed") {
    font-size: 16px;
    line-height: 25px;
    color: $color-gray;
  } @else {
    font-size: 21px;
    margin-bottom: 15px;
  }
}

.submenu ul {
  clear: both;
  margin: 0 0 45px;
  padding: 0;

  @if ($theme-name == "cutimed") {
    margin-bottom: 22px;
  }

  @if ($theme-name == "leukoplast") {
    margin-bottom: 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  li {
    list-style-type: none;
    margin: 0 0 9px;
    padding: 0;
    @if ($theme-name == "leukoplast" or $theme-name == "cutimed") {
      margin-bottom: 0;
    }
  }
}

.submenu .event-item {
  @include clearfix();

  margin-bottom: 20px;
}

.submenu .event-info {
  font-size: 15px;
  margin-left: 95px;
}

.submenu .event-title {
  font-family: $fontfamily-regular-bold;
}

.submenu .product-item {
  @include clearfix();
}

.submenu .product-image {
  display: block;
  float: left;
  width: 82px;
}

.submenu .product-info {
  display: block;
  margin-left: 100px;
  padding-right: 20px;
}

.submenu .product-title {
  display: block;
  font-size: 19px;
}

.submenu .product-description {
  color: #000;
  display: block;
  font-size: 15px;
}

.submenu .image-item {
  position: relative;
}

.submenu .image-item .btn-wrapper {
  bottom: 10px;
  position: absolute;
  text-align: center;
  width: 100%;
}

.submenu .image-item img {
  width: 100%;
}

.submenu-bg-world-map {
  right: 0;
}

ul.submenu-bg-world-map-list li {
  float: none;
  line-height: normal;
}

@if ($theme-name == "leukoplast") {
  ul.submenu-bg-world-map-list {
    margin-bottom: 25px;
  }
}

ul.submenu-bg-world-map-list a {
  font-size: 16px;
  word-break: break-word;

  @if ($theme-name == "leukoplast") {
    font-size: 17px;
    line-height: 25px;
  } @else if ($theme-name == "cutimed") {
    line-height: 25px;
  }
}

.submenu-content ul.submenu-bg-world-map-list a {
  @if ($theme-name == "men") {
    color: $logo-color;
  }
}

.country-selector-link,
.location.has-submenu {
  position: static !important;
}

.country-selector-link {
  font-size: 14px;
  vertical-align: middle;
}

.submenu-bg-world-map .submenu-content {
  @if ($theme-name == "cutimed") {
    padding: 40px 40px 20px 15px;
    background-image: none;
  } @else {
    background-color: $menu-submenu-bg-color;
    background-image: url("../img/location-world-map.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
    background-position-x: center;
  }

  @if ($theme-name == "leukoplast") {
    padding: 50px 20px;
  }

  @media (min-width: $grid-bp-sm) {
    height: 70vh;
    overflow: auto;

    @if ($theme-name == "leukoplast") {
      height: 660px;
    }
  }
}

.submenu a {
  color: $submenu-link-color;

  &:hover {
    color: $submenu-link-hover;
  }
}

.submenu .submenu-header-title {
  @if ($theme-name != "cutimed") {
    @include font-setup();
  } @else {
    font-family: $fontfamily-regular;
    font-weight: normal;
  }
}

.submenu.has-footer .submenu-content {
  border-bottom: 0;
}

.js-dropdown .arrow,
.product-filter-item--is-active .arrow {
  // bottom: -12px;
  height: 12px;
  position: absolute;
  width: 24px;
  z-index: 10001; // HEIGHER THAN SUBMENU

  &:after {
    border-bottom: 12px solid $menu-submenu-bg-color;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    content: "";
    height: 0;
    position: absolute;
    width: 0;
  }

  &:before {
    border-bottom: 12px solid $menu-arrow-border-color;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    content: "";
    height: 0;
    position: absolute;
    top: -1px;
    width: 0;
  }
}

.submenu .container {
  padding: 0 10px;
}

.other-sites {
  width: auto;
}

.other-sites .submenu-content {
  padding: 35px;
}

.other-sites .column {
  padding: 0;
}

.other-sites .column-content {
  margin-left: 0;
  padding: 0;
}

.other-sites a.active {
  text-decoration: underline;
}

.other-sites-list {
  margin: 0;
  white-space: nowrap;
}

.other-sites-link {
  display: inline;
  padding: 0;
  position: relative;
}

.other-sites-list {
  margin: 0;
  white-space: nowrap;

  li {
    display: block;
    margin-right: 20px;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }

    a {
      font-size: 16px;
    }
  }
}

.other-sites-list li a {
  @if ($theme-name == 'men' ) {
    color: $logo-color;
  }
}

.submenu-footer {
  @include header-gradient($header-gradient-color1, $header-gradient-color2);
  border: 1px solid $menu-submenu-border-color;
  border-top: 0;
  min-height: 140px;
  padding: 15px 35px;
}

.submenu-footer .column {
  padding-left: 30px;
  padding-right: 20px;

  &:first-child {
    padding-left: 0;
  }
}

.submenu-footer .title-item {
  display: block;
  margin-top: 23px;
}

.submenu-footer .title {
  @if ($theme-name == "com") {
    font-weight: 100;
  }

  @if ($theme-name == "cgr") {
    color: $text-color;
  } @else {
    color: $accent-color;
  }
  display: block;
  font-family: $fontfamily-titles-normal;
  font-size: 28px;
  margin-bottom: 3px;
}

.submenu-footer .subtitle {
  @if ($theme-name == "cgr") {
    color: $text-color;
  } @else {
    color: $accent-color;
  }

  i {
    font-size: 13px;
    padding-left: 10px;
  }
}

.submenu-footer .product-item {
  position: relative;
  top: 3px;
}

.submenu-footer .product-item .image {
  display: block;
  float: left;

  img {
    max-height: 110px;
    max-width: 110px;
    position: relative;
  }
}

.submenu-footer .product-item .text {
  display: block;
  font-size: 14px;
  margin-left: 125px;
  padding-top: 3px;
}

.submenu-footer .product-item .title {
  display: block;
  font-family: $fontfamily-titles-normal;
  font-size: 16px;
  margin-bottom: 2px;
}

.submenu-footer .product-item .description {
  display: block;
  line-height: 1.3;
  margin-bottom: 15px;
}

.submenu-footer .product-item .link {
  font-family: $fontfamily-titles-normal;
}

.submenu-footer .image-item {
  @include clearfix();
}

.submenu-footer .image-info {
  display: block;
  float: left;
  font-size: 16px;
  margin-top: 30px;
  padding-right: 10px;
  width: 45%;
}

.submenu-footer .title {
  font-family: $fontfamily-regular-bold;
  margin-bottom: 3px;
}

.submenu-column-title {
  @include font-size($font-size-menu-item);

  font-family: $fontfamily-regular-bold;

  @if ($theme-name == "cutimed") {
    line-height: 22px;
    margin-top: 15px;
    padding-bottom: 8px;
  } @else {
    padding-bottom: 1em;
  }

  @media (min-width: $grid-bp-md) {
    border-bottom: 1px solid $home-color;
    line-height: 25px;
    margin-bottom: 15px;

    @if ($theme-name == "cutimed") {
      margin-top: 0;
    }
  }
}

@if ($theme-name == "cutimed") {
  .submenu-link p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }
}

@media (max-width: $grid-bp-sm-max) {
  @if $theme-name == "leukoplast" {
    .submenu-link {
      @include font-size($font-size-header-title);
      display: block;
      padding-left: 30px;
      margin-bottom: 15px;

      &:last-of-type {
        margin-bottom: 35px;
      }

      p {
        line-height: 23px;
      }
    }

    .submenu-title + .submenu-link {
      margin-top: -10px;
    }

    .submenu-link + .submenu-title {
      margin-top: 20px;
    }
  } @else {
    .submenu-link {
      @if ($theme-name != "cutimed") {
        display: none;
      }
    }
  }
}
