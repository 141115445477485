@import "../../../node_modules/swiper/swiper";
@import "../../../node_modules/swiper/modules/navigation/navigation";
@import "../../../node_modules/swiper/modules/pagination/pagination";
@import "../../../node_modules/swiper/modules/scrollbar/scrollbar";
@import "../../../node_modules/swiper/modules/lazy/lazy";

$swiper-theme-color: $carousel-control-color;
$swiper-pagination-color: #{desaturate(lighten($carousel-control-color, 30), 80)};
$swiper-arrow-color: #809ea7 !default;
$swiper-arrow-color-hovered: #000 !default;

@if ($theme-name == "leukoplast") {
  $swiper-arrow-color: $segment-color;
  $swiper-arrow-color-hovered: $segment-color-alt;
}

@if ($theme-name == "cutimed") {
  $swiper-arrow-color: $color-gray;
}

.swiper-pagination-bullet {
  background: $swiper-pagination-color;
  border-radius: 10px;
  cursor: pointer;
  display: inline-block;
  height: 12px;
  width: 12px;
  list-style: none;
  margin-right: 10px;
  opacity: 1;

  &:last-of-type {
    margin-right: 0;
  }

  &-active {
    background-color: $swiper-theme-color;
  }
}

@if ($theme-name == "men") {
  .swiper-pagination-bullet {
    background: #718b9f;
    border: 1px solid #718b9f;

    &-active {
      background-color: $color-purple;
      border: 1px solid $color-purple;
    }
  }
}

.swiper-button-prev::after {
  right: 10px;
}

.swiper-button-next::after {
  left: 10px;
}

.gallery-btn-prev {
  left: -5px;
}

.gallery-btn-next {
  right: -5px;
}

.swiper-button-prev,
.swiper-button-next {
  &::after {
    color: $swiper-arrow-color;
    font-size: 28px;
    position: relative;
    top: -10px;

    @media (min-width: $grid-bp-xs) {
      position: initial;
    }
  }

  &:hover::after {
    color: $swiper-arrow-color-hovered;
  }
}

.swiper-lazy-preloader {
  border-color: $swiper-theme-color;
  border-top-color: transparent;
}

.carousel-alt .swiper-button-prev,
.carousel-alt .swiper-button-next {
  @if ($theme-name == "actimove") {
    top: 44%;
  } @else {
    top: 50%;
  }
}

.carousel-box .swiper-button-prev,
.carousel-box .swiper-button-next {
  top: 35%;
}

.carousel-alt .gallery-btn-prev,
.carousel-alt .gallery-btn-next {
  top: calc(50% - 37px);
}

.carousel-gallery {
  max-width: 1040px;

  @if ($theme-name == "actimove") {
    padding: 0 80px;
  } @else {
    padding: 0 60px;
  }
}

.gallery-slide-img {
  width: 100%;
}

.intro-carousel .swiper-button-next,
.intro-carousel .swiper-button-prev {
  position: absolute;
  top: -40px;
  bottom: 0;
  margin: auto 0;
  width: 88px;
  height: 88px;
  background-color: $btn-play-pause-background;
  border-radius: 50%;

  &::after {
    top: 0;
    position: relative;
    color: $color-white;
    fill-opacity: 0;

    @if ($theme-name == "men") {
      color: $color-yellow;
    }
  }
}

.intro-carousel .swiper-button-next {
  right: -40px;

  &::after {
    left: -15px;
  }
}

.intro-carousel .swiper-button-prev {
  left: -40px;

  &::after {
    right: -15px;
  }
}

@media (max-width: $grid-bp-sm) {
  .carousel-alt .swiper-button-prev,
  .carousel-alt .swiper-button-next,
  .intro-carousel .swiper-button-prev,
  .intro-carousel .swiper-button-next,
  .carousel-gallery-mask-before,
  .carousel-gallery-mask-after {
    display: none;
  }

  .carousel-gallery {
    padding: 0 40px 0 20px;
  }
}

.carousel-gallery-mask-before,
.carousel-gallery-mask-after {
  background-color: $background-color;
  bottom: 0;
  position: absolute;
  top: 0;
  z-index: 1;

  @if ($theme-name == "actimove") {
    width: 80px;
  } @else {
    width: 60px;
  }
}

.carousel-gallery-mask-before {
  left: 0;
}

.carousel-gallery-mask-after {
  right: 0;
}

@if ($theme-name == "actimove") {
  .swiper-pagination-bullet {
    background-color: $color-white;
    border: 1px solid $color-aluminium;
  }

  .swiper-pagination-bullet-active {
    background-color: $color-primary;
    border: none;
  }

  .carousel-gallery .swiper-button-prev,
  .carousel-gallery .swiper-button-next {
    border: 1px solid $color-primary;
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }

  .carousel-gallery .swiper-button-prev::after,
  .carousel-gallery .swiper-button-next::after {
    color: $color-primary;
  }

  .carousel-gallery .swiper-button-prev {
    left: 0;
  }

  .carousel-gallery .swiper-button-next {
    right: 0;
  }

  .carousel-gallery {
    @media (max-width: $grid-bp-xs-max) {
      padding: 0 40px 0 20px;
    }

    @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  @media (max-width: $grid-bp-sm-max) {
    .carousel-alt.carousel-gallery .swiper-button-prev,
    .carousel-alt.carousel-gallery .swiper-button-next,
    .carousel-gallery-mask-before,
    .carousel-gallery-mask-after {
      display: none;
    }
  }
}
