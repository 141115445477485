.crate.ClickDimension--Form {
  padding: 25px 15px;
}

.crate.ClickDimension--Form .clickdform.mainDiv {
  padding: 0 !important;
}

.clickdform .responsiveCell div.alignTop {
  display: block !important;
}

// This is calculation for fitting input when we have 3 inputs in row
.ClickDimension--Form .responsiveCellSize2 {
  width: calc(100% / 3 * 2 - 5px);
}

.ClickDimension--Form .responsiveCellSize3 {
  width: 100%;
}

@for $i from 1 through 3 {
  .ClickDimension--Form .clickdform .maxSize#{$i} {
    margin-bottom: 3px;
    max-width: none !important;
    display: inline-block;
  }

  .ClickDimension--Form .clickdform.mainDiv .alignBottom.minSize#{$i} {
    width: 100% !important;
  }

  @media (min-width: $grid-bp-md) {
    .ClickDimension--Form .clickdform.mainDiv .alignTop.minSize#{$i} {
      min-width: auto !important;
      width: 100% !important;
    }
  }

  @media (max-width: $grid-bp-xs-max) {
    .responsiveRow .responsiveCellSize#{$i} {
      width: 100% !important;
      min-width: auto !important;
    }
  }

}

.ClickDimension--Form .responsiveRow {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 1px;
}

.ClickDimension--Form .responsiveCell input {
  margin-bottom: 10px;
}

.ClickDimension--Form .responsiveCell span {
  font-family: $fontfamily-regular-bold !important;
  font-size: 14px !important;
  font-weight: normal;
  display: flex;
  align-items: flex-end;
  margin-bottom: 3px;
  color: $home-color;
}

.ClickDimension--Form .responsiveCell span.requiredStar {
  margin: 0;
  display: inline-block;
}

.responsiveRow .responsiveCell input[type=text] {
  border: 1px $border-color-alt solid;
  height: 30px;
  color: $home-color;
  padding: 6px 10px;
  width: 100%;
}

.responsiveRow .responsiveCell input.floatLeft {
  margin-top: 10px;
  margin-right: 10px;
}

.responsiveRow .responsiveCell.line {
  display: none;
}

.responsiveRow .responsiveCell select {
  @include font-size($font-size-form-fields);
  height: 30px;
  border: 1px solid $border-color-alt;
  color: $home-color;
  font-weight: normal;
  padding: 6px 10px;
  width: 100%;
}

.ClickDimension--Form .responsiveCell input[type='checkbox'] + span {
  font-family: $fontfamily-regular !important;
  padding-top: 10px;
  display: table;
}

.responsiveRow .QapTcha {
  padding: 15px 0;
}

.responsiveRow .responsiveCell .buttonContainer {
  display: flex;
}

.responsiveRow .responsiveCell input[type=button] {
  @include font-size($font-size-btn);
  background-color: $cta-bgcolor;
  border: 2px solid $cta-bgcolor;
  border-radius: 3px;
  color: $color-white !important;
  cursor: pointer;
  display: inline-block;
  font-weight: $btn-weight;
  padding: 7px 18px 9px;
}

@media (min-width: $grid-bp-sm) and (max-width: $grid-bp-md) {
  .ClickDimension--Form .responsiveCellSize3 {
    width: 100% !important;
    min-width: auto !important;
  }

  .responsiveCellSize1,
  .responsiveCellSize2 {
    min-width: auto !important;
    // This is calculation for fitting input when we have 3 inputs in row
    width: calc(100% / 3 - 25px) !important;
  }
}

@media (min-width: $grid-bp-md) and (max-width: $container-max-width) {
  .responsiveCellSize1 {
    min-width: auto !important;
    // This is calculation for fitting input when we have 3 inputs in row
    width: calc(100% / 3 - 10px) !important;
  }
}

@media (max-width: $grid-bp-xs-max) {
  .clickdform .minSize1,
  .clickdform .minSize2 {
    width: 100% !important;
    min-width: auto !important;
  }
  .ClickDimension--Form .responsiveRow {
    flex-direction: column;
  }
}

@media (max-width: $grid-bp-md) {
  .clickdform .minSize3 {
    width: 100% !important;
    min-width: auto !important;
  }
}

@if ($theme-name == "leukoplast") {
  .crate {
    border: transparent;
    background: transparent;
  }

  .ClickDimension--Form .responsiveCell span {
    color: $segment-color-alt !important;
    font-size: $font-size-paragraph !important;
  }

  .responsiveRow .responsiveCell {
    margin-bottom: 2px;
  }

  .responsiveRow .responsiveCell input[type=text],
  .responsiveRow .responsiveCell select,
  .responsiveRow .responsiveCell input[type=button] {
    height: 40px;
  }

  .responsiveRow .responsiveCell input[type=text] {
    background-color: $background-color-alt;
    border: transparent;
    font-size: $font-size-paragraph;
    color: $segment-color-alt;
    font-weight: normal;
    padding: 6px 10px;
    text-transform: none;
  }

  .responsiveRow .responsiveCell input[type=text]:focus,
  .responsiveRow .responsiveCell select:focus {
    outline: 1px solid $segment-color !important;
  }

  .responsiveRow .responsiveCell select {
    background-color: $background-color-alt;
    font-size: $font-size-paragraph;
    color: $segment-color-alt;
    font-weight: normal;
    margin: 0;
    padding: 6px 10px;
  }

  .responsiveRow .responsiveCell input[type=button] {
    background-color: transparent;
    border: 1px solid $segment-color-alt;
    color: $segment-color-alt !important;
    border-radius: 50px;
    min-width: 210px;
  }

  .responsiveRow .responsiveCell input[type=button]:hover {
    border-color: $cta-bg-hover;
    background-color: $cta-bg-hover;
    color: $color-white !important;
  }
}

@if ($theme-name == "cutimed") {
  .crate.ClickDimension--Form {
    padding: 0 0 15px;
  }

  .ClickDimension--Form .responsiveCell span {
    font-size: 16px !important;
    line-height: 25px;
    color: $color-gray !important;
  }

  .responsiveCellSize3 {
    min-width: unset !important;
  }

  .responsiveRow .responsiveCell .buttonContainer {
    margin-top: 10px;
    margin-right: 0 !important;
  }

  .responsiveRow .responsiveCell input[type=button] {
    @include btn("primary");

    line-height: 20px;
    font-family: $fontfamily-regular-bold;
    flex-basis: 100%;

    &:hover {
      filter: brightness(90%);
    }
  }

  .clickdform .minSize1,
  .clickdform .minSize2,
  .clickdform .minSize3 {
    width: auto !important;
    min-width: auto !important;
  }

  .responsiveRow .responsiveCell input[type=text],
  .responsiveRow .responsiveCell select,
  .responsiveCell select option {
    font-family: $fontfamily-regular !important;
    height: 36px;
    font-size: 14px;
    border-radius: 3px;
    background-color: $color-white;
  }

  .responsiveRow .responsiveCell input[type=text]:focus,
  .responsiveRow .responsiveCell select:focus {
    outline: 1px solid $color-cuticell-classic !important;
  }

  .responsiveRow .responsiveCell input.floatLeft {
    margin-top: 15px;
    margin-left: 0;
  }

  @media (min-width: $grid-bp-sm) {
    .responsiveCellSize1 {
      min-width: unset !important;
      width: unset !important;
      flex: 1;
    }

    .ClickDimension--Form .responsiveRow {
      gap: 22px;
    }

    .responsiveCellSize2 {
      width: calc(66.66667% - 8px) !important;
      min-width: calc(66.66667% - 8px) !important;
    }

    .responsiveRow .responsiveCell .buttonContainer {
      margin-left: 0;
    }
  }

  @media (min-width: $grid-bp-md) {
    .columnmarker {
      margin-right: 100px;
    }
  }
}