.component-image-carousel {
  @include svg-play-icon();

  .image-carousel {
    @include disableSelection();

        position: relative;

        @if ($theme-name == "cutimed") {
          background-color: $color-white;
          border: 1px solid $color-blue;
        }

        &:hover {
            .popUpEnlarge {
                opacity: 1;
                transform: scale(1);
            }
        }
        @media (max-width: $grid-bp-xs)  {
          .arrow .swiper-button-prev,
          .arrow .swiper-button-next {
            margin-top: -10px;
          }
        }
        .popUpEnlarge {
            @if ($theme-name == "leukoplast" or $theme-name == "cutimed") {
              display: none;
            }
            background-image: $image-carousel-enlarge;
            background-position: 0 0;
            background-repeat: no-repeat;
            opacity: .5;
            position: absolute;
            top: -10px;
            transform: scale(0.65);
            transition: all .8s;
            width: 40px;
            height: 40px;
            // the value is negative because it follows the design requirements
            top: -10px;
            @if ($is-tena-theme) {
              display: none;

              @if ($theme-name != "actimove") {
                @media (min-width: $grid-bp-sm) {
                  display: block;
                  right: 0;
                }
              }
            }

            @include mediaquery(xxs) {
                background-size: cover;
                height: 30px;
                width: 30px;
            }
        }
        .arrow {
            cursor: pointer;
            height: 30px;
            position: absolute;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);
            width: 40px;
            z-index: 2;

        .mfp-zoom-out-cur & {
            display: none;
        }

            &:hover {
              .swiper-button-prev:after,
              .swiper-button-next:after {
                color: $image-carousel-arrow-hover-color !important;
              }
            }
            .swiper-button-prev:after,
            .swiper-button-next:after {
                color: $image-carousel-arrow-color;

                @if ($is-tena-theme) {
                  font-size: 20px;

                  @media (max-width: $grid-bp-xs-max) {
                    display: none;
                  }
                } @else {
                  font-size: 30px;
                }
            }
            .carousel-nav-button-prev,
            .carousel-nav-button-next {
              &:focus {
                outline: none;
              }
            }

            @if ($theme-name == "leukoplast" or $theme-name == "cutimed") {
              @media (min-width: $grid-bp-md) {
                display: none;
              }
            }
        }
        .images {
            @include mediaquery(xxs) {
                height: 220px;
            }

            @if ($is-tena-theme) {
              @include mediaquery(xs) {
                height: 370px;
                padding-bottom: 20px;
              }

              height: 600px;
            } @else {
              height: 350px;
            }
            .image {
                a {
                  display: block;
                  @if ($is-tena-theme) {
                    height: 600px;
                  } @else {
                    height: 350px;
                  }
                }
                img {
                    bottom: 0;
                    display: block;
                    left: 0;
                    margin: auto;
                    max-height: 100%;
                    max-width: 100%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: auto;
                    height: auto;
                }
            }
        }

        .pager {
            bottom: -20px;
            position: absolute;
        }
        .video {
            outline: none;
        }
    }
}

.image-carousel-popup {
    .mfp-figure:after {
        background: none;
        box-shadow: none;
    }
}

@if ($theme-name == "leukoplast" or $theme-name == "cutimed") {
  .product-intro-carousel-thumbnail {
    display: none;
  }
}

@if ($is-tena-theme) {
  .product-intro-carousel-thumbnail {
    @include svg-play-icon();

    display: none;
  }

  .product-intro-carousel-thumbnail .svg-icon {
    padding: 5px;
    width: 60px !important;
    height: 60px !important;
  }

  @if ($theme-name == "women" or $theme-name == "cgr" or $theme-name == "actimove") {
    .product-intro-carousel-thumbnail .svg-icon {
      padding: 0;
      left: 0 !important;
      right: 0 !important;
      transform: translate(0, 0) !important;
      position: relative !important;
      border-radius: 10px !important;
    }
  }

  .product-intro-carousel-thumbnail-box {
    position: relative;
  }

  .product-intro-carousel-thumbnail-box .svg-icon {
    position: absolute !important;
    top: unset !important;
  }

  .product-intro-carousel-thumbnail-img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    cursor: pointer;
    background-color: $color-white;

    @if ($theme-name != "actimove") {
      border-radius: 10px;
    }
  }

  .product-intro-carousel-thumbnail-img--active {
    outline: 5px solid $product-intro-thumbnail-active-border-color;

    @if ($theme-name == "actimove") {
      outline-width: 3px;
      border-radius: 0;
    }
  }

  @media (min-width: $grid-bp-sm) {
    .product-intro-carousel-thumbnail {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      margin: auto;
      flex-wrap: wrap;
      gap: 10px;
    }
  }
}

.carouselThumbnail {
  @include svg-play-icon();

    .ThumbnailSlider {
        float: left;
        position: relative;
        top: 15px;
        width: auto;

        .icon {
            cursor: pointer;
            padding: 10px;
            position: absolute;
            width: 15px;
            height: 15px;
            opacity: 0.7;

            @if ($is-tena-theme) {
              color: $product-intro-thumbnail-slider-icon;
            } @else {
              color: $carousel-thumbail-slider-icon;
            }

            &:focus {
              outline: none;
            }
        }

        .icon-nav-arrow-top {
          top: 7px;
          transform: rotate(180deg);

          @if ($is-tena-theme) {
            left: 42px;
          } @else {
            left: 37px;
          }

          &:before {
            content: "\e61b";
          }
        }

        .icon-nav-arrow-bottom {
          bottom: 7px;

          @if ($is-tena-theme) {
            left: 26px;
          } @else {
            left: 22px;
          }

          &:before {
            content: "\e61b";
          }
        }

        .image-pager-wrapper {
            display: block;
            margin: 25px 0;
            overflow: hidden;

            @if ($is-tena-theme) {
              height: 520px;
            } @else {
              height: 260px;
            }

            .image-pager {
                display: block;
                margin: 0;
                padding-left: 0;
                position: relative;
                text-align: center;
                width: 100%;
                li {
                    align-items: center;
                    background: $image-carousel-thumbnail-bg-alt;

                    @if ($theme-name == "leukoplast" or $theme-name == "cutimed") {
                      border: 1px solid $image-carousel-thumbnail-border-color;
                    } @else {
                      border-radius: 10px;
                    }
                    clear: both;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    list-style: none;
                    margin: 3px 7px;
                    position: relative;
                    vertical-align: middle;
                    zoom: 1;

                    @if ($is-tena-theme) {
                      height: 72px !important;
                      width: 72px;
                    } @else {
                      height: 60px !important;
                      width: 60px;
                    }

                    img {
                        @if ($theme-name=="com") {
                            opacity: 0.6;
                        }
                        overflow: hidden;
                        text-indent: 100%;
                        white-space: nowrap;
                        height: auto;
                        width: auto;

                        @if ($is-tena-theme) {
                          border-radius: 5px;

                          @if ($theme-name == "actimove") {
                            border-radius: 0;
                          }
                        }
                    }
                    &.videoThumbnail-icon {
                        background: $image-carousel-thumbnail-bg-alt;
                        text-align: center;
                        display: flex;
                        align-items: center;

                        img {
                          width: 100%;
                        }
                    }
                    &.active {
                        @if ($is-tena-theme) {
                          border: 5px solid $product-intro-thumbnail-active-border-color;

                          @if ($theme-name == "actimove") {
                            border-width: 1px;
                            border-radius: 0;
                          }
                        }

                        @if ($theme-name == "leukoplast") {
                          border-color: $logo-color;
                          border-width: 2px;
                        } @else if ($theme-name == "cutimed") {
                          border-color: $accent-color;
                        }
                        img {
                            @if ($theme-name=="com") {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}

.carouselThumbnail .svg-icon.play-icon {
  width: 25px;
  height: 25px;
}

.carouselThumbnail .svg-icon.play-icon,
.component-image-carousel .svg-icon.play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;

  @if ($theme-name == "cutimed") {
    border-radius: 50%;
    box-shadow: 0 0 10px 4px $btn-box-shadow;

    .play-icon-arrow {
      stroke: $color-gray;
    }
  }
}

.component-image-carousel .image-carousel .arrow.arrow-right {
  left: initial;

  @if ($is-tena-theme) {
    right: 0;
  } @else {
    right: 25px;
  }
}

.component-image-carousel .image-carousel .arrow.arrow-left {
  right: initial;

  @if ($is-tena-theme) {
    left: 0;
  } @else {
    left: 25px;
  }
}