.image-overlay-wrapper {
  left: 15px;
  right: 15px;
  color: $color-white;
  position: absolute;
  top: 60px;
  width: auto;
}

.image-overlay-text {
  @if ($theme-name == "men" or $theme-name == "com") {
    font-family: $fontfamily-header;
  } @else {
    font-family: $fontfamily-regular-bold;
  }

  font-size: 60px;
  line-height: 1;
  margin: 0;
  text-transform: uppercase;
}

@media (max-width: $grid-bp-xxs-max) {
  .image-overlay-wrapper {
    bottom: 0;
    color: $color-white;
    left: 0;
    padding: 10px;
    position: absolute;
    right: 5px;
    text-align: center;

    @if ($theme-name == "men") {
      background: rgba(0, 70, 118, 0.8);
    } @else if ($theme-name == "com") {
      background: rgba(0, 59, 115, 0.8);
    } @else {
      background: rgba(19, 145, 62, 0.8);
    }
  }

  @if ($theme-name != "actimove") {
    .image-overlay-text {
      font-size: 20px !important;
    }
  }
}

@media (max-width: $grid-bp-xs-max) {
  .image-overlay-wrapper {
    top: 40px;
  }
}

@media (max-width: $grid-bp-sm-max) {
  .image-overlay-text {
    font-size: 38px;
  }
}

@if ($theme-name == "actimove") {
  .banner .image-overlay-wrapper {
    top: 24px;
    left: 24px;

    @media (min-width: $grid-bp-sm-max) {
      top: 40px;
      left: 40px;
    }
  }

  .banner .image-overlay-text {
    @include font-size-and-line-height($image-overlay-text-font-size, $image-overlay-text-line-height);

    font-family: $fontfamily-titles-normal;
    text-transform: none;
    font-style: italic;
  }
}
