.header-items {
  display: none;

  @if ($theme-name != "cutimed") {
    margin-top: 15px;
  }

  @media (min-width: $grid-bp-md) {
    display: flex;
    min-height: 32px;
  }
}

.header-items .item {
  @if ($theme-name == "women" or $theme-name == "men") {
    border-left: 1px solid #ededed;
  } @else if($theme-name == "cgr") {
    border-left: 1px solid #b1b1b1;
  }

  border-width: 0;
  display: flex;
  align-items: center;
  padding-left: 14px;
  padding-right: 10px;
  position: relative;

  @media (min-width: $grid-bp-md) {
    min-height: 32px;
  }

  &:last-of-type {
    padding-right: 0;
  }
}

.header-items .item:hover .text,
.header-items .item:hover .title {
  @if ($theme-name == "leukoplast") {
    color: $logo-color;
  } @else if ($theme-name == "men") {
    color: $color-white;
  } @else {
    color: $accent-color;
  }
}

.header-items .title {
  @include font-size($font-size-header-item-title);

  display: block;
  font-family: $fontfamily-regular-bold;
  line-height: 1;
  margin: 0 0 2px;

  @if ($theme-name == "com") {
    color: $accent-color;
    font-weight: bold;
    white-space: nowrap;
  }

  @if ($theme-name == "leukoplast") {
    font-weight: bold;
  }
}

.header-items .text {
  display: inline-block;
  vertical-align: middle;
  font-family: $fontfamily-regular;

  @if ($theme-name == "leukoplast") {
    color: $segment-color-alt;
  } @else if ($theme-name == "actimove") {
    color: $color-light-black;
  } @else {
    color: $logo-color;
  }
}

.header-items a {
  display: inline-block;
}

.header-items .image {
  display: inline-block;
  margin-right: 10px;
  margin-top: 0;
  position: relative;
  vertical-align: middle;
}

.header-items .image img {
  height: 30px;
  width: auto;

  @if ($theme-name == "cutimed") {
    height: auto;
    vertical-align: middle;
    width: 25px;
  }
}

.header-items .subtitle {
  @include font-size($font-size-header-item-title);

  display: block;
  margin: 0;
}

@media (min-width: $grid-bp-md) and (max-width: $grid-bp-lg) {
  .header-items {
    @if ($theme-name == 'men') {
      margin-top: 15px;
    }
  }
}