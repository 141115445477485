.testimonial-container {
  border-bottom: 1px solid $border-color;
  border-top: 1px solid $border-color;
  padding-bottom: 65px;
  padding-top: 65px;

  @if ($theme-name == "men") {
    border-bottom: 1px solid $testimonials-border-color;
    border-top: 1px solid $testimonials-border-color;
  }

  .testimonial-quote {
    @include font-size($font-size-cta-title);

    font-family: $fontfamily-regular;
    margin-bottom: 30px;
  }
}

.testimonial-box .pushbox-content .pushbox-text .pushbox-text-content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
}

.testimonial-box .pushbox-content .pushbox-text .pushbox-text-content .pushbox-testimonial {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.testimonial-box .pushbox-content .pushbox-text .pushbox-text-content .pushbox-testimonial .testimonial-quotation {
  @include font-size-and-line-height(22px, 32px);

  font-weight: normal;
  margin: auto 15px 20px 15px;
  padding-top: 20px;

  @if ($theme-name == "men") {
    text-transform: uppercase;
    color: $color-white;
    font-family: $fontfamily-titles-thin;
    line-height: 28px;
    margin-left: 0;
  } @else {
    text-transform: none;
    font-family: $fontfamily-regular;
  }
}

@if ($theme-name == "men") {
  .testimonial-box .pushbox-content-inner {
    background: $color-blue;
    border-radius: 3px;
  }

  .testimonial-box .pushbox-content .pushbox-text .pushbox-text-content .pretitle {
    color: $color-white;
  }
}

.testimonial-box .pushbox-content .pushbox-text .pushbox-text-content .pushbox-testimonial .text {
  @include font-size-and-line-height(16px, 22px);
  margin-top: auto;

  @if ($theme-name == "men") {
    color: $color-white;
    margin: auto 0 15px;
  }
}

.testimonial-box .pushbox-content .pushbox-text .pushbox-text-content .text p {
  margin: 0;
}

.testimonial-box .pushbox-content .pushbox-button {
  position: relative;
}

.testimonial-box .pushbox-content.has-button .pushbox-text {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}

.testimonial-box .pushbox-content.semi-transparent {
  background-image: none;
}

.testimonial-box .pushbox-content-inner {
  min-height: auto;
}

.testimonials-full-width {
  .testimonial-quote {
    @include font-size-and-line-height(
      $testimonials-full-width-quote-font-size,
      $testimonials-full-width-quote-line-height
    );

    letter-spacing: -0.5px;

    @if ($theme-name == "men") {
      color: $color-purple;
      font-family: $fontfamily-gotham-black;
      text-transform: uppercase;
    }
  }

  .testimonial-quote-info {
    @include font-size-and-line-height(
      $testimonials-full-width-quote-info-font-size,
      $testimonials-full-width-quote-info-line-height
    );
  }

  @media (max-width: $grid-bp-sm) {
    @if ($theme-name == "men") {
      .testimonial-quote-info {
        text-transform: uppercase;
      }
    }
  }
}

@media (max-width: $grid-bp-sm) {
  @if ($theme-name == "men") {
    .testimonial-box .pushbox-content .pushbox-text .pushbox-text-content .pushbox-testimonial .text {
      text-transform: uppercase;
    }
  }
}

@if ($theme-name == "actimove") {
  .testimonials-border {
    border-top: 1px solid $testimonial-border-color;
    border-bottom: 1px solid $testimonial-border-color;
  }

  .testimonial-container {
    padding-top: 40px;
    padding-bottom: 40px;
    border: none;
  }

  .testimonial-container .testimonial-quote {
    font-family: $fontfamily-header;
    font-weight: 600;
    font-style: italic;
    color: $testimonial-quote-color;
    margin-bottom: 24px;
  }

  .testimonials-full-width .testimonial-quote-info {
    color: $testimonial-quote-info-color;
    margin: 0;
  }

  @media (min-width: $grid-bp-md) {
    .testimonial-container {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
}
