.component-sitemap {
  display: flex;
  flex-wrap: wrap;
}

.sitemap-column {
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
  margin-bottom: 10px;

  @media (min-width: $grid-bp-xs) {
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;
  }

  @media (min-width: $grid-bp-sm) {
    width: (100% / 3);
  }

  @media (min-width: $grid-bp-lg) {
    width: 25%;
  }
}

.sitemap-list {
  @extend .reset-list;
}

.sitemap-sub-list {
  @extend .reset-list;
  margin-left: 20px;
  @if $theme-name == "leukoplast" {
    font-size: 17px;
  }
}

.sitemap-list-title {
  @include font-setup();

  font-size: $sitemap-list-title-font-size;
}

.sitemap-list-item {
  line-height: 1.6em;
  list-style: none;
  word-break: break-word;
}

.sitemap-mega-list {
  @extend .reset-list;
  margin-left: 20px;
}

.sitemap-mega-item-link {
  border-bottom: 1px solid $home-color;
}

@if ($theme-name == "cutimed") {
  .sitemap-column {
    margin-bottom: 35px;

    @media (min-width: $grid-bp-md) {
      width: 25%;
    }

    @media (min-width: $container-max-width) {
      width: 20%;
    }
  }

  .sitemap-list-item {
    line-height: 22px;
  }

  .sitemap-sub-list {
    margin-left: 0;
  }

  .sitemap-list-title {
    font-family: $fontfamily-regular-bold-condensed;
    border-bottom: 1px solid $border-color;
    padding-bottom: 10px;
    margin-bottom: 20px;
    line-height: 26px;

    @media (min-width: $grid-bp-sm) {
      margin-bottom: 30px;
    }
  }

  .sitemap-list-item-title {
    font-family: $fontfamily-regular-bold;
  }
}

@if ($theme-name == "cutimed" or $theme-name == "actimove") {
  .sitemap-sub-list > .sitemap-list-item > .sitemap-sub-list {
    padding-left: 20px;
  }
}

@if ($theme-name == "actimove") {
  .sitemap-column {
    margin-bottom: 20px;
    width: 100%;

    @media (min-width: $grid-bp-md) {
      width: 25%;
    }
  }

  .sitemap-list-item {
    line-height: 22px;
    margin-bottom: 12px;
  }

  .sitemap-sub-list {
    margin-top: 12px;
    margin-left: 0;
  }

  .sitemap-list-title {
    @include arrowIcon($color-primary);

    font-family: $fontfamily-titles-normal;
    position: relative;
    line-height: 26px;
    margin-bottom: 16px;

    &:after {
      top: unset;
      right: auto;
      // Negative value is added to position the arrow exactly after the words,
      // no matter how much text we have
      bottom: -12px;
      margin-left: 10px;
      color: $color-light-black;
    }
  }

  .sitemap-list-title-link {
    color: $color-light-black;
    font-style: italic;

    &:hover {
      color: $home-color;
    }
  }

  .sitemap-list-item-title,
  .sitemap-list-item-subtitle {
    color: $color-primary;

    &:hover {
      color: $color-accent;
    }
  }
}