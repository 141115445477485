.footer {
  position: relative;
  @if ($theme-name == "leukoplast") {
    color: $color-white;
  }
  @include mediaquery(xs) {
    @if ($theme-name == "com") {
      margin-top: 60px;
    } @else {
      margin-top: 85px;
    }
  }
  h2,
  p {
    @if ($theme-name == "leukoplast") {
      color: $color-white;
    } @else {
      color: $home-color;
    }
  }
  p {
    @if ($theme-name == "leukoplast") {
      line-height: 1.3em;
    }
    @if ($theme-name == "men") {
      font-family: $fontfamily-titles-thin;
    } @else {
      font-family: $fontfamily-regular;
    }
  }
  a {
    @if ($theme-name == "com") {
      color: #fff;
    }
  }
  h2 {
    @if ($theme-name == "men") {
      font-family: $fontfamily-regular-bold;
    } @else {
      font-family: $fontfamily-regular;
    }
    @include font-size($font-size-footer-h2);
    @if ($theme-name == "com") {
      margin-bottom: 13px;
    } @else {
      margin-bottom: 20px;
    }
    font-weight: 100;
  }
  img {
    max-width: 100%;
  }
  .footer-columns {
    background-color: $footer-bg-color;
    padding: 55px 0 0;

    .footer-column {
      @if ($theme-name == "leukoplast") {
        line-height: 1.3em;
      }
      .btn {
        @if ($theme-name == "leukoplast") {
          border-color: $footer-font-color;
        } @else {
          border-color: $submenu-link-color;
        }
        &:hover {
          background-color: inherit;
          @if ($theme-name == "leukoplast") {
            background-color: #fff;
            border-color: #fff;
            color: $footer-bg-color;
          } @else {
            border-color: $submenu-link-hover;
            color: $submenu-link-hover;
          }
        }
      }
      .footer-text-angle-links {
        @if ($theme-name != "leukoplast") {
          font-family: $footer-fontfamily-bold;
        } @else {
          font-size: $font-size-footer-toolbar;
          line-height: 22px;
        }
        margin-bottom: 5px;
        &:hover {
          @if ($theme-name == "leukoplast") {
            text-decoration: underline;
          } @else {
            color: $footer-link-hover-color;
          }
        }
        i {
          margin-left: 5px;
          vertical-align: middle;
        }
      }
    }

    @media (max-width: $grid-bp-sm) {
      .footer-column:last-child {
        margin-bottom: 30px;
      }
    }

    .footer-text-angle-links a {
      @include font-setup();
    }
    a {
      @if ($theme-name != "leukoplast") {
        color: $submenu-link-color;
      } @else {
        color: $color-white;
      }
      &:hover {
        @if ($theme-name != "leukoplast") {
          color: $submenu-link-hover;
        }
      }
      text-decoration: none;
    }
    .reduced-width-paragraph {
      width: 90%;
    }
  }
  .footer-toolbar {
    @if ($theme-name == "com") {
      padding: 17px 0;
    } @else {
      padding: 23px 0;
    }
    @if ($theme-name == "leukoplast") {
      border-bottom: 1px solid $segment-color;
      color: $segment-color-alt;
    } @else {
      color: $color-white;
    }
    @include font-size($font-size-footer-toolbar);
    background-color: $footer-toolbar-background-color;
    ul {
      @include clearfix();
      margin: 0;
      padding: 0;
      li {
        float: left;
        list-style-type: none;
        i {
          font-size: 6px;
          vertical-align: middle;
        }
        a {
          @if ($theme-name == "leukoplast") {
            color: $segment-color-alt;
          } @else {
            color: $color-white;
            font-family: $fontfamily-regular;
          }

          margin: 0 10px;
          &:hover {
            @if ($theme-name == "leukoplast") {
              color: $logo-color;
            } @else {
              text-decoration: underline;
            }
          }
        }
        &:first-child {
          margin-left: 0;
          a {
            margin-left: 0;
          }
        }
      }
    }
    @include mediaquery(xs) {
      @if ($theme-name != "leukoplast" or $theme-name != "cutimed") {
        ul {
          margin-bottom: 20px;
          li {
            float: none;
            margin-bottom: 10px;
            i {
              display: none;
            }
            a {
              margin-left: 0;
            }
          }
        }
      } @else {
        ul li {
          margin-bottom: 20px;

          a {
            margin-left: 0;
          }
        }
      }
    }
    .copyright {
      @if ($theme-name == "com") {
        display: block !important;
      }
      @include mediaquery(md) {
        @if ($theme-name != "leukoplast") {
          text-align: left;
        }
      }
      text-align: right;

      @if ($theme-name == "leukoplast") {
        margin-top: 20px;

        @media (min-width: $grid-bp-lg) {
          margin-top: 0;
        }
      }
    }
  }
  .footer-bottom {
    @if ($theme-name == "com") {
      background-color: #fbf9f4;
    }

    @if ($theme-name != "cutimed") {
      padding: 65px 0;
    }

    text-align: center;
  }
}

%share-link {
  img {
    border-radius: 3px;
    @if ($theme-name == "leukoplast") {
      max-height: 60px;
      max-width: 60px;
    } @else {
      max-height: 48px;
      max-width: 48px;
    }
    vertical-align: middle;
  }
}

.footer-social {
  padding-left: 0;
}

.footer-social-item {
  @extend %share-link;

  height: 50px;
  list-style: none;
  margin-bottom: 25px;

  @if ($theme-name == "leukoplast") {
    display: inline-block;
    margin-right: 25px;
  }
}

.footer-social-link {
  display: inline-block;
  font-family: $footer-fontfamily-bold;
  padding-left: 0;

  &:hover {
    color: $submenu-link-hover;
  }
}

.footer-social-title {
  @include font-setup();
  margin-left: 10px;
}

.footer-social-link .icon-footer-link {
  margin-left: 5px;
  vertical-align: middle;
}

@if  $theme-name == "leukoplast" {
  .icon-footer-link {
    display: none;
  }
}

/***** Footer design update *****/

.footer-frost {
  position: relative;
}

.footer-frost .footer-columns {
  @include font-size-and-line-height($font-size-footer-paragraph, $footer-lineheight-paragraph);
  background: $footer-bg-tena-color;
  color: $color-white;

  @media (max-width: $grid-bp-sm) {
    padding-bottom: 25px;
  }

  a {
    color: inherit;
  }

  p {
    @include font-size-and-line-height($font-size-footer-paragraph, $footer-lineheight-paragraph);
  }
}

.footer-frost .footer-column {
  div,
  span {
    margin-bottom: 10px;
  }

  p {
    color: $color-white;
  }
}

.footer-frost .footer-columns h2 {
  @include font-size-and-line-height($font-size-footer-h2, $font-size-footer-h2-lineheight);
  margin: 0;
  padding-top: 32px;
  padding-bottom: 12px;
  font-family: $fontfamily-regular;
  font-weight: normal;
}

.footer-frost .footer-columns .footer-text-angle-links a {
  @include font-size-and-line-height($font-size-footer-paragraph, $footer-lineheight-paragraph);
  display: flex;
  align-items: center;
  padding-bottom: 12px;
}

.footer-frost .footer-columns .footer-social-item {
  @include font-size-and-line-height($font-size-footer-paragraph, $footer-lineheight-paragraph);
  height: auto;
  margin-bottom: 0;
}

.footer-frost .footer-columns .footer-social-title {
  margin-left: 0;
  font-family: $fontfamily-regular;
  font-weight: normal;
}

.footer-frost .footer-columns .footer-social-img {
  background-color: $color-white;
  border-radius: 0;
}

.footer-frost .footer-columns .icon-footer-link {
  margin-left: 12px;
}

.footer-frost .footer-columns .footer-social {
  margin: 0;
}

.footer-frost .footer-toolbar li {
  list-style-type: none;
  margin: 5px 0;

  @media (max-width: $grid-bp-sm) {
    margin: 0;
  }
}

.footer-frost .footer-toolbar-links .footer-anchor-links,
.footer-frost .DropDown-link--footer,
.footer-frost .copyright {
  display: flex;
  color: $footer-links-color;
}

.footer-frost .copyright {
  @include font-size-and-line-height($font-size-footer-paragraph, $footer-lineheight-paragraph);
  display: flex;
  justify-content: center;
}

.footer-frost .footer-toolbar-links .footer-dropdown {
  background: url("../img/location.png") no-repeat center center;
  background-position: left 25%;
}

.footer-frost .DropDown-link--footer {
  @include font-size-and-line-height($font-size-footer-paragraph, $footer-lineheight-links);
  margin-left: 25px;
}

.footer-frost .footer-toolbar-links {
  display: flex;
  justify-content: center;
  @if ($theme-name != "cutimed") {
    padding-bottom: 24px;
  }
}

.footer-frost .footer-toolbar-links .footer-link-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.footer-frost .footer-toolbar-links .footer-anchor-links {
  @include font-size-and-line-height($font-size-footer-paragraph, $footer-lineheight-links);
  margin-right: 40px;
}

.footer-frost .footer-toolbar {
  background: $footer-bg-tena-color;
  padding-bottom: 40px;
}

.footer-frost .country-selector-columns-container .submenu-bg-world-map-list li a {
  margin-left: 0;
}

.footer-frost .arrow {
  transform: rotate(180deg);
  top: -3px;
}

.footer-frost .country-selector--footer .submenu {
  bottom: 60px;
}

.footer-frost .footer-dropdown .select2,
.footer-frost .footer-dropdown .js-mobile-footer-changeLang {
  display: none;
}

.footer-frost .js-mobile-footer-changeLang .select2-selection--single {
  background-color: $footer-bg-tena-color;
}

.footer-frost .js-mobile-footer-changeLang .select2-selection__rendered {
  color: $color-white;
}

.footer-frost .footer-bottom {
  text-align: center;
}

.footer-toolbar-content {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
}

.footer .footer-column-title {
  margin-bottom: 30px;
}

.footer-disclaimer-text,
.footer .footer-disclaimer-text,
.footer-tcw .footer-disclaimer-text {
  border-bottom: 2px solid $logo-color;
  color: $logo-color;
  width: 100%;
  padding: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

@media (min-width: $grid-bp-sm) {
  .footer-disclaimer-text,
  .footer .footer-disclaimer-text,
  .footer-tcw .footer-disclaimer-text {
    font-size: 32px;
    padding: 16px 10px;
  }
}

@media (max-width: $container-max-width) {
  .footer-toolbar-content {
    flex-direction: column;
  }

  @if ($theme-name == "cutimed") {
    .footer .footer-toolbar .copyright {
      text-align: left;
    }
  }

  @if ($theme-name == "leukoplast") {
    .footer .footer-toolbar .copyright {
      text-align: right;
    }
  }
}

@if ($theme-name == "cutimed") {
  .footer .footer-columns p,
  .footer .footer-columns a,
  .footer .footer-columns article {
    color: $color-gray;
  }

  .footer .footer-column-title {
    color: $color-gray;
    font-size: 28px;
    line-height: 32px;
    font-family: $fontfamily-regular-bold-condensed;
  }

  .footer .footer-columns p,
  .footer .footer-columns div,
  .footer .footer-columns span {
    font-family: $fontfamily-regular;
    line-height: 25px;
  }

  .footer .footer-columns .footer-social-item {
    margin-bottom: 25px;
  }

  .footer .footer-social-img {
    margin-right: 10px;
  }

  .footer .footer-columns .btn {
    color: $color-blue;
    padding: 7px 45px 9px;
  }

  .footer .footer-logo {
    margin: 70px 0 90px 0;
  }

  .footer .copyright {
    margin: 0 0 0 75px;
  }

  .footer-toolbar-content {
    display: flex;
    justify-content: left;
  }

  .footer .footer-toolbar {
    padding-bottom: 24px;
    background-color: $color-blue;
  }

  .footer-social-link .icon-footer-link {
    margin-left: 10px;
  }

  .footer .footer-columns .footer-column .footer-anchor-link {
    border-color: $color-blue;
  }

  @media (max-width: $container-max-width) {
    .footer-toolbar-content {
      flex-direction: column;
    }

    .footer-toolbar-links {
      justify-content: left;
    }

    .footer-toolbar-links .footer-link-list {
      flex-direction: column;
    }

    .footer .footer-toolbar-items ul {
      margin-bottom: 0;
    }

    .footer .footer-toolbar-items ul li {
      margin-bottom: 20px;
    }

    .footer .copyright {
      margin: 20px 0 0 0;
    }
  }

  @media (max-width: $grid-bp-sm) {
    .footer-social-link .icon-footer-link {
      display: none;
    }

    .footer-logo {
      margin: 60px 0;
    }

    .footer .footer-social-title {
      display: none;
    }

    .footer .footer-social {
      display: flex;
    }
  }
}

@media (max-width: $grid-bp-xs-max) {
  .footer-frost .country-selector--footer {
    display: none;
  }

  .footer-frost .footer-dropdown .select2,
  .footer-frost .footer-dropdown .js-mobile-footer-changeLang {
    display: block;
  }

  .footer-frost .footer-dropdown .select2 .select2-selection {
    background-color: $footer-bg-tena-color;
  }

  .footer-frost .footer-dropdown .select2 .select2-selection__rendered {
    color: $footer-links-color;
  }

  .footer-frost .copyright {
    justify-content: left;
    padding-left: 5px;
  }

  .footer-frost .DropDown-link--footer {
    min-height: 30px;
    margin-top: 4px;
  }

  .footer-frost .footer-toolbar-links {
    flex-direction: column;
    justify-content: flex-start;
  }

  .footer-frost .footer-toolbar-links .footer-link-list {
    flex-direction: column;
    padding: 0 10px;
  }

  .footer-frost .footer-toolbar-links .footer-anchor-links {
    @include font-size-and-line-height($font-size-footer-paragraph, $footer-lineheight-links);
    padding-bottom: 12px;
  }
}

@media (min-width: $grid-bp-sm) {
  .footer-frost .footer-toolbar {
    padding-top: 40px;
  }

  @if ($theme-name == "cutimed") {
    .footer-frost .footer-toolbar {
      padding-top: 24px;
    }
  }
}

@if ($theme-name == "leukoplast") {
  @media (max-width: $grid-bp-sm) {
    .footer .footer-toolbar ul li {
      float: left;
    }
  }
}

@if ($theme-name == "actimove") {
  .footer-frost .footer-columns,
  .footer-frost .footer-toolbar {
    background-color: $color-primary-lightest;
  }

  .footer-frost .footer-columns h2 {
    @include font-size-and-line-height(22px, 26px);

    font-family: $fontfamily-header;
    font-style: italic;
    color: $section-title-color;
  }

  .footer-text-angle-links {
    @include font-size-and-line-height(16px, 22px);

    font-weight: 600;
    color: $color-primary;
  }

  .footer-frost .footer-column p,
  .footer-frost .copyright {
    color: $color-light-black;
  }

  .footer-frost .footer-toolbar-links .footer-anchor-links {
    color: $color-primary-light;
  }

  .footer-frost .footer-columns .footer-social-title {
    color: $color-primary;
    font-weight: 600;
  }

  .footer-frost .footer-toolbar-links .footer-dropdown {
    background: none;
  }

  .footer-frost .DropDown-link--footer {
    margin-left: 10px;
    color: $color-primary-light;

    &::before {
      content: "\e61b";
      display: inline-block;
      font-family: "Icomoon";
      right: 5px;
      position: relative;
      top: 0;
      width: 15px;
    }
  }

  .footer-frost .DropDown-link--footer.opened::before,
  .footer-frost .footer-dropdown .select2.select2-container--open .select2-selection__rendered::before {
    content: "\e61d";
  }

  .footer-frost .footer-dropdown .select2 .select2-selection {
    background-color: transparent;
    border: none;
  }

  .footer-frost .footer-dropdown .select2 .select2-selection__rendered {
    color: $color-primary-light;

    &::before {
      content: "\e61b";
      display: inline-block;
      font-family: "Icomoon";
      right: 5px;
      position: relative;
      top: 2px;
      width: 15px;
    }
  }

  .footer-frost .select2-container--default .select2-selection--single .select2-selection__arrow {
    display: none;
  }

  .footer-frost .footer-toolbar-links,
  .footer-frost .copyright {
    justify-content: flex-start;
  }

  .footer-frost .footer-toolbar-links .footer-link-list {
    flex-direction: column;
  }

  @media (min-width: $grid-bp-sm) {
    .footer-frost .footer-toolbar {
      padding-left: 20px;
      padding-top: 20px;
    }
  }

  @media (min-width: $grid-bp-md) {
    .footer-frost .footer-toolbar-links .footer-link-list {
      flex-direction: row;
    }

    .footer-frost .footer-toolbar-links,
    .footer-frost .copyright {
      justify-content: center;
    }
  }
}