/* Desktop filter */
.table-header-content {
  display: flex;
  justify-content: left;
  align-items: center;
}

/* Mobile filter */
$table-filter-icon-size: 24px;

.table-filter--is-active .table-filter-items,
.table-filter--is-active .table-filter-apply {
  display: block;
}

.table-filter--has-applied-filters .table-filter-applied-list,
.table-filter--has-applied-filters .table-filter-footer,
.table-filter--has-applied-filters .table-filter-reset-all,
.table-filter--is-active .table-filter-reset-all {
  display: inline-block;
}

.table-filter-items,
.table-filter-apply,
.table-filter-reset-all,
.table-filter-controls .table-filter-toggle,
.table-filter-item--is-active .table-filter-item-active,
.table-filter-controls,
.table-filter--is-active .table-filter-applied-list,
.table-filter-item--is-active .arrow {
  display: none;
}

.table-filter-apply {
  @include btn("primary");

  font-size: 1rem;
  line-height: 1.125;
  margin: 0 auto;
  text-align: center;
  padding: 13px 20px;
  color: $color-white;
  background-color: $logo-color;
  width: 100%;
}

.table-filter-footer .table-filter-apply.btn {
  border-radius: 0;
  color: $color-white;
  background-color: $logo-color;
}

.btn.table-filter-reset-all {
  color: $logo-color;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 0;
  text-decoration: underline;
}

.table-filter--is-active .table-filter-reset-all {
  margin-top: 20px;
}

.table-filter {
  background-color: $color-white;
  user-select: none;

  &--is-active {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: $z-index-overlay;
  }
}

.table-filter-mobile-bar {
  background-color: $logo-color;
  border-radius: 3px;
  color: $color-white;
  display: flex;
  justify-content: space-between;
}

.table-filter--is-active .table-filter-footer {
  bottom: 0;
  left: 0;
  margin: 15px;
  position: fixed;
  right: 0;
}

.table-filter--is-active .table-filter-mobile-bar {
  background-color: $color-white;
}

.table-filter--is-active .table-filter-mobile-bar {
  color: inherit;
  margin-left: 0;
  margin-right: 0;
}

.table-filter--is-active .table-filter-toggle-mobile {
  flex-direction: row-reverse;
}

.table-filter-toggle {
  background-color: transparent;
  border: none;
  color: inherit;
  font-size: 16px;
  line-height: 1;
  vertical-align: middle;
  padding-right: 0;
}

.table-filter-toggle-mobile {
  cursor: pointer;
  font-size: $table-filter-icon-size;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 6px 15px;
}

.table-filter--is-active .table-filter-toggle-mobile {
  pointer-events: none;

  &::before {
    pointer-events: auto;
  }
}

.table-filter-toggle-label {
  @include font-size-and-line-height(15px, 20px);

  font-family: $fontfamily-regular;
  line-height: $table-filter-icon-size;
  font-weight: 400;
}

.table-filter--is-active .table-filter-toggle-label {
  @include font-size-and-line-height(20px, 27px);
  color: $filter-main-text-color;
}

.table-filter-single-active {
  @include font-size-and-line-height(16px, 21px);

  color: $logo-color;

  &::after {
    content: ", ";
  }

  &:last-of-type::after {
    content: none;
  }
}

.table-filter-item {
  border: 1px solid $border-color;
  margin-bottom: 10px;
  padding: 9px;
}

.table-filter-item .icon-arrow-down {
  padding-top: 4px;
}

.table-filter-item-title {
  @include font-size-and-line-height(17px, 21px);

  color: $filter-main-text-color;
  margin-right: 10px;
}

.table-filter-items {
  padding: 15px;
}

.table-filter--is-active .table-filter-items {
  max-height: calc(100% - 150px);
  overflow-y: auto;
}

.table-filter-item--is-active .table-filter-controls {
  display: block;
  margin-top: 10px;
}

.table-filter-controls .horizontal-list {
  display: flex;
  flex-wrap: wrap;
}

.table-filter-controls .table-filter-toggle.icon-validation-cross {
  display: none;
}

.table-filter-btn {
  @include roundedButton();
  @include font-size-and-line-height(16px, 18px);

  color: $logo-color;
  border: 1px solid $logo-color;
  padding: 7px 13px;
  margin-right: 0;

  &--is-active {
    background-color: $logo-color;
    color: $color-white;
    border: none;
    outline: none;
  }

  &:focus {
    outline: none;
    border: 1px solid $color-black;
  }

  @media (min-width: $grid-bp-md) {
    &:hover {
      background-color: $logo-color;
      color: $color-white;
    }
  }
}

@media (max-width: $grid-bp-md) {
  .table-filter-btn:disabled {
    cursor: initial;
    opacity: 0.2;
  }
}

.table-filter-applied-list .table-filter-btn {
  background-color: $table-filter-applied-filter-bg-color;
  border: none;
  color: $home-color;
  line-height: 1.125;
  margin-right: 13px;
}

.table-filter-btn-info {
  cursor: default;
  pointer-events: none;
}

.table-filter-item-name {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.table-filter-applied-list {
  padding: 15px 0;
}

.table-filter-toggle-icon {
  fill: $color-white;
  height: $table-filter-icon-size;
  vertical-align: middle;
  width: 22px;
}

.table-filter-nothing-applicable {
  cursor: default;
  margin-bottom: 0;
}

.table-filter .table-filter-footer .disable-filters-apply {
  background-color: $segment-color;
  border: 1px solid $segment-color;
}

.disable-filters-apply {
  position: relative;
}

.disable-filters-apply::before {
  background-color: $color-white;
  border: 1px solid $disable-filters-apply-border-color;
  color: $color-black;
  content: attr(disableBtnText);
  display: none;
  font-size: 14px;
  padding: 5px;
  position: absolute;
  top: -30px;
  left: 0px;
  z-index: 1;
}

.disable-filters-apply:hover::before {
  display: block;
}

.table-filter-list-controls {
  display: none;
}

.table-filter-list {
  display: flex;
  list-style-type: none;
  flex-flow: wrap;
  padding: 0;
  margin: 0;
}

@media (min-width: $grid-bp-md) {
  .table-filter-single-active,
  .table-filter-mobile-bar,
  .table-filter-footer {
    display: none;
  }

  .table-filter-list-controls {
    display: block;
    margin-bottom: 10px;
  }

  .table-filter-list-control {
    background: none;
    border: none;
    outline: none;
    text-decoration: underline;
    color: $table-filter-controls-color;
    cursor: pointer;
    padding-left: 0;

    &:hover {
      color: $logo-color;
    }
  }

  .table-filter-item-title:hover {
   color: $logo-color;
  }

  .table-filter-controls .table-filter-toggle {
    display: block;
    position: absolute;
    right: -25px;
  }

  .table-filter-apply-btn {
    @include btn("secondary");
  }

  .table-filter-items {
    display: flex;
    padding: 0;
  }

  .table-filter--show-all .table-filter-items {
    flex-wrap: wrap;
  }

  .table-filter--show-all .table-filter-item-separator {
    flex-basis: 100%;
    height: 0;
    margin-bottom: 1em;
  }

  .table-filter-item {
    border: none;
    margin-bottom: 4px;
    margin-right: 20px;
    padding-bottom: 4px;
    padding-top: 4px;
    position: relative;
  }

  .table-filter-icon::before {
    line-height: 1;
    vertical-align: middle;
    color: $table-filter-active-color;
    font-weight: bold;
  }

  .table-filter-item--is-active .table-filter-controls {
    background-color: $color-white;
    border: 1px solid $menu-submenu-border-color;
    display: block;
    left: 0;
    margin-top: 10px;
    padding: 7px;
    position: absolute;
    width: max-content;
    z-index: $z-index-overlay;
  }

  .table-filter--is-active {
    position: static;
  }

  .table-filter--is-active .table-filter-items {
    max-height: 100%;
    overflow-y: initial;
  }

  .table-filter--is-active .table-filter-items {
    display: flex;
  }

  .table-filter-item--is-active .arrow {
    display: block;
  }

  .table-filter-item--is-applied .table-filter-item-title {
    color: $table-filter-active-color;
  }

  .table-filter-item .icon-arrow-down {
    padding-top: 0;
  }

  .table-filter-list {
    display: grid;
    grid-template-columns: 1fr;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
}
