.campaign-media-container .jw-preview.jw-reset {
  background-color: transparent !important;
  background-size: cover !important;
  display: block;
}

.inline-video-container .jwplayer {
  background-color: transparent !important;
}

.video-player-bg-image {
  bottom: 0;
  display: block;
  height: 100%;
  margin: auto;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

@if $theme-name == "leukoplast" {
  .mfp-content .jw-display.jw-reset {
    display: table !important;
  }

  .mfp-content .jw-display-icon-container {
    background-color: $btn-play-background-transparent !important;
    border-radius: 50%;
  }

  .jw-display-icon-rewind {
    display: none !important;
  }

  .jw-display .jw-icon {
    height: 100px !important;
    line-height: 100px !important;
    width: 100px !important;
    @media (max-width: $grid-bp-sm){
      height: 70px !important;
      line-height: 70px !important;
      width: 70px !important;
    }
  }

  .jw-display .jw-display-icon-next.jw-reset {
    display: none !important;
  }

}

.campaign-media-container .jw-wrapper {
  background-color: transparent;
}
