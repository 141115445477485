/* Breadcrumbs in header */

.Breadcrumbs-container {
  padding: 15px 0;

  @if ($theme-name == "leukoplast") {
    margin-top: 77px;
  } @else if ($theme-name == "cutimed") {
    background-color: $color-white;
    margin-top: 60px;
  } @else {
    margin-top: 100px;
  }

  @if ($theme-name == "men") {
    background-color: $color-purple;
  }

  ul {
    float: left;
    margin: 0;
    padding: 0;

    li {
      color: $breadcrumb-color;
      float: left;
      @if ($theme-name == "cutimed") {
        @include font-size-and-line-height(12px, 25px);
      } @else {
        @include font-size-and-line-height(16px, 22px);
      }
      letter-spacing: 0.02em;
      list-style: none;
      margin-right: 20px;

      &:first-child {
        @if ($theme-name != "cutimed") {
          display: none;
        }

        &:before,
        span {
          display: none;
        }
      }

      &:before {
          content: $icon-short-arrow-left;
          font-family: "Icomoon";
          margin-right: 15px;
          position: relative;
          top: 1px;

          @if ($theme-name == "men") {
            color: $color-yellow;
          }

          @if ($theme-name == "actimove") {
            color: $color-black;

            @media (min-width: $grid-bp-md) {
              color: $breadcrumb-arrow-color;
            }
          }
      }

      &:last-child {
          max-width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          span {
            text-decoration: none;

            @if ($theme-name == "actimove") {
              color: $color-black;
              font-weight: 600;
            }
          }

          &:after {
              content: "";
          }
      }

      a {
        color: $breadcrumb-color;

        @if ($theme-name != "actimove") {
          text-decoration: underline;
        }

        &:hover {
         color: $breadcrumb-hover-color;
        }
      }

      @if ($theme-name == "men") {
        a,
        span {
          color: $color-white;
        }
      }

      // hide all breadcrumb elements except the last step (the one to go back to)
      &:not(:nth-last-child(2)):not(:first-child) {
        display: none;
      }

      // on mobile, this is the arrow to the left
      &:nth-last-child(2) {
        &::before {
          display: inline-block !important;

          // for cutimed, this is arrow to the right
          @if ($theme-name != "cutimed") {
            content: $icon-short-arrow-left;
          } @else {
            content: $icon-short-arrow-right;
          }
        }
      }

      // show a non-clickable HOME when on segment root
      &:first-child:nth-last-child(2) {
        @if ($theme-name != "cutimed") {
          visibility: hidden;
        } @else {
          visibility: visible;
        }

        &::before {
          content: "";
          display: none !important;
        }

        span {
          text-decoration: none;
        }

        a {
          pointer-events: none;
          cursor: default;
        }
      }

      // show a clickable HOME to go back to (we are 1 step away from segment root)
      &:first-child:nth-last-child(3) {
        display: block !important;

        &::before {
          display: inline-block;
          content: $icon-short-arrow-left;
        }

        @if ($theme-name == "cutimed") {
          &::before {
            content: $icon-short-arrow-right;
          }

          @media (max-width: $grid-bp-sm-max) {
            span {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  @if ($theme-name == "leukoplast") {
    .home {
      position: relative;
    }
  }

  .home-link {
    @if ($theme-name != "actimove") {
      text-decoration: underline;
    }

    @if ($theme-name == "leukoplast") {
      color: transparent;

      &::before {
        position: absolute;
        left: 0;
        color: $breadcrumb-color;
        content: $icon-home;
        font-family: $fontfamily-icon;
        font-size: 23px;

        @media (min-width: $grid-bp-md) {
          left: 18px;
        }
      }
    }
  }
}

@if ($theme-name == "cutimed") {
  .Breadcrumbs-container .home-item:only-child a {
    pointer-events: none;

    span {
      text-decoration: none;
    }
  }

  @media (max-width: $grid-bp-sm-max) {
    .breadcrumbs-multiple .home-item {
      display: none;
    }

    .home-item:only-child {
      display: block;
    }
  }
}

.breadcrumb-hidden {
  padding: 0;
}

@if ($theme-name == 'men') {
  .breadcrumb-hidden {
    visibility: hidden;
  }

  .breadcrumb-hidden ul {
    height: 0;
  }
}

@media (min-width: $grid-bp-md) {
  .Breadcrumbs-container {
    margin-top: 0;

    ul li {
      // hide all breadcrumb elements except the last step (the one to go back to)
      &:not(:nth-last-child(2)):not(:first-child) {
        display: block;
     }

     // all arrows to be pointed to the right on desktop
      &::before {
        content: $icon-short-arrow-right !important;
        margin-right: 15px;
      }

      // HOME has no arrow on desktop
      &:first-child {
        display: block;

        &.home-item::before {
          content: "" !important;
        }
      }

      // remove the space for the arrow so the breacrumb does not move to the right when it has 2 items in it
      &:first-child:nth-last-child(3) {
        &::before {
          display: none !important;
        }
      }

      &:first-child:nth-last-child(2) {
        @if ($theme-name != "cutimed") {
          visibility: hidden;
        } @else {
          visibility: visible;
        }
      }
    }
  }

  @if ($theme-name == 'men') {
    .Breadcrumbs-container.breadcrumb-hidden {
      margin-top: -1px;
    }
  }
}