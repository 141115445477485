.one-col-text {
  @include svg-play-icon();
}

.one-col-text .svg-icon.play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@if ($theme-name == "cutimed") {
  .one-col-text .component-title {
    @include font-size-and-line-height(28px, 32px);
  }
}

@if ($theme-name == "leukoplast") {
  .one-col-text {
    width: 100%;
  }
}

@if ($theme-name == "actimove") {
  .one-col-text .component-title {
    @include font-size-and-line-height(22px, 26px);

    font-weight: 600;
  }

  .one-col-text .article .text-content {
    color: $color-light-black;
  }
}