$header-spacing: 30px;

.header-menu {
  @include mobileMenuWidth();

  background-color: $color-white;
  @if ($theme-name == "cutimed") {
    min-height: 50px;
  } @else {
    min-height: 63px;
  }
  padding: 10px 0;

  @media (min-width: $grid-bp-md) {
    padding: 0;
    position: relative;
    width: auto;

    @if ($theme-name == "leukoplast") {
      border-top: 1px solid $segment-color;
    }
  }
}

@media (min-width: $grid-bp-md) {
  @if ($theme-name == "men") {
    .header-menu {
      background-color: transparent;
    }
  }

  .header-menu .container {
    min-height: 55px;
  }

  .links li {
    min-height: 25px;
  }
}

.header-top .links li:hover > a,
.header-top .languages a:hover,
.header-top .country-selector a:hover {
  @if ($theme-name == "leukoplast") {
    color: $logo-color;
  } @else if ($theme-name == "men")  {
    color: $color-white;
  } @else {
    color: $accent-color;
  }
}

@if ($theme-name == "men")  {
  .header-top .links .country-selector li:hover > a {
    color: inherit;
  }
}

.mobile-nav--is-active .header-menu {
  @if ($theme-name == "cutimed") {
    background-color: $color-light-blue-2;
    padding-bottom: 45px;
  } @else {
    background-color: $page-background-color;
  }
  left: 0;
  z-index: 3;
}

.mobile-nav--is-active .ChangeSite-container {
  pointer-events: none;
}

.header-menu .horizontal-list > li {
  float: none;
  padding: 0;

  @media (min-width: $grid-bp-md) {
    float: left;
  }
}

.mobile-nav--is-active .menu-item .menu-item-main-title {
  padding-left: 0;
}

.mobile-nav--is-active .header-menu .submenu--is-active {
  position: static;
}

.mobile-nav--is-active .header-menu .arrow {
  display: none;
}

.header-menu .menu-item.has-submenu .menu-item-expand {
  padding-left: 30px;
  -webkit-tap-highlight-color: transparent;

  @media (min-width: $grid-bp-md) {
    padding-left: 15px;
    position: static;
  }

  &::before,
  &::after {
    border-color: $submenu-link-color;
    border-style: solid;
    border-width: 0;
    content: "";
    height: $mobile-menu-expand-icon-width;
    left: 0;
    position: absolute;
    width: $mobile-menu-expand-icon-width;

    @if ($theme-name == "leukoplast") {
      border-color: $segment-color;
    }
  }

  &::before {
    border-top-width: 2px;
    @if ($theme-name == "leukoplast") {
      top: 12px;
    } @else {
      top: 10px;
    }
  }

  &::after  {
    border-left-width: 2px;
    left: calc(#{$mobile-menu-expand-icon-width} / 2 - 1px);
    @if ($theme-name == "leukoplast") {
      top: 5px
    } @else {
      top: 4px;
    }
  }

  &.opened {
    &::after {
      display: none;
    }

  }

  &::before,
  &::after {
    @media (min-width: $grid-bp-md) {
      display: none;
    }
  }
}

.header-menu-container {
  @include transition(left $mobile-header-animation-speed);
  @include mobileMenuWidth();
  @include mobileMenuLeft();

  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;

  @if ($theme-name != "leukoplast") and ($theme-name != "men") {
    @if ($theme-name == "cutimed" or $theme-name == 'actimove') {
      border-bottom: none;
    } @else {
      border-bottom: 1px solid $footer-bg-color;
    }
  }

  @media (min-width: $grid-bp-md) {
    @if ($theme-name == "cutimed") {
      min-height: 50px;
    } @else {
      min-height: 64px;
    }

    overflow-x: initial;
    overflow-y: initial;
    position: initial;
    width: 100%;
  }
}

.mobile-nav--is-active .header-menu-container {
  left: 0;
}

@media (min-width: $grid-bp-md) {
  .header-menu-container.header-menu--hidden {
    min-height: 0;
  }
}

.header-menu-container.header-menu--hidden .header-menu {
  min-height: 0;
}

.header-mobile-country-language {
  background-color: #fff;
  display: inline-block;
  padding: 20px;
  padding-right: 0;
  width: 100%;

  @if ($theme-name == "leukoplast") {
    padding-top: 50px;
  }

  @media (min-width: $grid-bp-md) {
    display: none;
  }
}

.header-mobile-country-language .languages {
  color: $submenu-link-color;
  display: inline-block;

  @if ($theme-name == "cutimed") {
    font-size: 12px;
  } @else {
    font-size: 14px;
  }
}

.header-mobile-country-language .languages a {
  color: inherit;
}

.header-mobile-country-language .country-selector,
.header-mobile-country-language .languages,
.header-mobile-country-language .language-selector {
  float: none;
}

.header-mobile-country-language .country-selector,
.header-mobile-country-language .language-selector {
  border-bottom: $border-color 1px solid;
  margin-right: 20px;
  padding-bottom: 5px;

  @if ($theme-name == "leukoplast") {
    border-bottom-width: 2px;
  }
}

.header-mobile-country-language .country-selector {
  display: flex;
}

.header-mobile-country-language-title {
  @if ($theme-name == "leukoplast") {
    color: $segment-color-alt !important;
    font-size: 16px;
  } @else if ($theme-name == "cutimed") {
    color: $color-gray;
    font-size: 12px;
  } @else {
    color: inherit !important;
    font-size: 14px;
  }
  font-family: $fontfamily-regular-bold;
  line-height: 28px;
  margin-right: 10px;
  white-space: nowrap;
}

.header-menu-container .select2-dropdown,
.header-mobile-country-language .select2-selection--single {
  border: none;
}

.header-mobile-country-language .country-select-container {
  margin-right: -20px;
}

.header-menu-container .select2-container {
  width: 100%;
}

.header-menu-container .select2-container--default .select2-results>.select2-results__options {
  max-height: 80vh;
}

.header-menu-container .select2-container--default .select2-selection--single {
  border: none;

  @if ($theme-name == "cutimed") {
    background-color: transparent;
  }
}

.header-menu-container .select2-container .select2-selection--single .select2-selection__rendered {
  color: $header-selector-link-color;
  padding-right: 30px;

  @if ($theme-name == "cutimed") {
    font-size: 12px;
  } @else {
    font-size: 14px;
  }

  @if ($theme-name == "leukoplast") {
    max-width: 160px;
  } @else {
    max-width: 180px;
  }
}

.menu-wrapper {
  @if ($theme-name != "com") {
    min-height: 40px;
  }

  border-top: 0;

  @media (min-width: $grid-bp-md ) {
    @if ($theme-name == "leukoplast") {
      border-bottom: 1px solid $segment-color;
      border-top-width: 1px;
    } @else if ($theme-name == "men") {
      border-color: transparent;
    } @else if ($theme-name == "cutimed") {
      border-top: 1px solid $color-gray;
    } @else {
      border-top: 8px solid $segment-color;
    }
  }
}

.menu-wrapper.sticky {
  backface-visibility: hidden;
  background: $page-background-color;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  @if ($theme-name == "cutimed") {
    border-bottom: 1px solid $color-gray;
    border-top: none;
  }
}

.menu-wrapper.sticky .StickyButtons {
  margin-top: 6px;
  visibility: visible;

  &.hide-sticky-buttons {
    visibility: hidden;
  }
}

.menu-wrapper.sticky .StickyButtons .btn {
  @include font-size($font-size-sticky-menu);

  &.btn-hollow {
    color: $accent-color;
    &:hover {
      background-color: $cta-bg-hover;
      color: $color-white;
    }
  }
}

.menu-wrapper .sticky-show {
  display: none;
}

.menu-wrapper .menu {
  @include clearfix();
  font-size: 16px;
  list-style: none;
  margin: 0 5px;
  padding: 0;

  > li {
      float: left;

      .menu-item-main-title {
        @include font-size($font-size-menu-item);

        @if ($theme-name == "leukoplast") {
          line-height: 26px;
          &.multi-line {
            max-width: 220px;
          }
          &.opened {
            color: $logo-color;
          }
        }
        @if ($theme-name == "cutimed") {
          line-height: 25px;
        }

        background-color: transparent;;
        border: none;
        cursor: pointer;
        padding: 0;
        text-align: left;

        &:focus {
          outline: auto;
        }
      }
  }

  @media (min-width: $grid-bp-xs) {
    margin: 0 -10px;
  }

  @media (min-width: $grid-bp-md) {
    margin: 0;

    @if ($theme-name == "cutimed") {
      display: flex;

      > li {
        margin-right: auto;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.menu-wrapper .container {
  @media (max-width: $grid-bp-sm-max) {
    width: inherit;
  }
}

.menu-item {
  padding-bottom: 15px;
  width: 100%;

  &:last-child {
    padding-bottom: 0;
  }

  @media (min-width: $grid-bp-md) {
    padding: 17px 15px;
    width: auto;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-bottom: 17px;
      padding-right: 0;
    }
  }

}

.menu-item .menu-item-main-title {
  @include font-size($font-size-menu-item);
  color: $submenu-link-color;
  display: block;
  position: relative;
  text-decoration: none;
  font-family: $fontfamily-regular-bold;

  @if ($theme-name != "cutimed") {
    font-weight: bold;
  }

  @media (min-width: $grid-bp-md) {
    @if ($theme-name == "men") {
      font-family: $fontfamily-regular;
      color: $color-white;
    }

    &:hover {
      color: $submenu-link-hover;
    }

    @if ($theme-name == "men") {
      &:hover {
        color: $color-white;
      }
    }

    @if ($theme-name == "cutimed") {
      font-family: $fontfamily-regular;
    }
  }
}

.menu-item .submenu ul li {
  @if ($theme-name != "leukoplast") {
    margin-bottom: 20px;
  }

  @media (min-width: $grid-bp-md) {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

@if ($theme-name == "leukoplast") {
  .menu-item .submenu p {
    margin-bottom: 0;
  }
}

.menu-wrapper .menu > .menu-item.has-submenu .menu-item-main-title {
  margin-left: $header-spacing;

  @media (min-width: $grid-bp-md) {
    margin-left: 0;
    padding-left: 0;
  }
}

.menu-wrapper .menu > .menu-item.has-submenu .menu-item-main-title.menu-item-expand {
  float: left;
  margin-left: 0;
  margin-right: 5px;
}

.btn.menu-item-expand-keyboard {
  background-color: transparent;
  border-color: $menu-item-expand-keyboard-color;
  border-radius: 20px;
  border-width: 0;
  clip-path: none;
  display: none;
  float: right;
  height: 22px;
  text-align: center;
  padding: 0;
  position: relative;
  width: 22px;

  @if ($theme-name == "cutimed" or $theme-name == "leukoplast") {
    top: 3px;
  }
}

.btn .menu-item-expand-keyboard-icon {
  color: $menu-item-expand-keyboard-color;
  left: 0;
  right: 0;
}

@media (min-width: $grid-bp-md) {
  .menu-item-columns {
    display: flex;
    flex-direction: column;
    flex-direction: row;
  }

  .menu-item--is-in-focus .menu-item-expand-keyboard {
    display: flex;
  }

  @if ($theme-name == "cutimed") {
    .menu-item--is-in-focus .menu-item-main-icon {
      display: none;
    }
  }
}

.menu-item .submenu .column-content {
  margin-left: 0;
  margin-right: 15px;
}

@if $theme-name == "leukoplast" {
  .header-mobile-country-language .select2-container--default .select2-selection--single {
    right: 16px;
  }

  .header-mobile-country-language .select2-container--default .select2-selection--single b,
  .header-mobile-country-language .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-width: 0 1px 1px 0;
    margin-left: -25px;
    padding: 4px;
  }

  .header-mobile-country-language .select2-container--default .select2-selection--single b {
    margin-top: -7px;
  }

  .header-mobile-country-language .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    margin-top: -2px;
  }
} @else {
  .header-mobile-country-language .select2-container--default .select2-selection--single .select2-selection__arrow {
    right: 16px;
  }
}

.menu-item .submenu-header {
  padding-left: 25px;
}

.menu-item-main-icon {
  @if ($theme-name == "cutimed") {
    display: none;
    vertical-align: middle;

    @media (min-width: $grid-bp-md) {
      display: inline;
    }
  } @else {
    display: none;
  }
}

.header-menu .submenu-header .svg-arrow-right {
  display: none;
}

@if ($theme-name == "actimove") {
  .mobile-nav--is-active .icon.icon-nav-menu::before {
    content: '\2716';
    color: $color-black;
  }

  .menu-wrapper .submenu-content {
    border: none;
  }

  .menu-wrapper .menu {
    margin: 0 10px;
  }

  .menu-wrapper .submenu-content {
    padding: 0;
  }

  .header-mobile-country-language {
    padding: 0 10px 0 20px;
    position: fixed;
    bottom: 0;
    width: inherit;
  }

  .header-menu-container .select2-container.select2-container--default {
    position: fixed !important;
  }

  .header-menu-container .select2-dropdown.select2-dropdown--above {
    width: 200px !important;
  }

  .header-mobile-country-language .country-selector {
    border-bottom: none;
    border-top: 1px solid $border-color;
    padding-bottom: 0;
    padding-top: 10px;
    width: 100%;
  }

  .header-mobile-country-language .country-selector {
    color: $color-light-black;
  }

  .header-mobile-country-language-title,
  .header-mobile-country-language .select2-selection__rendered {
    @include font-size-and-line-height($font-size-paragraph, $line-height-paragraph);

    font-family: $fontfamily-regular;
    color: $color-light-black;
  }

  .header-mobile-country-language-title {
    margin-top: 2px;
    margin-right: 0;
    font-weight: 600;
  }

  .header-menu-container {
    background-color: $color-white;
  }

  .header-menu {
    margin: 5px 0;
  }

  .header-menu-link {
    padding: 20px;
  }

  .header-menu-link .btn {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      display: none;
    }
  }

  .header-menu-link-text {
    margin-bottom: 0;
    margin-left: 10px;
  }

  .header-menu-container .site-search-box {
    padding: 0 20px 0;
  }

  .header-menu-container .site-search-box .input-site-search {
    width: 100%;
    border-radius: 3px;
  }

  .header-menu-container .site-search-box--is-mobile .btn-search--reset {
    top: 8px;
  }

  .header-menu-container .site-search-box--is-mobile .btn-search--reset {
    right: 30px;
  }

  .header-menu-container .site-search-box .btn-search {
    left: 28px;
    top: 6px;
  }

  .header-menu .submenu .menu-item-columns .column {
    padding-left: 0;
  }

  .header-menu .menu-item:last-of-type {
    margin-bottom: 50px;
  }

  .menu-item .menu-item-main-title {
    padding: 18px 15px;
  }

  .menu-item .menu-item-main-title span,
  .menu-wrapper .menu-item .submenu-header {
    @include font-size-and-line-height(22px, 26px);

    font-family: $fontfamily-header;
    font-weight: 600;
  }

  .header-menu .menu .submenu-content {
    padding: 10px 0 0;
  }

  .menu-wrapper .menu-item .submenu-header {
    padding-left: 0;
  }

  .menu-item .menu-item-main-title span {
    color: $color-light-black;
    font-style: italic;
  }

  .header-menu .menu-item.has-submenu .menu-item-expand {
    padding-left: 25px;
  }

  .header-menu .menu-item.has-submenu .menu-item-expand:hover > span {
    color: $color-primary;
  }

  .header-menu .menu-item.has-submenu .menu-item-expand::before,
  .header-menu .menu-item.has-submenu .menu-item-expand::after {
    width: 12px;
    height: 12px;
    border-color: $color-primary;
  }

  .header-menu .menu-item.has-submenu .menu-item-expand::after {
    left: 5px;
    top: 5px;
  }

  .header-menu .submenu-title {
    font-family: $fontfamily-regular;
  }

  .header-menu .submenu-title--uppercase .submenu-title-link {
    @include font-size-and-line-height(16px, 22px);

    color: $color-light-black;
    text-transform: uppercase;
  }

  .header-menu .submenu .submenu-header-title {
    font-family: $fontfamily-header;
    font-style: italic;
  }

  .header-menu .submenu-column-title {
    color: $color-light-black;
    text-transform: uppercase;
  }

  .header-menu .submenu-title-link {
    color: $color-primary;
    font-family: $fontfamily-regular;
  }

  .header-menu .submenu-column-title,
  .header-menu .submenu-title-link {
    @include font-size-and-line-height($font-size-paragraph, $line-height-paragraph);

    font-weight: 400;
    font-family: $fontfamily-regular;
  }

  .header-menu .submenu-title {
    display: flex;
    align-items: center;
  }

  .header-menu .title-rectangle-icon {
    width: 12px;
    height: 16px;
    margin-right: 10px;
    transform: skewX(-20deg);
  }

  .select2-container--default .select2-selection--single {
    background-color: transparent;
  }

  .header-container .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-width: 0;
  }

  .header-container .select2-selection__arrow b::after {
    content: "\e61b";
    display: inline-block;
    font-family: "Icomoon";
    left: -7px;
    position: relative;
    top: -6px;
    width: 15px;
    color: $color-primary;
  }

  .header-container .select2-container--open .select2-selection__arrow b::after {
    content: "\e61d";
  }

  @media (min-width: $grid-bp-md) {
    .header-menu-link,
    .header-menu-container .site-search-box {
      display: none;
    }

    .menu-wrapper {
      border-top: 1px solid $color-aluminium;
    }

    .menu-wrapper .menu {
      margin-left: -15px;
    }

    .menu-item .menu-item-main-title span {
      @include font-size-and-line-height($font-size-paragraph, $line-height-paragraph);

      font-family: $fontfamily-regular;
      font-weight: 400;
      font-style: normal;
    }

    .menu-wrapper .menu .submenu-content {
      box-shadow: 1px 4px 16px 0px rgba(0, 0, 0, 0.2);
      padding: 40px;
      background-color: $color-white;
      margin-left: 15px;
    }

    .header-menu .submenu .menu-item-columns .column {
      display: flex;
      align-items: flex-start;
    }

    .header-menu .submenu .menu-item-columns .column:not(:first-child) .column-content {
      margin-left: 60px;
    }

    .menu-wrapper .menu .menu-item {
      display: flex;
      justify-content: center;
      padding: 0;
      font-family: $fontfamily-regular;
      transition: 0.3s;

      &:hover {
        background-color: $color-primary-lightest;
      }

      &:hover .menu-item-main-title{
        color: $color-primary;
      }
    }

    .header-menu .menu-item:last-of-type {
      margin-bottom: 0;
    }

    .header-menu .submenu-title {
      margin-bottom: 8px;
    }

    .header-menu .submenu-header-title {
      color: $color-light-black;
    }

    .header-menu .submenu-column-title {
      border: none;
      margin-bottom: 8px;
      padding-bottom: 0;
    }

    .header-menu .submenu-title:first-of-type {
      margin-top: 35px;
    }

    .header-menu .submenu-column-title .submenu-title:first-of-type {
      margin-top: 0;
    }

    .header-menu .submenu-header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    .header-menu .submenu-header .svg-arrow-right {
      display: block;
      margin-left: 10px;
    }

    .menu-wrapper .menu>.menu-item.has-submenu .menu-item-main-title {
      padding: 18px 15px;
      margin-right: 0;
    }

    .menu-item .submenu,
    .menu-item .arrow {
      margin-top: 50px;
    }

    .menu-item .submenu {
      padding-top: 25px;
    }

    .menu-item .arrow {
      top: 14px;
      margin-left: 30px;
    }

    .header-menu .menu-item--active {
      box-shadow: inset 0 4px 0 0 $color-primary;
    }
  }
}

@media (max-width: $grid-bp-sm-max) {
  .submenu .menu-item-columns .column {
    border-left: none;
    padding-left: $header-spacing;
  }

  .submenu .menu-item-columns .column ul {
    @if ($theme-name == "leukoplast") {
      font-size: 19px;
      line-height: 23px;
    } @else {
      font-size: 14px;
    }
  }

  .submenu .menu-item-columns .column ul .submenu-title-link {
    padding-left: $header-spacing;
    margin-bottom: 15px;
  }

  .menu-item .submenu-header {
    @if ($theme-name == "leukoplast") {
      font-size: 22px;
      line-height: 26px;
    } @else {
      font-size: 18px;
    }
    padding-left: $header-spacing;
  }

  .menu-item .submenu .column-content {
    @if ($theme-name != "leukoplast") {
      margin-right: 15;
    }
  }

  .menu-item .submenu .container {
    padding: 0;
  }

  .header-menu .submenu-content {
    background-color: transparent;
    border-left: none;
    border-right: none;
    padding: 20px 0 0;
  }

  .header-menu .submenu-title {
    @if ($theme-name == "leukoplast") {
      font-size: 19px;
      line-height: 23px;
      margin-bottom: 20px;
    } @else if ($theme-name == "cutimed") {
      font-size: 14px;
      line-height: 20px;
    } @else {
      font-size: 16px;
    }
  }

  .header-menu .submenu-title-link,
  .submenu-header-title {
    display: inline-block;
    width: 100%;
  }

  @if ($theme-name == "cutimed") {
    .header-menu-container {
      border: 1px solid $color-cuticell-classic;
    }

    .header-mobile-country-language {
      background-color: $color-light-blue-2;
    }
  }
}
