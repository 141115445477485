.header-mobile-items {
  @if ($theme-name == "cutimed") {
    background-color: $color-light-blue-2;
    padding: 30px 20px 20px;
  } @else {
    background-color: $color-white;
    padding: 20px;
  }

  @media (min-width: $grid-bp-md) {
    display: none;
  }
}

.header-mobile-items .title,
.header-mobile-items .subtitle {
  @if ($theme-name== "com") {
    color: $link-color;
  }
  @if ($theme-name == "women" or $theme-name == "cgr" or $theme-name == "men") {
    color: $accent-color;
  }

  display: block;
  @if ($theme-name == "leukoplast") {
    font-size: 16px;
    line-height: 19px;
  } @else if ($theme-name == "cutimed") {
    color: $color-blue;
    font-size: 16px;
    line-height: 25px;
    margin-left: 18px;
  } @else {
    font-size: 14px;
  }
}

@if ($theme-name == "cutimed") {
  .header-mobile-items .subtitle {
    display: none;
  }
}

.header-mobile-items .title {
  @if ($theme-name == "cutimed") {
    font-family: $fontfamily-regular-light;
    font-weight: normal;
  } @else {
    font-family: $fontfamily-regular-bold;
  }
}

.mobile-backdrop {
  pointer-events: none;
  top: 0;
  z-index: 1;
}

.mobile-backdrop,
.header-mobile-backdrop {
  @include transition(opacity $mobile-header-animation-speed);
  @include backgroundBackdrop();

  opacity: 0;
}

.header-mobile-backdrop {
  height: 35px;
  z-index: -1;

  @if ($theme-name == "leukoplast" or $theme-name == "cutimed") {
    display: none;
  }
}

.mobile-nav--is-active .header-mobile-backdrop,
.mobile-nav--is-active .mobile-backdrop {
  opacity: 1;
  pointer-events: initial;
}

.mobile-menu-trigger {
  @include transition(left $mobile-header-animation-speed);

  background-color: transparent;
  border: none;
  color: $mobile-header-trigger-color;
  cursor: pointer;
  display: block;
  font-size: 21px;
  height: $mobile-header-height;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: $mobile-menu-trigger-width;
  z-index: 800;

  @if ($theme-name == 'men') {
    color: $color-white;
  }

  @media (min-width: $grid-bp-md) {
    display: none;
  }
}

.mobile-menu-trigger .icon {
  display: block;
  line-height: $mobile-header-height;
  text-align: center;
  width: $mobile-menu-trigger-width;
}

.mobile-nav--is-active .mobile-menu-trigger {
  left: calc(100vw - 60px);

  @media (min-width: 380px) {
    left: #{$mobile-panel-width};
  }
}

.mobile-nav--is-active .header-container {
  left: 0;
}

.header-mobile-footer {
  padding: 20px 20px 100px;

  ul {
    @extend .reset-list;
    li {
      color: $color-white;
      line-height: 1.4em;
      margin-bottom: 15px;
      &.has-submenu {
        &:before {
          content: none;
        }
      }
    }
    a {
      color: $color-white;

      @if ($theme-name == "cutimed") {
        font-family: $fontfamily-regular-bold;
        font-weight: bold;
        line-height: 25px;
      } @else {
        font-family: $fontfamily-regular;
      }

      @if ($theme-name == "leukoplast") {
        font-size: 22px;
        line-height: 26px;
      } @else {
        font-size: 18px;
      }

      &:hover {
        color: $submenu-link-hover;
      }
    }
    .submenu-trigger {
      cursor: pointer;
      padding-left: 26px;
      i {
        font-size: 0.9em;
        margin-left: 10px;
        top: 2px;
      }
    }
    .submenu {
      margin-top: 20px;
      li:first-child {
        margin-top: 20px;
      }
    }
  }
}

.header-mobile-footer ul li ul {
  margin-left: 20px;
  margin-top: 10px;

  a {
    font-size: 16px;
  }
}

.header-container {
  background: $page-background-color;
}

.header-container .header {
  background: $page-background-color;
}

@if ($theme-name == "cutimed") {
  .header-mobile-divider {
    border: none;
    border-top: 1px solid $color-cuticell-classic;
    left: 20px;
    margin: 0;
    position: absolute;
    right: 20px;

    @media (min-width: $grid-bp-md) {
      display: none;
    }
  }
} @else {
  .header-mobile-divider {
    display: none;
  }
}

@media (min-width: $grid-bp-md) {
  .header {
    border-bottom: 0;
    padding-bottom: 0;
    position: relative;
  }

  .header-top {
    @include container-size();

    border-bottom: 0;
    margin: 0 auto;
    min-height: $header-height;
    padding: 0 30px;
    position: relative;
  }

  .header-container {
    background-color: transparent;
    height: initial;
    position: initial;
    width: 100%;
  }

  .header-mobile-footer {
    display: none;
  }

  .mobile-backdrop {
    display: none;
  }
}