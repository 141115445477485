@font-face {
  font-display: swap;
  font-family: Asap;
  font-style: normal;
  font-weight: normal;
  src:
    url("../fonts/Asap/asap.woff2") format("woff2"),
    url("../fonts/Asap/asap.woff") format("woff"),
    url("../fonts/Asap/asap.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Asap Bold";
  font-style: normal;
  src:
    url("../fonts/Asap/asap-bold.woff2") format("woff2"),
    url("../fonts/Asap/asap-bold.woff") format("woff"),
    url("../fonts/Asap/asap-bold.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: Icomoon;
  font-style: normal;
  font-weight: normal;
  src:
    url("../fonts/Icomoon/Icomoon.woff2") format("woff2"),
    url("../fonts/Icomoon/Icomoon.woff") format("woff");
}

/* Arabic Font */
@font-face {
  font-display: swap;
  font-family: Amiri;
  font-style: normal;
  font-weight: normal;
  src:
    url("../fonts/Amiri/amiri.woff2") format("woff2"),
    url("../fonts/Amiri/amiri.woff") format("woff");
}

/* Leukoplast */
@font-face {
  font-display: swap;
  font-family: "Exo2";
  font-style: normal;
  font-weight: normal;
  src:
    url("../fonts/Exo2/Exo2-Regular.woff") format("woff"),
    url("../fonts/Exo2/Exo2-Regular.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Exo2SemiBold";
  font-style: normal;
  font-weight: bold;
  src:
    url("../fonts/Exo2/Exo2-SemiBold.woff") format("woff"),
    url("../fonts/Exo2/Exo2-SemiBold.ttf") format("truetype");
}

// x-men headings font
@font-face {
  font-display: swap;
  font-family: "Gotham Black";
  font-style: normal;
  font-weight: 800;
  src:
    url("../fonts/Gotham/woff2/GothamSSm-Black_Web.woff2") format("woff2"),
    url("../fonts/Gotham/woff/GothamSSm-Black_Web.woff") format("woff");
}

// Cutimed
@font-face {
  font-display: swap;
  font-family: HelveticaNowLight;
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/HelveticaNow/HelveticaNowDisplay-Lt.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: HelveticaNowBold;
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/HelveticaNow/HelveticaNowText-Bd.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: HelveticaNowExtraBoldCondensed;
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/HelveticaNow/HelveticaNowText-CdExBd.otf") format("opentype");
}

// Actimove
@font-face {
  font-display: swap;
  font-family: RobotoRegular;
  src: url("../fonts/Roboto/woff2/Roboto.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: 'LucidaGrandeBoldItalic';
  font-style: italic;
  font-weight: bold;
  src:
    url("../fonts/LucidaGrande/woff2/LucidaGrande-BoldItalic.woff2") format("woff2"),
    url("../fonts/LucidaGrande/woff/LucidaGrande-BoldItalic.woff") format("woff");
}
