/* CSS for buy now */
.popUp-header {
  .popProduct-title {
    color: $accent-color;
    font-family: $fontfamily-regular;
    font-size: 36px;
    line-height: 42px;
    padding-bottom: 10px;
  }

  .selectProduct-size {
    position: relative;
  }

  .selectProduct-sizeWrap {
    clear: left;
    margin-bottom: 5px;

    @media (max-width: $grid-bp-xs-max) {
      margin-top: 30px;
      text-align: center;
    }
  }

  .selectProduct-size {
    color: $accent-color;
    font-family: $fontfamily-regular-bold;
    font-size: 18px;
    margin-bottom: 5px;
    margin-top: 0;
  }

  .popUp-row {
    display: flex;
    align-items: center;
  }

  .headProduct-img {
    text-align: center;
    .productImg {
      @include img-fluid();
    }
  }

  @media (max-width: $grid-bp-xs-max) {
    .popUp-row  {
      flex-direction: column;
    }
    .headProduct-img {
      margin: $vspace-sm;
    }
    .popProduct-title {
      font-size: 18px;
      font-weight: bold;
      line-height: 21px;
      text-align: center;
    }
  }
}

.messageInfo-wrap {
  border-top: 1px solid #ccc;
  padding-top: 15px;
  text-align: center;

  .messageInfo {
    color: #aaa;
    font-size: 18px;
    font-weight: bold;
  }
}

.productDetails-wrap {
  padding-top: 10px;

  .u-rowTitle {
    font-weight: bold;
  }
}

.productInfo-Details {
  align-items: center;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;

  &.hideBorder {
    border-width: 0;

    @media (max-width: $grid-bp-xs-max) {
      border-width: 1px;
    }
  }

  .productVendor-img {
    max-height: 60px;
    max-width: 200px;
    @include mediaquery(xxs) {
      max-width: 100%;
    }
  }

  .productCount-wrap .productCount {
    color: $accent-color;
    display: block;
    font-size: 18px;
  }
}

.productCount-wrap {
  word-break: break-all;
}

.productVendor-wrap,
.productCount-wrap {
  padding-bottom: 5px;
  padding-top: 5px;
}

.disclaimerInfo {
  padding-top: 1em;
}

.u-btnBuy {
  &.btn {
    @if ($theme-name != "men") {
      padding: 7px;
    }
    text-align: center;
    width: 100%;
  }

  &.status-outofStock {
    background-color: #aaa;
    border-color: #aaa;
    cursor: default;

    @if ($theme-name == "men") {
      color: $inactive-color;

      &::after {
        display: none;
      }
    } @else {
      color: #fff;
    }

    &:hover {
      background-color: #aaa;
    }
  }
}

.buy-now-wrapper .hidden {
  display: none;
}
