.sample.btn.btn-inactive,
.sample.field-hidden-input input:not(:checked) + label.btn {
    @if ($theme-name == "cgr" or $theme-name == "com") {
        background: $accent-color;
        border-color: $accent-color;
    } @else if($theme-name == "women") {
        background: $color-mid-light-blue;
        border-color: $color-mid-light-blue;
    } @else {
        background: #00abbd;
        border-color: #00abbd;
    }

    @if ($theme-name == "cgr") {
        color: #fff;
    } @else if($theme-name == "women") {
        color: #003b73;
    } @else if($theme-name == "com") {
        color: #003357;
    }

    @if ($theme-name == "men") {
      background-color: $color-purple;
      color: $color-purple;
    }

    cursor: not-allowed;
}

.sample.btn.btn-inactive:hover,
.sample.field-hidden-input input:not(:checked) + label.btn:hover {
    @if ($theme-name == "cgr") {
        background: $accent-color;
        border-color: $accent-color;
        color: #fff;
    } @else if($theme-name == "women") {
        background: #a9d0e6;
        border-color: #a9d0e6;
        color: #fff;
    } @else if($theme-name == "men") {
        background: #00abbd;
        border-color: #00abbd;
        color: #fff;
    }
}

@if ($theme-name == "com") {
  .field-hidden-input input:not(:checked) + label.sample.btn:hover,
  .sample.btn.btn-inactive:hover,
  .sample.field-hidden-input input:not(:checked) + label.btn:hover {
    background: #003b73;
    border-color: #003b73;
    color: #fff;
  }
}