.campaign-media-container {
  overflow: hidden;
  position: relative;
  width: auto;
}

.campaign-media-container--landing {
  margin: 0 auto;
}

@if ($theme-name == "leukoplast") {
  // This is a padding-bottom hack to maintain aspect ratio
  .campaign-media-container--no-image {
    padding-bottom: 50%;
  }

  @media (max-width: $grid-bp-xs) {
    .campaign-media-container--no-image .overlay-container {
      position: static;
      transform: unset;
    }

    .campaign-media-container .overlay-content .btn {
      text-align: center;
    }
  }
}

@include mediaquery(sm) {
  .campaign-media-container video {
    object-fit: cover !important;
  }

  // instead of aspect-ratio 3:2
  .campaign-media-container .jw-aspect {
    padding-top: 66.67% !important;
  }
}

@include mediaquery(xs) {
  .campaign-media-container .jw-aspect {
    padding-top: 100% !important;
  }
}

.campaign-media-container.image-container-fluid {
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;

  img {
    width: 100%;
    height: inherit;
  }

  @if ($theme-name != "leukoplast") {
    @media (min-width: $grid-bp-md) {
      min-height: 300px;
    }
  }
}

.campaign-media-container img {
  width: 100%;

  @if ($theme-name != "leukoplast") {
    @media (max-width: $grid-bp-sm-max) {
      aspect-ratio: 3 / 2;
      object-fit: cover;
    }

    @media (max-width: $grid-bp-xs-max) {
      aspect-ratio: 1 / 1;
    }
  }
}

.overlay-container {
  @include svg-play-icon();

  color: $home-color;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  @if ($theme-name == "men") {
    text-align: left;
    width: 100%;

    .overlay-container h1 {
      font-family: $fontfamily-titles-thin;
    }

    .overlay-content {
      max-width: 720px;
    }

    .overlay-cta {
      display: flex;
      margin: 24px 0;
    }

    .overlay-cta .btn {
      @include btn("tertiary");
      margin-right: 15px;
    }
  }

  @if ($theme-name != "men") {
    .campaign-header {
      width: auto;
    }
  }

  @if ($theme-name != "leukoplast" and $theme-name != "men") {
    width: 335px;

    @media (min-width: $grid-bp-sm) {
      width: 465px;
    }

    @media (min-width: $grid-bp-md) {
      width: 615px;
    }

    @media (min-width: $grid-bp-lg) {
      width: 772px;
    }
  }
}

@if ($theme-name != "leukoplast" and $theme-name != "cutimed" and $theme-name != "men") {
  .overlay-container .svg-icon.play-icon {
    left: 0;
    margin-top: 24px;
    position: relative;
    top: 0;
    transform: unset;
  }
}


.overlay-container p {
  color: $home-color;
}

.overlay-container .lightenText,
.overlay-container .lightenText p {
  color: $color-white;
}

.overlay-container h4 {
  @include font-size($font-size-caption-image-subheader);
  font-family: $fontfamily-regular;
}

.overlay-container h1 {
  font-family: $campaign-media-title-font;
  @include font-size($font-size-caption-image-header);
  font-weight: normal;
  margin-top: 0;

  @media (max-width: $grid-bp-sm) {
    font-size: 28px;
    line-height: 32px;
  }
}

@if ($theme-name == "men") {
  .overlay-container h4 {
    margin-bottom: 16px;
  }

  .overlay-container h1 {
    margin-bottom: 25px;
  }

  .overlay-container h4,
  .overlay-container h1 {
    text-transform: uppercase;
  }
}

.overlay-container .campaign-media-description {
  @include font-size($font-size-caption-image-subheader);
}

@if ($theme-name == "actimove") {
  .campaign-media-container-btn {
    @include btn("primary-light");

    width: 100%;
    text-align: center;

    &::after {
      right: auto;
      margin-left: 10px;
    }
  }

  .campaign-media-container {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 3px);
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.54) 100%);
    }
  }

  .campaign-media-container.campaign-media-container--video::before {
    content: none;
  }

  .campaign-media-container--second-btn {
    margin-top: 10px;
  }

  .campaign-media-container .svg-icon.play-icon {
    fill: transparent;
    width: 60px;
    height: 60px;
  }

  .campaign-media-container .play-icon-arrow {
    fill: $color-white;
  }

  .overlay-container .campaign-media-subheading {
    @include font-size-and-line-height($campaign-subtitle-font-size, $campaign-subtitle-line-height);

    font-family: $fontfamily-regular;
    text-transform: uppercase;
    letter-spacing: 0.4px;
  }

  .overlay-container .campaign-media-heading {
    @include font-size-and-line-height($campaign-title-font-size, $campaign-title-line-height);

    font-family: $fontfamily-header;
    margin-bottom: 16px;
    font-style: italic;
  }

  .overlay-container .campaign-media-description {
    @include font-size-and-line-height($campaign-description-font-size, $campaign-description-line-height);

    font-family: $fontfamily-regular;
    margin-bottom: 16px;
  }

  .campaign-media-container .overlay-container {
    bottom: 0;
    top: unset;
    transform: translate(-50%, -5%);
    width: 90%;
    padding-bottom: 24px;
  }

  .campaign-media-container .overlay-container.overlay-container--video {
    background: none;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    padding: 0;
  }

  @media (min-width: $grid-bp-xs) {
    .campaign-media-container .overlay-container {
      width: 60%;
    }

    .campaign-media-container .overlay-container {
      padding-bottom: 40px;
    }
  }

  @media (min-width: $grid-bp-md) {
    .campaign-media-container {
      background: none;

      &::before {
        content: none;
      }
    }

    .campaign-media-container.image-container-fluid {
      padding-bottom: 25px;
    }

    .campaign-media-container .overlay-container {
      text-align: left;
      padding: 60px 50px 75px 40px;
      background: $campaign-content-background-color;
      position: absolute;
      bottom: 0;
      left: 0;
      top: unset;
      transform: none;
      // Calculates an angle of 10 degrees on the right side
      clip-path: polygon(0 0, 100% 0, calc(100% + -0.18*100vmax) 100vmax, 0 100vmax);
      width: 720px;
    }

    .overlay-container.overlay-container--video .svg-icon.play-icon {
      margin-top: 0;
    }

    .campaign-media-container-btn {
      width: fit-content;
    }
  }
}
