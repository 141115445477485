.header-logo {
  display: inline-block;
  margin-top: 0;
  width: $mobile-header-logo-width;

  @if ($theme-name == "leukoplast") {
    left: 70px;
    position: relative;
  }

  @if ($theme-name == "cutimed") {
    align-items: center;
    display: inline-flex;
    margin: 0 auto;
    min-height: 60px;
  }

  @media (min-width: $grid-bp-md) {
    position: absolute;
    width: $header-logo-width;

    @if ($theme-name == "leukoplast") {
      left: 30px;
      top: 20px;
    } @else if ($theme-name == "cutimed") {
      display: inline-block;
      left: 30px;
      margin: 0;
      top: 45px;
    } @else {
      left: -115px;
      min-height: 96px;
    }
  }
}

.header-logo img {
  height: inherit;
  width: inherit;

  @if ($theme-name == "leukoplast" or $theme-name == "cutimed") {
    vertical-align: bottom;
  }
}

@if ($theme-name == "actimove") {
  .header-logo {
    left: 0;
    margin-top: 16px;
    display: flex;
    align-items: center;
    width: 125px;

    @media (min-width: $grid-bp-md) {
      left: 30px;
      width: 180px;
      margin-top: 5px;
    }
  }
}

@media (max-width: $grid-bp-sm-max) {
  @if ($theme-name == 'men') {
    .header-logo img {
      width: 220px;
      padding-bottom: 5px;
      margin-left: 40px;
    }
  }
}

@media (min-width: $grid-bp-md) and (max-width: $grid-bp-lg) {
  @if ($theme-name == 'men') {
    .header-logo {
      width: 400px;
      left: -30px;
    }
    .header-logo img {
      padding-top: 10px;
    }
  }
}

@media (min-width: $grid-bp-lg) {
  @if ($theme-name == 'men') {
    .header-logo {
      left: -40px;
    }
  }
}


