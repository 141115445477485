.horizontal-list {
  @include clearfix();
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    float: left;
  }
}

%reset-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.reset-list {
  @extend %reset-list;
}

.link-list {
  @extend %reset-list;
  font-family: $fontfamily-regular-bold;

  @if $theme-name == "leukoplast" {
    font-size: $font-size-paragraph;
  } @else {
    font-size: 18px;
  }

  li {
    border-bottom: 1px solid $border-color;
    padding: 15px 0;

    @if $theme-name == "leukoplast" {
      &:first-of-type {
        border-top: 1px solid $border-color;
      }
    }
  }
}

@if $theme-name == "leukoplast" {
  .link-list .link {
    color: $logo-color;
    text-decoration: none;
  }
}

.link-list img {
  height: 100%;
  margin-right: 15px;
  max-height: 80px;
  max-width: 140px;
  object-fit: contain;

  @media (min-width: $grid-bp-sm) {
    margin-right: 30px;
  }
}

.link-list--has-images {
	display: grid;
	grid-auto-rows: 1fr;
}

.link-list--has-images .link-list-item-content:only-child {
	margin-left: 170px;
}
.link-list .link-list-item-content {
  @include font-setup();
}
