/*
 * Overrides of default Power Review styles
 */

.p-w-r {
    font-family: $fontfamily-regular !important;
}

.pr-star-v4-0-filled {
    background-image: svg-uri($pr-star-0) !important;
}

.pr-star-v4-25-filled {
    background-image: svg-uri($pr-star-25) !important;
}

.pr-star-v4-50-filled {
    background-image: svg-uri($pr-star-50) !important;
}

.pr-star-v4-75-filled {
    background-image: svg-uri($pr-star-75) !important;
}

.pr-star-v4-100-filled {
    background-image: svg-uri($pr-star-100) !important;
}

// Change look of ratings inside a product component
.pr-snippet-stars {
    background: transparent !important;
}

.pr-category-snippet__total {
  padding-left: 0 !important;
  color: $accent-color !important;
  font-family: inherit !important;
  font-weight: bold !important;
  font-size: 12px !important;

  &:before {
    content: "|";
    margin-right: 5px;
    color: $pr-inactive-color;
    margin-top: -1px;
    display: inline-block;
  }
}

.pr-snippet-rating-decimal {
    border: none !important;
    color: $accent-color !important;
    font-weight: bold !important;
    font-size: 12px !important;
}

.product-list .js-review .p-w-r {
  min-height: 20px;
}

.product-list .pr-snippet-rating-decimal,
.product-list .pr-category-snippet__total {
  font-weight: normal !important;
  font-size: 16px !important;
}

@if ($theme-name != "cutimed") {
  .product-list .js-review {
    min-height: 25px;
  }

  .product-box-container-image-left .js-review {
    min-height: 25px;

    @media (min-width: $grid-bp-md)  {
      min-height: 45px;
    }
  }
}

.product-box-container-image-left .js-review .p-w-r {
  min-height: 20px;
  @media (min-width: $grid-bp-md)  {
    min-height: 40px;
  }
}

.p-w-r .pr-category-snippet {
  @media (max-width: $grid-bp-xs-max)  {
    margin-left: 0px !important;
  }
}

.component-product-box .p-w-r .pr-category-snippet {
  display: flex !important;
  flex-direction: column;
  align-items: left;

  @media (min-width: $grid-bp-sm) {
    align-items: center;
  }
}

.ProductContent .js-review {
  min-height: 28px;
}

.js-review,
.js-review-selection-hook {
    .p-w-r {
        margin: 0 0 5px;
    }
}

// Puch text away from the edge
.pr-review-display,
.pr-review-snapshot {
    padding: 0 10px !important;
}

// Break stars and decimal in two separate lines in the Product Box Component
@media (min-width: $container-max-width) {
    .product-box-container-image-left .p-w-r {
        .pr-category-snippet,
        .pr-category-snippet__item {
            display: block !important;
        }
        .pr-category-snippet__total {
            margin-top: 3px;

            &:before {
                display: none;
            }
        }
    }
}

// Center Write a review form
.p-w-r .pr-war {
    margin: 0 auto !important;
}

// Ratings bar in  product intro
.product-intro-item .p-w-r {
    margin: 0 !important;

    .pr-snippet-write-review-link {
        color: $product-intro-font-color !important;
        vertical-align: middle;
        text-decoration: underline;
    }

    .pr-snippet-review-count {
        color: $product-intro-font-color !important;
        vertical-align: middle;
    }

    a.pr-snippet-review-count {
        text-decoration: underline;
    }

    .pr-snippet-rating-decimal {
        color: $product-intro-font-color !important;
        padding: 0 4px !important;
    }

    .pr-snippet-read-and-write {
        margin-top: 0 !important;
        margin-bottom: 10px !important;
    }

    .pr-snippet-stars-reco-reco {
        display: none;
    }

    .pr-snippet .pr-snippet-stars-png .pr-rating-stars {
        margin-top: -4px;
    }

    .pr-snippet-stars-container {
        margin-bottom: 10px !important;
    }
}

// Review snapshot

.pr-rd-no-reviews .pr-snippet-write-review-link {
    color: $accent-color !important;
}

.pr-review-snapshot {
    background-color: transparent !important;

    .pr-faceoff-img-txt,
    .pr-faceoff-label,
    .pr-faceoff-title,
    .pr-faceoff-review {
        color: $accent-color !important;
    }

    .pr-snippet-rating-decimal {
        color: $pr-inactive-color !important;
    }

    .pr-review-snapshot-block,
    .pr-review-snapshot-faceoff,
    .pr-faceoff-img-txt {
        background-color: $pr-block-light-color !important;
    }

    .pr-review-snapshot-block-container {
        margin: 15px 0 !important;
    }

    .pr-faceoff-img-txt,
    .pr-faceoff-img {
        border-color: $accent-color !important;
        border-width: 2px !important;
    }

    .pr-faceoff-img-txt {
        font-weight: 700 !important;
    }

    .pr-review-snapshot-faceoff {
        border: none !important;
        border-radius: 0 !important;
    }

    .pr-histogram-stars:hover {
        background: none !important;
    }

    .pr-review-snapshot-header-intro {
        padding: 0 !important;
        margin: 0 !important;
        border-bottom: none !important;
    }

    .pr-snippet-reco-to-friend-percent {
        background: none !important;
    }

    .pr-snippet-reco-to-friend-percent .pr-checkbox-icon {
        display: none !important;
    }

    .pr-reco-to-friend-message {
        background: none !important;
        color: #fff !important;
    }

    .pr-review-snapshot-block-histogram {
        margin-bottom: 0 !important;
        padding: 15px 0 !important;
    }

    .pr-ratings-histogram-bar {
        background-color: $pr-idle-color !important;
    }

    .pr-ratings-histogram-barValue {
        background-color: $pr-star-color !important;
    }

    .pr-reco-value {
        font-size: 3.5rem !important;
        line-height: 1 !important;
    }

    .pr-reco-to-friend-message {
        font-size: 12px !important;
    }

    .pr-review-snapshot-block {
        border-right-color: transparent !important;
    }

    .pr-review-snapshot-block-recommend {
        background-color: $accent-color !important;
        padding: 0;
        margin: 0 15px !important;

        @include mediaquery(sm) {
            margin: 0 0 15px !important;
            padding: 30px 15px !important;
        }
    }

    .pr-review-snapshot-block-snippet {
        @include mediaquery(sm) {
            padding: 15px !important;
        }
    }

    .pr-snippet-write-review-link {
        background: none !important;
        border: 2px solid $color-gray !important;
    }
}

// Review display

.pr-review-display {
    background-color: transparent !important;

    .pr-rd-no-reviews {
        display: none;
    }

    .pr-rd-review-product {
        color: $accent-color !important;
    }

    .pr-rd-main-header {
        padding: 15px 0 0 !important;
        background: none !important;
    }

    .pr-rd-sort {
        @include mediaquery(sm) {
            margin-left: 0 !important;
        }
    }

    .pr-review {
        padding: 15px !important;
    }

    .pr-review:nth-child(odd) {
        background-color: $pr-block-light-color !important;
    }

    .pr-review:nth-child(even) {
        background-color: $pr-block-dark-color !important;
    }

    .pr-rd-review-headline {
        margin: 1px 15px !important;

        @include mediaquery(sm) {
            margin: 0 !important;
        }
    }

    .pr-rd-bottomline {
        display: none !important;
    }

    .pr-helpful-btn {
        border: 1px solid $color-gray !important;
    }

    .pr-helpful-yes {
        border-right: none !important;
    }

    .pr-rd-footer {
        background: none !important;
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
    }
}

// Upvote / downvote review
.p-w-r .pr-helpful-btn:active {
    background: $accent-color !important;
}

.p-w-r .pr-helpful-btn:active span {
    color: #fff !important;
}

.p-w-r .pr-helpful-btn:hover {
    background: $color-gray !important;
}

.p-w-r .pr-helpful-active {
    background: $color-gray !important;
}

.p-w-r .pr-thumbs-icon {
    color: $pr-inactive-color !important;
}

.p-w-r .pr-helpful-active span,
.p-w-r .pr-helpful-active[disabled] span {
    color: #fff !important;
}

.pr-snippet-rating-decimal {
    .pr-review-display &,
    .pr-review-snapshot & {
        background: transparent !important;
        border: none !important;
        height: auto !important;
        font-weight: 700 !important;
        line-height: 20px !important;
    }
}

.pr-snippet-stars {
    .pr-review-display &,
    .pr-review-snapshot & {
        background-color: transparent !important;
    }
}

// Modal / Write a review

.js-review .p-w-r .pr-media-modal,
.js-review[data-reviewtype="ReviewForm"] .p-w-r {
    .overlay {
        background: transparent;
    }

    .modal__body {
        border-radius: 0 !important;
        border: 1px solid $menu-submenu-border-color !important;
        width: 720px !important;
        box-shadow: none !important;

        padding: 0 30px !important;

        @include mediaquery(sm) {
            padding: 0 15px !important;
        }
    }

    .button__close {
        box-shadow: none;
        @media (max-width: 630px) {
          top: 70px;
        }
    }

    .pr-cross-icon {
        font-family: "Icomoon";
        speak: none;
        font-style: normal;
        font-weight: normal;
        -webkit-font-feature-settings: normal;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        font-size: 28px;
        color: $color-gray !important;

        &:before {
            content: "\e707";
        }
    }

    .pr-cross-icon svg {
        display: none;
    }

    .pr-header {
        margin: 0 0 20px;
    }

    .pr-header-product-name {
        color: $accent-color !important;
    }

    .pr-header-required {
        color: $accent-color !important;
    }

    .pr-header-required span {
        color: $accent-color !important;
    }

    .pr-accessible-btn {
        @extend .btn;
        margin: 0 0 30px;
        padding-right: 18px !important;

        @include mediaquery(sm) {
            text-align: center !important;
        }
    }

    .pr-accessible-btn + a {
        width: 100%;
        clear: both;
        display: block;
        overflow: hidden;
        box-sizing: content-box;

        padding: 15px 30px;
        margin: 0 -30px;

        @include mediaquery(sm) {
            padding: 15px 15px;
            margin: 0 -15px;
        }
    }

    .pr-subscript {
        color: $pr-inactive-color !important;
        margin: 1em 0;
    }

    .pr-subscript a {
        color: $pr-inactive-color !important;
        text-decoration: underline;
    }

    .control-label span,
    .pr-control-label span {
        color: $accent-color !important;
    }

    .pr-header-title {
        color: $accent-color !important;
    }

    .pr-helper-text {
        color: $pr-inactive-color !important;
    }

    .pr-btn:hover {
        background-color: $pr-block-light-color;
        border-color: $pr-block-dark-color;
    }

    .pr-btn.active {
        background-color: $accent-color !important;
        border-color: transparent;
    }

    .pr-clear-all-radios {
        color: $accent-color !important;
    }
}

.js-review[data-reviewtype="ReviewForm"] {
    max-width: 700px;
    margin: 0 auto;
}

.js-review[data-reviewtype="ReviewForm"] .p-w-r .pr-accessible-btn + a {
    background: transparent;
}

.js-review .p-w-r .pr-media-modal .pr-accessible-btn + a {
    background: $pr-block-light-color;
}

// Hide verified buyer

.pr-verified_buyer {
    display: none !important;
}

// Hide reviewed at

.pr-rd-reviewed-at {
    display: none !important;
}

// Modal shown when review is submitted

.thank-you-page {
    padding: 10px 0 20px !important;
}

.thank-you-page .pr-rating-stars {
    margin-bottom: 5px !important;
}

.thank-you-page .headline {
    margin-top: 15px !important;
}

.thank-you-page .pr-text {
    margin: 5px 0 !important;
}

.thank-you-page .header {
    @include mediaquery(sm) {
        display: block;
    }

    a {
        color: $accent-color !important;
    }

    h5 {
        display: none;
    }
}

// Product finder sample box

.product-finder-results .component-sample-box .p-w-r .pr-category-snippet {
    margin: 0;

    @media (min-width: 1200px) {
        display: block;
    }

    @include mediaquery(xs) {
        display: block;
    }
}

.product-finder-results .component-sample-box .p-w-r .pr-category-snippet__item {
    @media (min-width: 1200px) {
        display: block;
    }

    @include mediaquery(xs) {
        display: block;
    }
}

.product-finder-results .component .pr-category-snippet__total:before {
    @media (min-width: 1200px) {
        display: none;
    }

    @include mediaquery(xs) {
        display: none;
    }
}

.product-finder-results .component-sample-box .p-w-r .pr-category-snippet__total {
    @media (min-width: 1200px) {
        margin: 3px 0 10px;
    }

    @include mediaquery(xs) {
        margin: 3px 0 10px;
    }
}

.pr-snippet-write-review-link {
    color: #303533 !important;
}

// Add container for review snapshot in product details.
[data-pagename="productdetail"] ~ .js-review {
    @extend .container;

    // Remove margin for reviews in product details.
    > .p-w-r {
        margin: 0;
    }
}

@if ($is-tena-theme) {
  .product-intro-container-frost .p-w-r .pr-category-snippet {
    margin-left: 0 !important;
  }

  .accordion-item .product-intro-item .p-w-r,
  .accordion-item .MdrDetails-container .container {
    width: 100% !important;
  }

  .accordion-item .js-review {
    width: 100%;
  }

  .accordion-item .p-w-r .pr-snippet .pr-snippet-stars-png .pr-snippet-rating-decimal {
    display: none !important;
  }

  .accordion-item .p-w-r .pr-category-snippet {
    display: flex !important;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .accordion-item .p-w-r .pr-category-snippet__total {
    color: $home-color !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    margin-left: 5px;

    @media (min-width: $grid-bp-md) {
      font-size: 22px !important;
    }
  }

  .accordion-item .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets {
    padding: 13px 15px;
  }

  .accordion-item .p-w-r .pr-review-snapshot.pr-snapshot-mobile .pr-review-snapshot-simple .pr-review-snapshot-block-recommend {
    padding: 13px 6px 24px !important;

    @media (min-width: $grid-bp-md) {
      margin: unset !important;
      margin-bottom: 15px !important;
    }
  }

  .accordion-item .p-w-r .pr-review-display .pr-rd-review-headline {
    margin-left: 0 !important;
  }

  .product-box .p-w-r .pr-snippet .pr-snippet-stars-png .pr-snippet-rating-decimal {
    display: none;
  }

  .pr-category-snippet__total:before {
    content: "(" !important;
    margin-right: 0;
    color: unset;
  }

  .pr-category-snippet__total:after {
    content: ")";
  }
}

@if ($theme-name == "actimove") {
  .product-box .p-w-r .pr-snippet .pr-snippet-stars-png .pr-snippet-rating-decimal {
    display: none;
  }

  .product-box [data-bv-show="rating_summary"] .bv_main_container .bv_numReviews_text,
  .product-intro-item [data-bv-show="rating_summary"] .bv_main_container .bv_numReviews_text {
    text-decoration: none !important;
  }

  .pr-category-snippet__total {
    color: $color-primary !important;
  }

  .pr-category-snippet__total:before {
    content: "(" !important;
    margin-right: 0;
    color: unset;
  }

  .pr-category-snippet__total:after {
    content: ")";
  }

  @media (max-width: $grid-bp-xs-max) {
    .product-box .p-w-r .pr-category-snippet {
      margin: unset;
    }

    .component-product-box .p-w-r .pr-category-snippet {
      align-items: flex-start;
    }
  }
}