@if ($is-tena-theme) {
  .content-column {
    position: unset;
    top: 90px;
    right: 0;
    left: 0;

    @media (min-width: $grid-bp-md) {
      position: sticky;
      margin-top: 15px;
      margin-bottom: 50px;
    }
  }

  .product-content-frost .product-intro-content-header {
    @media (max-width: $grid-bp-sm-max) {
      display: none;
    }
  }

  .product-badge {
    @include font-size-and-line-height ($product-intro-badge-font-size, $product-intro-badge-line-height);

    font-family: $fontfamily-regular-bold;
    color: $color-white;
    background-color: $product-intro-badge-bg;
    width: fit-content;
    padding: 5px;
    border-radius: 15px;
  }

  .product-intro-container-frost .product-subtitle {
    @include font-size-and-line-height(16px, 22px);

    font-family: $product-intro-subtitle;
  }

  @if ($theme-name != "actimove") {
    .product-intro-container-frost .product-title {
      @include font-size-and-line-height(22px, 28px);

      margin-top: 10px;
      font-family: $product-intro-title-font;
      font-weight: 400;
      color: $product-intro-title-color;
    }
  }

  .product-intro-container-frost .bazaarVoice-left-aligned {
    margin-bottom: 20px;
  }

  .product-description-short {
    line-height: 25px;
    margin-bottom: 40px;
  }

  .product-absorbency h3 {
    @include font-size-and-line-height ($product-intro-label-font-size, $product-intro-label-line-height);

    margin-bottom: 6px;
  }

  .product-content-frost .product-options {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .product-label-text {
    @include font-size-and-line-height ($product-intro-label-font-size, $product-intro-label-line-height);

    font-family: $product-intro-label;
    margin-bottom: 6px;
  }

  .product-intro-sizes {
    width: 50%;
  }

  .product-sizes-value {
    line-height: 17px;
  }

  .product-content-frost .product-color-circle {
    width: 50%;
    margin: 0 0 16px;
  }

  .product-content-frost .product-color-outer {
    width: 20px;
    height: 20px;
    margin-left: 2px;
  }

  .product-content-frost .product-color-inner {
    width: 16px;
    height: 16px;
    margin-top: 8px;
  }

  .product-content-frost .product-size-guide {
    display: flex;
    margin-bottom: 30px;
    cursor: pointer;

    .product-label-text {
      color: $product-intro-size-guide-color;
      margin-top: 2px;
      margin-left: 6px;
    }
  }

  @media (max-width: $grid-bp-sm-max) {
    .product-buttons-container {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $color-white;
      padding: 16px 10px;
      box-shadow: 0px 2px 10px $product-intro-button-container-color;
      z-index: 10;
    }
  }

  .product-buttons-container .product-buttons {
    display: flex;
    flex-direction: row;
    margin: auto;

    @media (max-width: $grid-bp-xs-max) {
      width: 100%;
    }

    @media (min-width: $grid-bp-md) {
      margin-bottom: 25px;
    }
  }

  .product-buttons-container .btn.horizontal-btns {
    @if ($theme-name != "actimove") {
      @include font-size-and-line-height(19px, 19px);

      span {
        line-height: 19px;
      }
    }

    text-align: left;
    @if ($theme-name != "men" and $theme-name != "actimove") {
      padding: 10px 80px 10px 18px;
    }

    @media (max-width: $grid-bp-xs-max) {
      margin: 0 10px 0 0;
    }
  }

  .product-intro-list {
    padding-left: 0;
  }

  .product-intro-list-item {
    list-style-type: none;
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    line-height: 22px;
  }

  .product-intro-list-item p {
    margin-bottom: 0;
  }

  .product-intro-list-item-text {
    @include font-size-and-line-height(16px, 22px);
  }

  .product-content-frost .icon-validation-tick:before {
    color: $color-white;
    background-color: $color-black;
    padding: 2px;
    border-radius: 50px;
    margin-right: 10px;
  }

  .panel-wrapper {
    position: fixed;
    display: flex;
    align-items: flex-end;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 51;
    background-color: rgba($color-black, 0.67);
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;

    @media (min-width: $grid-bp-sm) {
      justify-content: flex-end;
    }
  }

  .panel-wrapper--opened {
    visibility: visible;
    opacity: 100%;
    pointer-events: unset;

    .panel {
      overflow-y: auto;
    }
  }

  .panel {
    padding: 40px 14px 70px;
    max-height: 95vh;
    height: auto;
    background-color: $color-white;
    box-shadow: 0px 0px 5px rgba($color-black, 0.2);
    border-radius: 8px 8px 0 0;
    width: 100%;

    @media (min-width: $grid-bp-sm) {
      top: 0;
      max-height: unset;
      height: 100%;
      padding: 68px 20px;
      transition: 0.5s;
      border-radius: 0;
      width: 415px;
    }
  }

  .panel-close-button {
    position: absolute;
    bottom: 16px;
    left: 45%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: $product-intro-panel-close-button;
    color: $color-white;
    text-align: center;
    line-height: 38px;
    font-size: 28px;
    cursor: pointer;

    @media (min-width: $grid-bp-sm) {
      top: 12px;
      right: 18px;
      left: unset;
    }
  }

  .panel-header {
    @include font-size-and-line-height (22px, 28px);

    font-family: $product-intro-panel-header;
    text-align: left;

    @if ($theme-name == "actimove") {
      font-weight: 600;
      color: $color-light-black;
    } @else {
      font-weight: 400;
    }
  }

  .panel-media {
    margin-top: 30px;
    height: auto;
    text-align: center;
    width: 100%;

    img {
      max-width: 100%;
    }
  }

  .panel-description {
    margin-top: 20px;
    margin-bottom: 0;

    @if ($theme-name == "actimove") {
      line-height: 22px;
      font-weight: 600;
      color: $color-light-black;
    } @else {
      line-height: 25px;
    }
  }

  .panel-size-guide {
    margin-top: 35px;
  }

  .panel-size-label {
    @include font-size-and-line-height(14px, 18px);

    font-family: $fontfamily-regular-bold;
    margin-top: 10px;
    line-height: 25px;
  }

  .panel-size-info {
    line-height: 25px;
    margin-bottom: 0;
  }

  .product-intro-frost-ruler rect,
  .product-intro-frost-ruler line {
    stroke: $product-intro-ruler-color;
  }

  .MdrDetails-container .panel-header {
    @if ($theme-name == "actimove") {
      @include font-size-and-line-height(16px, 22px);

      margin-bottom: 5px;
    } @else {
      @include font-size-and-line-height(20px, 22px);
    }
  }

  .MdrDetails-container .panel-description,
  .MdrDetails-container .panel-size-info {
    @if ($theme-name == "actimove") {
      line-height: 22px;
      font-weight: 400;
    } @else {
      line-height: 25px;
    }
  }

  .MdrDetails-container .panel-media {
    text-align: left;
  }
}

@if ($theme-name == "actimove") {
  .product-intro-container-frost .product-subtitle,
  .product-intro-list-item-text {
    font-family: $intro-text-font-family;
  }

  .product-badge {
    font-family: $fontfamily-regular;
    display: flex;
    justify-content: flex-start;
    text-transform: uppercase;
    background-color: unset;
    color: $home-color;
    padding-left: 0;
  }

  .product-badge-img {
    width: 14px;
    height: 16px;
    margin-right: 5px;
  }

  .product-intro-container-frost .product-title {
    @include font-size-and-line-height(22px, 26px);

    font-style: italic;
  }

  .product-subtitle,
  .panel-size-label  {
    @include font-size-and-line-height(16px, 22px);

    font-family: $fontfamily-regular;
  }

  .product-intro-container-frost .product-title,
  .product-subtitle {
    font-weight: 600;
    margin-bottom: 8px;
  }

  .product-description-short,
  .product-sizes-value,
  .panel-size-info {
    line-height: 22px;
  }

  .product-content-frost .product-options {
    flex-direction: column;
  }

  .product-label-text {
    font-family: $fontfamily-regular;
    line-height: 22px;
    font-weight: 600;
  }

  .product-content-frost .product-color-circle,
  .product-intro-sizes {
    width: 100%;
  }

  .product-circle-wrapper,
  .product-circle  {
    display: flex;
  }

  .product-circle:not(:first-child) {
    margin-left: 10px;
  }

  .product-content-frost .product-color-inner {
    margin-top: 2px;
  }

  .product-circle-text {
    margin-bottom: 0;
    color: $color-light-black;
    margin-left: 4px;
  }

  .product-content-frost .product-size-guide .product-label-text {
    margin-left: 0;
  }

  .product-buttons-container .buy-now-highlighted {
    @include btn("primary");
  }

  .product-buttons-container .btn-secondary {
    @include btn("secondary");
  }

  .product-buttons-container .buy-now-highlighted span,
  .product-buttons-container .btn-secondary span {
    @include font-size-and-line-height(16px, 22px);

    font-family: unset;
    font-weight: 600;
  }

  @media (max-width: $grid-bp-sm-max) {
    .product-buttons-container .btn.horizontal-btns {
      text-align: center;
    }
  }

  @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
    .product-buttons {
      justify-content: center;
    }

    .product-buttons-container .btn.horizontal-btns {
      min-width: 350px;
    }
  }

  .product-description-short,
  .product-support-value,
  .product-sizes-value {
    color: $color-light-black;
  }

  .product-content-frost .icon-validation-tick:before {
    background-color: $product-intro-icon-color;
  }

  @media (min-width: $grid-bp-sm) {
    .panel {
      padding: 90px 40px;
      width: 512px;
    }
  }

  .panel-close-button {
    left: 45%;
    bottom: 16px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: $home-color;

    @media (min-width: $grid-bp-xs-max) {
      top: 40px;
      right: 40px;
      left: unset;
    }
  }

  .panel-list {
    padding-left: 30px;
  }

  .panel-list-item {
    color: $color-light-black;
    line-height: 28px;

    &::marker {
      color: $color-light-black;
    }
  }

  .panel-size-label {
    font-weight: 600;
  }

  .MdrDetails-container .panel-description {
    margin-top: 0;
  }

  .MdrDetails-container .panel-media {
    @include svg-play-icon();

     margin-top: 15px;
  }

  .MdrDetails-container .panel-content--accordion .panel-description {
    font-weight: 600;
  }

  .MdrDetails-container .panel-video {
    margin-bottom: 15px;
  }

  .MdrDetails-container .panel-media .panel-description:last-of-type {
    margin-bottom: 15px;
  }

  .MdrDetails-container .panel-content-wrapper {
    @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
      width: 530px;
    }

    @media (min-width: $container-max-width) {
      width: 534px;
    }
  }

  .MdrDetails-container .one-col-text {
    color: $color-light-black;
    padding-left: 0;
    padding-right: 0;

    @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
      max-width: 530px;
    }

    @media (min-width: $container-max-width) {
      max-width: 534px;
    }
  }

  .MdrDetails-container .panel-content--accordion {
    flex-direction: column;
  }

  .MdrDetails-container .one-col-text .text-content b {
    display: inline-block;
    font-weight: 600;
    margin-bottom: 5px;
  }
}