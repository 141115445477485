.sample-product-box {
  background-color: $color-white;
  border: 1px $border-color solid !important;
  cursor: pointer;
  padding: 25px 15px;
}

.sample-product-box .product-box-content {
  overflow: hidden;
  width: 100%;
}

.sample-product-box .product-box-text {
  margin: 0 auto 20px !important;
  min-height: 60px;
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: $grid-bp-sm) {
    margin: 0 auto 70px !important;
    min-height: 100px;
    padding-left: 0;
    padding-right: 0;
  }
}

.sample-product-box .sample-box-btn {
  @include mediaquery(xs) {
    text-align: center;
  }
}

.sample-container .txtArea {
  width: 100%;
}

@if ($theme-name == "cutimed") {
  .sample-container .product-box-container,
  .sample-container .product-box-text {
    padding: 0;
  }

  .sample-container .product-box-container-image-left .component-product-box {
    margin: 0;
    padding: 0;
  }

  .sample-container .manual-padding.small-padding {
    padding: 0;
  }

  .sample-container .section-title {
    @include font-size-and-line-height(28px, 32px);

    margin-top: 50px;
    border: none;
  }

  .sample-container .product-box-container {
    margin: 40px 0;
  }

  .sample-container .component-product-box.sample-product-box {
    margin: 0;
    padding: 0;
    width: 100%;
    border: none !important; // Override .sample-product-box from product-box.scss
  }

  .sample-container .product-box-text {
    margin: 0 !important; // Override .sample-product-box .product-box-text from product-box.scss
  }

  .sample-container .product-box-content .title {
    margin-top: 15px;
  }

  .sample-container .product-box-content .product-box-select-btn {
    padding: 10px 0;
  }

  .sample-container .intro-subtitle p {
    font-family: $fontfamily-regular-bold;
    margin-bottom: 15px;
  }
  .sample-selected-info-container .section-title {
    @include font-size-and-line-height(28px, 32px);

    border: none;
    padding-bottom: 0;
  }

  .sample-selected-info-container .sample-info .section-title {
    border: none;
    margin-top: 0;
  }

  .sample-selected-info {
    border: 1px solid $border-color-dropwdown;
    border-radius: 3px;
    margin-bottom: 35px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .sample-selected-info .section-title {
    border: none;
    text-align: center;
  }

  .sample-selected-info .component-product-box .title {
    @include font-size-and-line-height(16px, 25px);

    font-family: $fontfamily-regular-bold;
  }

  .sample-selected-info .component-product-box {
    border: none;
  }

  .sample-selected-info .product-box-img {
    margin-bottom: 25px;
  }

  .sample-selected-info .product-box.product-compact .product-box-text {
    text-align: center;
    margin-bottom: 20px;
  }

  .component-product-box .product-box.product-compact .product-box-content .sample-box-btn {
    position: relative;
    text-align: left;
  }

  .sample-container .product-box-text .text {
    min-height: 0;
  }

  .sample-container .product-box-select-btn .btn {
    min-width: 220px;
  }

  .sample-container .sample.field-hidden-input input:not(:checked) + label.btn,
  .sample-container .sample-previous-btn {
    color: $color-gray !important;
    background: $color-white;
    border-color: $color-gray;
  }

  .sample-container .product-box-select-btn input:checked + label {
    color: $color-white;
    background: $color-blue;
    border-color: $color-blue;
  }

  .sample-container .question-box-submit .btn {
    min-width: 180px;
    padding: 10px 15px;
  }

  .sample-container .sample-previous-btn:hover {
    color: $color-white !important;
  }

  .sample-container .sample-previous-btn i {
    display: none;
  }

  @media (min-width: $grid-bp-md) {
    .sample-selected-info {
      padding-bottom: 0;
    }

    .sample-selected-info-container .sample-info {
      padding: 0 30px;
    }

    .sample-container .product-box-container {
      border: 1px solid $color-cuticell-classic;
      border-radius: 3px;
      padding: 40px 20px;
      margin: 20px 0;
    }

    .sample-container .product-box-content {
      flex-direction: row;
      align-items: center;
    }

    .sample-container .product-box-content .title {
      @include font-size-and-line-height(16px, 25px);

      font-family: $fontfamily-regular-bold;
    }

    .sample-container .product-box-img {
      margin-right: 30px;
    }

    .sample-container .product-box-img img {
      width: 230px;
    }

    .sample-container .validate.field-checkbox-container {
      padding: 0;
    }

    .sample-container .field-label {
      margin-bottom: 5px;
    }

    .form-order-sample {
      margin-left: -10px;
    }
  }
}