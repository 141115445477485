.intro-carousel {
  @include svg-play-icon();
}

.intro-carousel .svg-icon.play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.intro-carousel-cover-img {
  background-color: $color-white;
  height: 100%;
  min-height: 120px;
  object-fit: cover;
  object-position: center;
  width: 100%;

  @media (min-width: $grid-bp-xs) {
    min-height: 150px;
  }

  @media (min-width: $grid-bp-sm) {
    height: 240px;
  }

  @media (min-width: $grid-bp-md) {
    height: 330px;
  }

  @media (min-width: $grid-bp-lg) {
    height: 368px;
  }
}

.intro-carousel--is-inline .intro-carousel-cover-img {
  @media (min-width: $grid-bp-md) {
    max-width: $container-max-width;
  }
}

$carousel-nav-button-bg-size: 75px;

.carousel-nav-button-bg::after {
  background-color: white;
  content: "";
  display: block;
  height: $carousel-nav-button-bg-size;
  position: absolute;
  top: -23px;
  width: $carousel-nav-button-bg-size;
  z-index: -1;
}

.image-carousel .carousel-nav-button-bg::after {
  background-color: transparent;
}


@if ($theme-name == "men") {
  .carousel-nav-button-prev,
  .carousel-nav-button-next {
    @media (max-width: $grid-bp-sm) {
      display: none;
    }
  }
}

.carousel-nav-button-prev .carousel-nav-button-bg::after {
  border-bottom-right-radius: $carousel-nav-button-bg-size;
  border-top-right-radius: $carousel-nav-button-bg-size;
  left: -#{$carousel-nav-button-bg-size / 1.5};
}

.carousel-nav-button-next .carousel-nav-button-bg::after {
  border-bottom-left-radius: $carousel-nav-button-bg-size;
  border-top-left-radius: $carousel-nav-button-bg-size;
  right: -#{$carousel-nav-button-bg-size / 1.5};
}

.intro-carousel-link {
  display: block;
}

@if ($theme-name == "leukoplast" or $theme-name == "cutimed") { 
  .intro-carousel .play-pause-btn .svg-icon.play-icon {
    fill: $color-black;
    fill-opacity: 0;
  }
}

.intro-carousel .carousel-controls {
  position: absolute;
  height: 100%;
  top: 0;
}

.intro-carousel .carousel-pager {
  position: absolute;
  bottom: 0;
  left: 0;
}

.intro-carousel-item-container {
  padding-bottom: 30px;
}

.carousel-controls .col-12 {
  position: inherit;
}

.carousel-alt-controls {
  padding-top: 50%;
  position: absolute;
  top: 0;
}

.intro-carousel-inline-img {
  float: left;
  height: 140px;
  position: relative;
  top: -15px;
  width: auto;
  max-width: 50%;
  object-fit: contain;

  @if ($theme-name == "men") {
    float: unset;
  }
}

.intro-carousel-content {
  margin-top: 30px;
}

.intro-carousel-header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.intro-carousel-article {
  width: 40%;
  text-align: left;

  @media (max-width: $grid-bp-sm) {
    width: 100%;
  }
}

@if ($theme-name == "cutimed") {
  .intro-carousel-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .intro-carousel-article {
    max-width: 35%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .swiper-pagination-bullet {
    background: $color-cuticell-classic;
  }

  .swiper-pagination-bullet-active {
    background-color: $color-blue;
  }

  @media (max-width: $grid-bp-sm) {
    .intro-carousel-container {
      align-items: center;
    }

    .intro-carousel-article {
      max-width: 100%;
    }
  }
}

.intro-carousel-btn {
  clear: both;
  @if ($theme-name == "leukoplast") {
    @include btn("secondary");

    @media (min-width: $grid-bp-xs) {
      min-width: $btn-min-width;
    }

    @media (max-width: $grid-bp-xs-max) {
      width: 100%;
    }
  }

  @if ($theme-name == "men") {
    @include btn("tertiary");

    /* Remove this code if we get hover design */
    &:hover {
      color: $color-white;
      background-color: $color-yellow;

      &::before {
        background-color: $color-purple;
      }
    }
  }

  @if ($theme-name == "cutimed") {
    background-color: $color-pink;
    border: 2px solid $color-pink;
    width: 280px;
    text-align: center;
    font-size: 15px;
    line-height: 20px;
    font-family: $fontfamily-regular-bold;
  }
}

.intro-carousel-btn:hover {
  @if ($theme-name == "cutimed") {
    background-color: $color-pink;
  }
}

.intro-carousel-title {
  @include font-size-and-line-height($font-size-intro-carousel-title, $line-height-intro-carousel-title);
  letter-spacing: 0.02em;
  margin-bottom: 10px;

  @if ($theme-name == "leukoplast") {
    @include mediaquery(xxs) {
      text-align: left;
    }
  }

  @if ($theme-name == "men") {
    font-family: $fontfamily-titles-thin;
    text-transform: uppercase;
    text-align: left;

    @media (max-width: $grid-bp-sm) {
      color: $color-purple;
    }
  }

  @if ($theme-name == "cutimed") {
    width: 100%;
    text-align: left;
    font-family: $fontfamily-regular-bold-condensed;
  }
}

@media (min-width: $grid-bp-xs) {
  .carousel-nav-button-bg::after {
    display: none;
  }

  .intro-carousel .carousel-pager {
    display: block;
  }

  .carousel-controls {
    padding-top: 0;
    position: relative;
  }

  .carousel-nav-button-prev,
  .carousel-nav-button-next {
    bottom: 0;
    top: 20px;
  }

  .intro-carousel-inline-img {
    height: 180px;
  }
}

@media (max-width: $grid-bp-sm) { 
  .intro-carousel-text-media {
    display: flex;
    flex-direction: row-reverse;
    justify-content: left;
    gap: 25px;
  }

  .intro-carousel-btn span {
    @include font-size-and-line-height(19px, 21px);
  }

  .intro-carousel-title {
    @include font-size-and-line-height(22px, 28px);
  }
}

@media (min-width: $grid-bp-sm) {
  .intro-carousel-title {
    text-align: left;

    &:not(&--is-dark) {
      color: $color-white;

      @if ($theme-name == "leukoplast") {
        color: $cta-bgcolor-alt;
      }
    }
  }

  @if ($theme-name == "leukoplast") {
    .intro-carousel-light-text .intro-carousel-title {
      color: $color-white;
    }

    .intro-carousel-light-text .intro-carousel-btn {
      border-color: $color-white;
      color: $color-white;

      &:hover {
        border-color: $cta-bgcolor-alt;
      }
    }
  }

  .intro-carousel-btn {
    display: inline-block;
  }

  .intro-carousel-content {
    min-height: 200px;
    bottom: 30px;
    left: 0;
    position: absolute;
    right: 0;
    text-align: right;
    display: flex;
    align-items: center;
  }

  .intro-carousel-inline-img {
    height: 100px;
  }

  .intro-carousel-btn,
  .intro-carousel-inline-img,
  .intro-carousel-title {
    @if ($theme-name != "men") {
      clear: both;
      float: left;
    }
  }

  .intro-carousel--is-inline .intro-carousel-content {
    padding-right: 20px;
  }
}


@media (min-width: $grid-bp-md) {
  .intro-carousel-inline-img {
    height: 160px;
  }

  .intro-carousel--is-inline .intro-carousel-inline-img {
    height: 140px;
  }
}

@media (min-width: $container-max-width) {
  .intro-carousel-title {
    margin-bottom: 20px;
  }

  .intro-carousel-inline-img {
    height: 170px;
  }
}

@if ($is-tena-theme) {
  @media (max-width: $grid-bp-xs-max) {
    .product-intro-container-frost .carousel-nav-button-prev .carousel-nav-button-bg::after,
    .product-intro-container-frost .carousel-nav-button-next .carousel-nav-button-bg::after {
      display: none;
    }
  }
}