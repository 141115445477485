.compare-products {
  overflow-x: hidden;
}

.compare-products-columns {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 0;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: 1fr;
  width: 100%;
}

.compare-products-column {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;

  &--no-data {
    height: fit-content;
  }
}

.compare-products-column:nth-child(3),
.compare-products-column:nth-child(4) {
  display: none;
}

.compare-products-item {
  background-color: $compare-product-bg-color;
  border-radius: 8px;
  padding: 24px 8px 32px;
  text-align: center;
  word-break: break-word;
}

.compare-products-item .detail {
  text-align: center;
}

.compare-products-item .rating {
  display: inline-block;
}

.compare-products-image {
  height: 95px;
  margin-bottom: 16px;
  max-width: 100%;
  object-fit: contain;
  width: auto;
}

.compare-products-item .box-heading {
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  word-break: break-word;
}

.compare-products-item .box-text {
  margin: 0;
  padding: 8px 0 24px;
  word-break: break-word;
}

.compare-products-footer {
  margin-top: auto;
}

.compare-products-footer .btn {
  font-size: 16px;
  margin: 30px 0 8px;
  width: 100%;

  @media (min-width: $grid-bp-md) {
    font-size: 19px;
  }
}

.compare-products-footer .form {
  display: flex;
  flex-direction: column-reverse;
  float: none;
  margin-bottom: 0;
  width: 100%;
}

.compare-products-footer .form-field {
  margin-bottom: 8px;
}

.compare-products-footer .form .btn {
  @include btn("secondary");

  margin: 0;
  width: 100%;
}

.compare-products-footer .buy-now-highlighted {
  margin: 0;
}

.compare-products-filters .select2-container,
.compare-products-filters .select2-container .select2-selection--single {
  height: 100%;
}

.compare-products-filters .select2-container .select2-selection--single .select2-selection__rendered {
  text-overflow: unset;
  white-space: normal;
  width: 100%;
}

.compare-products-heading {
  color: $color-purple;
  font-family: $fontfamily-titles-thin;
  font-size: 22px;
  line-height: 28px;
  margin: 20px 0 40px;
  text-transform: uppercase;
}

.product-color-circle {
  margin: 16px auto 8px;
}

.product-color-outer {
  border: 1px solid $input-search-color;
  border-radius: 50%;
  height: 48px;
  position: absolute;
  width: 48px;
}

.product-color-inner {
  border-radius: 50%;
  height: 40px;
  margin: 4px;
  width: 40px;
}

.compare-product-sizes {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.compare-product-sizes-label {
  font-family: $fontfamily-regular;
  font-size: 28px;
  line-height: 32px;
  padding-top: 28px;
}

.compare-product-sizes-value {
  margin-bottom: 24px;
}

.product-item-icon {
  align-self: center;
  margin-bottom: 8px;
  width: 64px;
}

.compare-products-quantity {
  line-height: 22px;
  margin-bottom: 28px;
}

.compare-product-benefits {
  display: flex;
  flex-direction: column;
  margin: 16px 0 8px;
}

.compare-product-sample {
  display: flex;
  flex-direction: column;
}

.compare-products-filter .select2-selection--single .select2-selection__rendered {
  color: $color-purple;
  line-height: 18px;
  padding-left: 0;
}

.compare-products-filters {
  position: relative;
}

.compare-products-filters-container {
  padding: 8px 0;

  &--is-sticky {
    background-color: $color-white;
    box-shadow: 1px 4px 16px 0 $box-shadow-color;
    position: fixed;
    width: 100%;
    z-index: 2;
  }
}

.compare-product-reset {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  pointer-events: none;
}

.compare-product-reset-text {
  @include font-size-and-line-height(16px, 22px);

  padding-left: 4px;
  margin-bottom: 0;
  color: $color-purple;
  font-family: $fontfamily-regular-bold;
}

.compare-product-reset-icon,
.compare-product-reset-text {
  pointer-events: auto;
}

@media (max-width: $grid-bp-xs-max) {
  .compare-products-filters .select2-dropdown {
    width: 100% !important;
  }

  .compare-products-filters .select2-container {
    left: 0 !important;
    right: 0;
    width: 100%;
  }
}

@media (min-width: $grid-bp-sm) {
  .compare-products-columns {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .compare-products-column:nth-child(3) {
    display: flex;
  }

  .compare-products-item {
    padding-left: 16px;
    padding-right: 16px;
  }

  .compare-products-image {
    height: 120px;
    margin-bottom: 24px;
  }

  .compare-products .btn-buy-now {
    margin: 24px 0 16px;
  }
}

@media (min-width: $grid-bp-md) {
  .compare-products-image {
    height: 160px;
  }

  .compare-products-item .box-heading {
    font-family: $fontfamily-regular;
    font-size: 22px;
    line-height: 28px;
  }

  .compare-products-heading {
    font-size: 28px;
    line-height: 32px;
  }
}

@media (min-width: $grid-bp-lg) {
  .compare-products-columns {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .compare-products-column:nth-child(4) {
    display: flex;
  }
}
