.component-product-details .product-details {
  overflow: hidden;

  h3 {
    @include font-size($font-size-h4);
    margin-bottom: 4px;
  }
}

.component-product-details .product-details .detail {
  display: inline-block;
  float: left;
  height: 70px;
  margin-bottom: 20px;
  width: 50%;

  @include mediaquery(sm) {
    margin: 0 0 30px;
  }

  @include mediaquery(xs) {
    width: 50%;
    margin: 0 0 15px;
  }

  p {
    margin-bottom: 0;
  }

  h3 {
    margin-bottom: 10px;
  }

  .sizes {
    color: #cad1db;

    .filled {
      color: $accent-color;
    }
  }
}

.component-product-details .component-pushbox {
  &:last-of-type {
    margin-top: 30px;
  }

  .prod-text-box {
    padding: 15px;
  }
}

.prod-text-box {
  text-align: center;
}

.component-product-details .packages-list {
  @extend .reset-list;

  li {
    margin-bottom: 5px;
  }
}

@if $theme-name == "leukoplast" {
  .product-benefits .article-list-item h2 {
    color: $logo-color;
  }

  .product-benefits .article-list-title {
    border-bottom: none;
  }
}

@if $theme-name == "cutimed" {
  .component-product-details .component-title {
    text-align: center;
    border: none;
  }

  .product-benefits .article-list-title {
    border-bottom: none;

    @media (max-width: $grid-bp-xxs-max) {
      text-align: center;
    }
  }

  .product-facts-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
  }

  .product-facts .product-facts-box {
    max-width: 150px;
    text-align: center;
    margin-bottom: 40px;
  }

  .product-facts .product-facts-image {
    max-width: 100px;
    height: auto;
  }

  .product-facts .product-facts-text {
    @include font-size-and-line-height(24px, 24px);

    font-family: $fontfamily-regular-bold-condensed;
    margin-top: 10px;
    color: $color-blue;
  }

  .information-evidence-module {
    border: 1px solid $color-blue;
    color: $color-gray;
    padding: 16px 25px 25px;
    text-align: center;
    word-break: break-word;

    @media (min-width: $grid-bp-sm) {
      padding: 30px 100px 40px;
      text-align: left;
    }

    @media (min-width: $grid-bp-md) {
      margin-bottom: 20px;
    }
  }

  .information-evidence-module .component-title {
    @include font-size-and-line-height($font-size-section-title, $line-height-section-title);

    display: block;
    font-family: $fontfamily-regular-bold-condensed;
    margin-top: 0;
    margin-bottom: 34px;

    @media (min-width: $grid-bp-sm) {
      margin-bottom: 24px;
    }
  }

  .information-evidence-module .text-content {
    display: block;
    font-family: $fontfamily-regular;
    line-height: 25px;
    margin-bottom: 24px;
  }

  .information-evidence-module .btn {
    @include btn("secondary");

    padding-left: 30px;
    padding-right: 30px;
    text-align: center;

    @media (max-width: $grid-bp-xs-max) {
      margin-right: 20px;
      margin-left: 20px;
    }

    @media (min-width: $grid-bp-xs) {
      padding-left: 80px;
      padding-right: 80px;
    }
  }
}

@if ($is-tena-theme) {
  .accordion-item .prod-text-box {
    @include font-size-and-line-height(14px, 18px);

    text-align: left;
  }
}