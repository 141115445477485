[class^="icon-"],
[class*=" icon-"],
.DropStyle {
    font-family: $fontfamily-icon;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
    content: "\e600";
}
.icon-arrow-down {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe600\;");
}
.icon-arrow-up:before {
    content: "\e601";
}
.icon-arrow-up {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe601\;");
}
.icon-button-left:before {
    content: "\e602";
    /*!rtl:raw:
content: "\e605";
*/
}
.icon-button-left {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe602\;");
}
.icon-button-right:before {
    content: "\e605";
    /*!rtl:raw:
content: "\e602";
*/
}
.icon-button-right {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe605\;");
}
.icon-color-circle:before {
    content: "\e60a";
}
.icon-color-circle {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe60a\;");
}
.icon-drop-empty:before {
    content: "\e60c";
}
.icon-drop-empty {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe60c\;");
}
.icon-drop-full:before {
    content: "\e60d";
}
.icon-drop-full {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe60d\;");
}
.icon-drop-half-full:before {
    content: "\e60e";
}
.icon-drop-half-full {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe60e\;");
}
.icon-footer-link:before {
    content: "\e60f";
    /*!rtl:raw:
display: inline-block;
transform: scaleX(-1);
*/
}
.icon-footer-link {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe60f\;");
}
.icon-home:before {
    content: "\e610";
}
.icon-home {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe610\;");
}
.icon-nav-menu:before {
    content: "\e61c";
}
.icon-nav-menu {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe61c\;");
}
.icon-radiobutton-outer_circle:before {
    content: "\e706";
}
.icon-radiobutton-outer_circle {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe706\;");
}
.icon-short-arrow-left:before {
    content: "\e619";
}
.icon-short-arrow-left {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe619\;");
}
.icon-short-arrow-right:before {
    content: "\e620";
}
.icon-short-arrow-right {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe620\;");
}
.icon-validation-cross:before {
    content: "\e707";
}
.icon-validation-cross {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe707\;");
}
.icon-validation-tick:before {
  content: "\e708";
}
$icon-arrow-down: "\e600";
$icon-arrow-up: "\e601";
$icon-button-left: "\e602";
$icon-button-minus: "\e603";
$icon-button-plus: "\e604";
$icon-button-right: "\e605";
$icon-color-circle: "\e60a";
$icon-drop-empty: "\e60c";
$icon-drop-full: "\e60d";
$icon-drop-half-full: "\e60e";
$icon-footer-link: "\e60f";
$icon-home: "\e610";
$icon-nav-menu: "\e61c";
$icon-radiobutton-outer_circle: "\e706";
$icon-short-arrow-left: "\e619";
$icon-short-arrow-right: "\e620";
$icon-validation-cross: "\e707";

.svg-icon-container {
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
}

$svg-dimension: 1.2em;

.svg-icon {
  fill: $color-black;
  height: $svg-dimension;
  line-height: 1;
  vertical-align: baseline;
  width: $svg-dimension;
}

.svg-icon-white {
  fill: $color-white;
}

.play-icon-pause {
  fill: transparent;
}
