.item-sample {
  margin-bottom: 10px;
}

.item-sample a {
  border: 1px solid $mobile-header-menu-background;

  @if ($theme-name != "leukoplast") {
    border-radius: $btn-border-radius;
    display: flex;
  } @else {
    display: block;
  }

  @if ($theme-name != "cutimed") {
    padding: 15px;
  }
}

.item-sample img {
  @if ($theme-name == "cutimed") {
    max-height: 35px;
    max-width: 35px;
  } @else if ($theme-name == "actimove") {
    max-height: 16px;
    max-width: 16px;
  } @else {
    max-height: 55px;
    max-width: 80px;
  }
}

.item-sample .text {
  flex-basis: 150px;
}

.item-sample .title {
  @include font-setup();
}

@media (min-width: $grid-bp-md) {
  .item-sample {
    margin-bottom: 0;
  }

  .item-sample a {
    border: none;
    display: block;
    padding: 0;
  }

  .item-sample .img {
    max-height: initial;
  }

  @if ($theme-name == 'men') {
    .item-sample .text .title,
    .item-sample .text .subtitle {
      font-family: $fontfamily-regular;
      font-weight: normal;
      color: $color-white;
    }
  }

  @if ($theme-name != "leukoplast") {
    .item-sample .title {
      @if ($theme-name == "cutimed") {
        @include font-size-and-line-height(15px, 20px);
      } @else {
        @include font-size-and-line-height(16px, 18px);
      }
    }

    .item-sample .subtitle {
      display: none;
    }
  }
}
